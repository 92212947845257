













import { Vue, Component, Prop } from "vue-property-decorator";
import Copy from "@/components/UI/icon/Copy.vue";

@Component({
  name: "ToastWithCopy",
  components: {
    Copy,
  },
})
export default class ToastWithCopy extends Vue {
  @Prop({ type: String, default: "Message" })
  public readonly message!: string;
  @Prop({ type: String, default: null})
  public readonly requestGuid!: null | string

  copyMessage() {
    navigator.clipboard.writeText(`${this.requestGuid} ${this.message}`);
  }
}
