import { IUploads } from "./preapplication.interfaces";
import { errorMessage, ANOTHER_UPLOADS_MAX_COUNT } from "./preapplication.constants";
import { InitialFileTypes } from "@/constants/InitialFileTypes";

export const DEFAULT_UPLOADS: IUploads = {
  application: {
    name: "application",
    type: "doc",
    fileType: 19,
    title: "Заявление",
    descr: "",
    guid: "",
    isValid: false,
    isTouched: false,
    required: true,
    errors: [],
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    maxFiles: 1,
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""], // TODO: пустое это для .sig-файлов CMS, которые почему-то не получают mime-тип от браузера. Надо понять, почему
    },
    errorMessage,
    isIdentificationContent: true,
    isPreappContent: false
  },
  passport: {
    name: "passport",
    type: "doc-passport",
    fileType: InitialFileTypes.Passport,
    title: "Паспорт",
    descr: "",
    guid: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
    isIdentificationContent: true,
    isPreappContent: true
  },
  foto: {
    name: "foto",
    type: "doc-foto",
    fileType: InitialFileTypes.Photo,
    fromCamera: true,
    title: "Фото заявителя",
    descr: "",
    guid: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
    isIdentificationContent: true,
    isPreappContent: true
  },
  bill: {
    name: "bill",
    type: "doc",
    fileType: InitialFileTypes.Bill,
    title: "Счёт на оплату",
    descr: "",
    guid: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
    isIdentificationContent: false,
    isPreappContent: true
  },
  upd: {
    name: "upd",
    type: "doc",
    fileType: InitialFileTypes.UPD,
    title: "УПД",
    descr: "",
    guid: "",
    isValid: false,
    isTouched: false,
    required: true,
    accept: ".pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", ""],
    },
    errorMessage,
    isIdentificationContent: false,
    isPreappContent: true
  },
};

export const DEFAULT_ANOTHER_UPLOAD = {
  type: "doc",
  title: "Другой документ",
  descr: "",
  isValid: false,
  isTouched: false,
  required: false,
  accept: ".png, .jpg, .jpeg, .pdf, .sig",
  errors: [],
  rules: {
    size: 15728640,
    // type: ["application/pdf", "image/png", "image/jpeg", ""],
  },
  errorMessage,
  isIdentificationContent: true,
  isPreappContent: false
};

export const DEFAULT_ANOTHER_UPLOADS = new Array(ANOTHER_UPLOADS_MAX_COUNT)
  .fill("")
  .map((_,i) => `another${i}`)
  .reduce((a, v) => ({...a, [v]: {...DEFAULT_ANOTHER_UPLOAD, name: v}}), {});
