import { Module } from "vuex";

import { RootState } from "@/store/root.interface";

import { actions } from "./certificate-list.actions";
import { getters } from "./certificate-list.getters";
import { mutations } from "./certificate-list.mutations";
import { ICertificateListState } from "./certificate-list.types";

export const certificateListState: Module<ICertificateListState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    totalPages: null,
    sizePage: 10,
    activePage: 1,
    isLoaded: false,
  },
  mutations,
  actions,
  getters
};
