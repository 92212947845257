import {app} from "@/main";
import { fetch } from '@/services/api';
import { CERTIFICATE_LIST_URLS as URL_LIST } from '@/config/urls';

import { IListItem } from './certificate-list.types';
import { CERTIFICATE_STATUS_IDS } from "@/constants/certificate.constants";

const getList = async (
  {sizePage, activePage, queryParams}:
  {sizePage: number; activePage: number, queryParams: string}
) => {
  app.$modal.show("loader");

  const response: {
    data?: {
      certificates: IListItem[];
      totalCount: number;
      totalPages: number;
      currentPage: number;
    };
    success?: boolean;
    error?: string | null;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetList}/${sizePage}/${activePage}${queryParams}`
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.data;
    response.error = data.error;
    response.success = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
    response.success = false;
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getReport = async ({queryParams}: {queryParams: string}) => {
  app.$modal.show("loader");

  const response: {
    data?: string | File | Blob | Uint8Array;
    headers?: any;
    error?: string;
  } = {};

  try {
    const { status, data, headers } = await fetch.get(
      `${URL_LIST.GetReport}${queryParams}`,
      {responseType: "blob"}
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
    response.data = data;
    response.headers = headers;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const validateCertificate = async (
  {appId, serialNumber}:
  {appId: number; serialNumber: string;}
) => {

  const response: {
    data?: {
      statusId: CERTIFICATE_STATUS_IDS;
      status: string;
      notValidReasons: string[];
    };
    success?: boolean;
    error?: string | null;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.Validate}/${appId}/${serialNumber}`
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.data;
    response.error = data.error;
    response.success = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
    response.success = false;
  }
  
  return response;
};

export default {
  getList,
  getReport,
  validateCertificate,
};
