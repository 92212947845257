import { MutationTree } from "vuex";

import {
  IRevokeListState,
  IListItem
} from './revoke-list.types';

export const SET_REVOKE_LIST = 'SET_REVOKE_LIST';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_SIZE_PAGES = 'SET_SIZE_PAGES';
export const SET_IS_LOADED = 'SET_IS_LOADED';
export const CLEAR_REVOKE_LIST = 'CLEAR_REVOKE_LIST';
export const SET_IS_REVOKES_ALLOWED = 'SET_IS_REVOKES_ALLOWED';

export const mutations: MutationTree<IRevokeListState> = {
  [SET_REVOKE_LIST](state, list: IListItem) {
    state.list = JSON.parse(JSON.stringify(list));
  },
  [SET_TOTAL_PAGES](state, num: number) {
    state.totalPages = num;
  },
  [SET_ACTIVE_PAGE](state, num: number) {
    state.activePage = num;
  },
  [SET_SIZE_PAGES](state, num: number) {
    state.sizePage = num;
  },
  [SET_IS_LOADED](state, val: boolean) {
    state.isLoaded = val;
  },
  [CLEAR_REVOKE_LIST](state) {
    state.list = [];
    state.sizePage = 10;
    state.activePage = 1;
    state.totalPages = null;
    state.isLoaded = false;
  },
  [SET_IS_REVOKES_ALLOWED](state, value: boolean | null) {
    state.isRevokesAllowed = value;
  }
};
