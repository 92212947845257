import { GetterTree } from "vuex";
import { isEmpty } from "lodash";

import { IApplicationListState } from "./application-list.interfaces";
import { RootState } from "../../root.interface";
import { getFormattedDate } from "@/helpers";
import { IDENTIFICATION_KINDS } from "@/constants";

export const getters: GetterTree<IApplicationListState, RootState> = {
  getApplicationList(state, getters, rootState, rootGetters) {
    const isManagerPartner = rootGetters["authorizationState/isManagerPartner"];
    const isChiefManager = rootGetters["authorizationState/isChiefManager"];
    const identificationKinds = Object.values(IDENTIFICATION_KINDS)
      .reduce((acc, cur) => ({...acc, [cur.value]: cur.label}), {});
    return state.applicationsList.map((el: any) => {
      const statusSMEV = Array.isArray(el.statusSMEV) ? el.statusSMEV.map((item: any) => ({
        ...item,
        name: item.name.replace(" заявителя", "")
      })) : [];

      const row = [];
      row.push({
        id: el.id || "",
        externalSourcePkcs10: el.externalSourcePkcs10,
        managerComment: el.managerComment || "",
        isPdnUsingProhibited: el.isPdnUsingProhibited || false,
      });
      row.push(el.creationDate || "");
      row.push({
        status: (el.status && el.status == "Ошибка регистрации в ЭДО" ? "Регистрация в ЭДО" : el.status) || "",
        comment: isEmpty(el.comment) ? null : el.comment,
        statusSMEV
      });
      row.push(el.identificationKind in identificationKinds ? (identificationKinds as any)[el.identificationKind] : "");
      row.push(el.paymentStatusDescription || "");
      if (!isManagerPartner) {
        row.push(el.lastPaymentDate ? getFormattedDate(el.lastPaymentDate, "DD.MM.YYYY HH:mm") : "");
      }
      row.push({
        organizationShortName: el.organizationShortName || "",
        fnsStatusId: el.fnsStatusId || 0
      });
      row.push(`${el.surname || ""} ${el.name || ""} ${el.middleName || ""}`);
      row.push(el.applicantType || "");
      if (!isManagerPartner) {
        row.push(el.phoneNumber || "");
      }
      row.push(el.order || "");
      row.push(el.insuranceType ? 'Да' : 'Нет');
      if (!isManagerPartner) {        
        row.push(`<b>${!el.totalPrice && el.totalPrice !== 0 ? "" : el.totalPrice} ₽</b>`);
      }
      if (isChiefManager || isManagerPartner) {
        row.push(el.managerFIO || "");
      }
      if (isManagerPartner) {
        row.push(el.filialName || "");
      }

      return row;
    });
  },

  getPageName(state, getters, rootState, rootGetters) {
    const isManagerPartner = rootGetters["authorizationState/isManagerPartner"];
    const isChiefManager = rootGetters["authorizationState/isChiefManager"];
    return `app${isManagerPartner ? 'ManagerPartner' : isChiefManager ? 'HeadManager' : ''}${state.isArchive ? 'Archive' : ''}`;
  },

  getAllowCreateAppFromXml(state, getters, rootState, rootGetters) {
    return rootState.filialInfo?.options.allowCreateAppFromXml;
  },
};
