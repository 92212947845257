import {app} from "@/main";
import { fetch } from '@/services/api';
import { REVOKE_URLS as URL_LIST } from '@/config/urls';
import { DOCUMENT_TYPE_IDS } from '@/constants/revoke.constants';

import { IRevokeApplication } from './revoke.types';

const getApplication = async ({id}: {id: number}) => {
  app.$modal.show("loader");

  const response: {
    data?: IRevokeApplication;
    error?: string;
    status?: number;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetApplication}/${id}`
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';

    if (error.response?.status) response.status = error.response.status;
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const downloadDocument = async (
  {guid, documentType}: {guid: string; documentType: DOCUMENT_TYPE_IDS}
) => {
  app.$modal.show("loader");

  const response: {
    data?: {
      base64: string;
      contentType: string;
      fileDescription: string;
      fileExtension: string;
      fileName: string;
    };
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.DownloadDocument}?id=${guid}&type=${documentType}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const attachDocument = async (
  {guid, documentType, base64}:
  {guid: string; documentType: DOCUMENT_TYPE_IDS, base64: string}
) => {
  const response: {error?: string} = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.AttachDocument}`,
      {
        applicationId: guid,
        documentType,
        base64
      }
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }
  
  return response;
};

const attachSignedApplication = async (
  {guid, base64}:
  {guid: string; base64: string}
) => {
  const response: {error?: string} = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.AttachSignature}`,
      {
        applicationId: guid,
        signedDocumentType: 1,
        signatureBase64: base64,
      }
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }
  
  return response;
};

const getVerifyingCode = async ({guid, phone}: {guid: string; phone?: string;}) => {
  const response: {error?: string} = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.GetVerifyingCode}`,
      {
        applicationId: guid,
        phoneNumber: phone,
      }
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }
  
  return response;
};

const skipVerifyingCode = async ({guid}: {guid: string}) => {
  const response: {error?: string} = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.SkipVerifyingCode}`,
      {
        applicationId: guid,
      }
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }
  
  return response;
};

const checkVerifyingCode = async (
  {guid, phone, code}:
  {guid: string; phone?: string; code: string;}
) => {
  const response: {data?: {isValid: boolean}; error?: string} = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.CheckVerifyingCode}`,
      {
        applicationId: guid,
        phoneNumber: phone,
        phoneCode: code,
      }
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);

    response.data = data.success;
    
  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }
  
  return response;
};

export default {
  getApplication,
  downloadDocument,
  attachDocument,
  attachSignedApplication,
  getVerifyingCode,
  skipVerifyingCode,
  checkVerifyingCode,
};
