import Vue from 'vue';
import { ActionTree } from 'vuex';
import download from "downloadjs";

import { RootState } from '@/store/root.interface';
import { TOAST_OPTIONS } from '@/constants';

import api from './certificate-list.api';
import { ICertificateListState } from './certificate-list.types';
import {
  SET_CERTIFICATE_LIST,
  SET_TOTAL_PAGES,
  SET_ACTIVE_PAGE,
  SET_SIZE_PAGES,
  SET_IS_LOADED,
  SET_CERTIFICATE_STATUS_IN_LIST,
} from './certificate-list.mutations';

export const actions: ActionTree<ICertificateListState, RootState> = {
  async getList({commit, state, rootGetters}) {
    const queryParams = rootGetters["filterState/getSearchString"]("certificates")
                      + rootGetters["filterState/getFilterString"]("certificates");

    const { data, success, error } = await api.getList({
      sizePage: state.sizePage,
      activePage: state.activePage,
      queryParams,
    });

    if (error || !success || !data) {
      Vue.$toast.error(
        `Ошибка при получении списка истекающих сертификатов: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    commit(SET_CERTIFICATE_LIST, data.certificates);
    commit(SET_TOTAL_PAGES, data.totalPages);
    if (data.currentPage !== state.activePage) {
      commit(SET_ACTIVE_PAGE, data.currentPage !== 0 ? data.currentPage : 1);
    }
    commit(SET_IS_LOADED, true);
  },

  async getReport({ rootGetters }) {
    const queryParams = rootGetters["filterState/getSearchString"]("certificates")
                      + rootGetters["filterState/getFilterString"]('certificates');

    const { data, headers, error } = await api.getReport({queryParams});

    if (error || !data || !headers) {
      Vue.$toast.error(
        `Ошибка выгрузки CSV заявок на отзыв: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    let fileName;
    try { 
      fileName = decodeURI(headers["content-disposition"].match(/filename=(.*);/)[1]);
    } catch {
      const now = new Date(Date.now());
      fileName = `Список_истекающих сертификатов_${now.toLocaleDateString()}_${now.toLocaleTimeString()}.csv`;
    }
  
    download(data, fileName, headers["content-type"]);
  },

  async validateCertificate({commit}, {appId, serialNumber}: {appId: number; serialNumber: string;}) {
    const { data, success, error } = await api.validateCertificate({appId, serialNumber});

    if (error || !success || !data) {
      Vue.$toast.error(
        `Ошибка при перепроверке статуса сертификата: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    commit(SET_CERTIFICATE_STATUS_IN_LIST, {id: appId, statusId: data.statusId, status: data.status});
  },

  changePage({ commit, state, dispatch }, page: number) {
    if (state.activePage !== page) {
      commit(SET_ACTIVE_PAGE, page);
      dispatch("getList");
    }
  },

  changeSizePage({ commit, state, dispatch }, sizePage: number) {
    if (state.sizePage !== sizePage) {
      commit(SET_SIZE_PAGES, sizePage);
      dispatch("getList");
    }
  },

  setActivePage({ commit }, activePage: number) {
    commit(SET_ACTIVE_PAGE, activePage);
  },
};
