import { MutationTree } from "vuex";
import { IAuthorizationState } from "./authorization.interfaces";

export const SET_ROLE = "SET_ROLE";
export const SET_SAVE_USER = "SET_SAVE_USER";
export const SET_FORM_CONTROL = "SET_FORM_CONTROL";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_IS_FORM_VALID = "SET_IS_FORM_VALID";
export const CLEAR_FORM_CONTROLS = "CLEAR_FORM_CONTROLS";
export const SET_IS_SUBMIT_ACTIVE = "SET_IS_SUBMIT_ACTIVE";
export const TOGGLE_RECOVERY_PASSWORD = "TOGGLE_RECOVERY_PASSWORD";
export const SET_LOCATION_TO_REDIRECT = "SET_LOCATION_TO_REDIRECT";

export const mutations: MutationTree<IAuthorizationState> = {
  [SET_FORM_CONTROL](state, { control, name }: { control: any; name: string }) {
    state.formControls[name] = { ...control };
  },
  [SET_IS_FORM_VALID](state, val: boolean) {
    state.isFormValid = val;
  },
  [SET_ERROR_MESSAGE](state, err: null | string) {
    state.errorMessage = err;
  },
  [SET_SAVE_USER](state, val: boolean) {
    state.saveUser = val;
  },
  [SET_IS_SUBMIT_ACTIVE](state, val: boolean) {
    state.isSubmitActive = val;
  },
  [CLEAR_FORM_CONTROLS](state) {
    state.isRecoveryPassword = false;
    state.isFormValid = true;
    state.isSubmitActive = false;
    state.errorMessage = null;
    state.saveUser = false;
  },
  [TOGGLE_RECOVERY_PASSWORD](state) {
    state.isRecoveryPassword = !state.isRecoveryPassword;
  },
  [SET_LOCATION_TO_REDIRECT](state, locationToRedirect: string) {
    state.locationToRedirect = locationToRedirect;
  },
  [SET_ROLE](state, value: IAuthorizationState['role']) {
    state.role = value;
  }
};
