import {app} from "@/main";
import { fetch } from '@/services/api';

import { PREAPPLICATION_URLS as URL_LIST, EXTERNAL_SERVICES_URLS } from '@/config/urls';
import { IGetFieldsFromBasisResponse, IPreApplicationState, IDivisionInfo } from './preapplication.interfaces';

const getFieldsFromBasis = async (
  {initialApplicationType, inn, ogrn}:
  {initialApplicationType: 0 | 1, inn: string; ogrn?: string}
) => {
  const response: {data?: IGetFieldsFromBasisResponse; error?: string} = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.GetFieldsFromBasis}`,
      {
        initialApplicationType,
        inn,
        ogrn,
      }
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);

    response.data = data;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка'
      );
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const readyForUpd = async (orderId: number | string) => {
  const response: {error?: string} = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.ReadyForUpd}/${orderId}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const setStatus = async (
body: {
  id: IPreApplicationState['orderId'];
  newStatusId: number;
  comment: string;
}) => {
  const response: {
    status?: number;
    data?: {orderId: number};
    error?: string
  } = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.SetStatus}`,
      body,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);

    response.data = data;
    response.status = status;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
    response.status = error.response?.status;
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getDivisionInfo = async (divisionCode: number) => {
  const response: {
    data?: IDivisionInfo;
    error?: string;
    status?: number;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${EXTERNAL_SERVICES_URLS.GetDivisionName}?query=${divisionCode}`,
    );
  
    if (status === 403) throw new Error('Нет доступа');
    if (status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.error) throw new Error(data.error);

    response.data = data;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
    response.status = error.response?.status;
  }
  
  return response;
};

export default {
  getFieldsFromBasis,
  readyForUpd,
  setStatus,
  getDivisionInfo,
};
