export const DEFAULT_COUNTRY = { value: 193, label: "Россия" };

export const TYPE_ENTRES = [
  {
    title: "Физическое лицо",
    name: "fl",
    id: 1,
    inputFields: [
      "surname",
      "name",
      "patronymic",
      "gender",
      "email",
      "phone",
      "dateDoc",
      "birthDate",
      "serialDoc",
      "inn",
      "snils",
      "countryId",
      "regionRealId",
      "cityReal",
      "addressReal",
      "addovert",
      "ksk",
      "kskcom",
      "cryptopro",
      "passportDivision",
      "passportDivisionCode",
      "fnsCode",
      "payerSubjectType",
      "separatedAddressBuildingType",
      "separatedAddressBuildingValue",
      "separatedAddressLocalityType",
      "separatedAddressLocalityValue",
      "separatedAddressRoadType",
      "separatedAddressRoadValue",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
    ],
    notRequiredFields: [
      "department",
      "index",
      "patronymic",
      "regionRealId",
      "cityReal",
      "addressReal",
      "patronymicOrg",
      "clientComment",
      "partnerOrganizationName",
      "fnsCode",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
    ],
    notRequiredFieldsLicense: [
      "birthDate",
      "serialDoc",
      "dateDoc",
      "passportDivisionCode",
      "snils",
      "ogrnip",
      "position",
      "personInn",
      "ogrn",
      "shortNameOrg",
      "surnameOrg",
      "nameOrg",
      "patronymicOrg",
      "positionDirector",
      "passportDivision",
    ],
  },
  {
    title: "Индивидуальный предприниматель",
    name: "ip",
    id: 2,
    inputFields: [
      "surname",
      "name",
      "patronymic",
      "gender",
      "email",
      "phone",
      "dateDoc",
      "birthDate",
      "serialDoc",
      "snils",
      "inn",
      "ogrnip",
      "countryId",
      "regionRealId",
      "cityReal",
      "addressReal",
      "ksk",
      "kskcom",
      "cryptopro",
      "addovert",
      "phoneOrg",
      "passportDivision",
      "passportDivisionCode",
      "fnsCode",
      "payerSubjectType",
      "separatedAddressBuildingType",
      "separatedAddressBuildingValue",
      "separatedAddressLocalityType",
      "separatedAddressLocalityValue",
      "separatedAddressRoadType",
      "separatedAddressRoadValue",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
    ],
    notRequiredFields: [
      "department",
      "index",
      "patronymic",
      "regionRealId",
      "cityReal",
      "addressReal",
      "patronymicOrg",
      "clientComment",
      "partnerOrganizationName",
      "fnsCode",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
    ],
    notRequiredFieldsLicense: [
      "birthDate",
      "serialDoc",
      "dateDoc",
      "passportDivisionCode",
      "snils",
      "ogrnip",
      "position",
      "personInn",
      "ogrn",
      "shortNameOrg",
      "surnameOrg",
      "nameOrg",
      "patronymicOrg",
      "positionDirector",
      "passportDivision",
    ],
  },
  {
    title: "Юридическое лицо",
    name: "ur",
    id: 3,
    inputFields: [
      "surname",
      "name",
      "patronymic",
      "gender",
      "email",
      "phone",
      "dateDoc",
      "birthDate",
      "serialDoc",
      "passportDivision",
      "passportDivisionCode",
      "personInn",
      "inn",
      "snils",
      "ogrn",
      "department",
      "position",
      "countryId",
      "regionLawId",
      "cityLaw",
      "addressLaw",
      "ksk",
      "kskcom",
      "cryptopro",
      "addovert",
      "surnameOrg",
      "nameOrg",
      "patronymicOrg",
      "phoneOrg",
      "fullNameOrg",
      "shortNameOrg",
      "positionDirector",
      "kpp",
      "index",
      "fnsCode",
      "payerSubjectType",
      "separatedAddressBuildingType",
      "separatedAddressBuildingValue",
      "separatedAddressLocalityType",
      "separatedAddressLocalityValue",
      "separatedAddressRoadType",
      "separatedAddressRoadValue",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
    ],
    notRequiredFields: [
      "department",
      "index",
      "patronymic",
      "patronymicOrg",
      "clientComment",
      "partnerOrganizationName",
      "fnsCode",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
    ],
    notRequiredFieldsLicense: [
      "birthDate",
      "serialDoc",
      "dateDoc",
      "passportDivisionCode",
      "snils",
      "ogrnip",
      "position",
      "personInn",
      "ogrn",
      "shortNameOrg",
      "surnameOrg",
      "nameOrg",
      "patronymicOrg",
      "positionDirector",
      "passportDivision",
    ],
  }    
];

export const STATUSES = {
  InWork: {
    id: 1,
    title: "В работе"
  },
  WaitingForConfirmation: {
    id: 2,
    title: "Ожидание подтверждения"
  },
  WaitingForDocuments: {
    id: 3,
    title: "Ожидание документов"
  },
  NewPreApplication: {
    id: 10,
    title: "Новая предзаявка"
  },
  ClientRefused: {
    id: 11,
    title: "Клиент отказался"
  },
  OutOfReach: {
    id: 12,
    title: "Не выходит на связь"
  },
  OtherReason: {
    id: 13,
    title: "Прочее"
  },
  ApplicationFormed: {
    id: 20,
    title: "Заявка сформирована"
  },
  Duplicate: {
    id: 14,
    title: "Повтор"
  },
};

export const IDENTIFICATION_STATUSES = {
  New: {
    id: 1,
    title: "Новая идентификация"
  },
  Approved: {
    id: 2,
    title: "Идентификация подтверждена"
  },
  Declained: {
    id: 10,
    title: "Идентификация отклонена"
  },
};

export const PREAPP_TYPES = {
  Default: {
    id: 0
  },
  Identification: {
    id: 1
  }
};

export const IDENTIFICATION_NOTIFICATIONS = {
  Email: {
    value: 0,
    label: "email"
  },
  Sms: {
    value: 1,
    label: "sms"
  }
};

export const DEFAULT_SCANS = [
  {
    title: "Паспорт",
    index: 1,
    type: "doc-passport",
    guid: null,
  },
  {
    title: "Фото заявителя",
    index: 2,
    type: "doc-foto",
    guid: null,
  }
];

export const errorMessage = {
  size: "Размер файла не должен превышать 15 Мб.",
  type: "Неверный формат.",
};

export const ANOTHER_UPLOADS_MAX_COUNT = 10;

export const PAYER_TYPES = {
  himself: {
    id: 99,
    value: "himself",
    label: "Сам заявитель",
    activeFields: [
      "payerSubjectType",
    ],
    requiredFields: [
      "payerSubjectType",
    ]
  },
  fl: {
    id: 1,
    value: "fl",
    label: "Физическое лицо",
    activeFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
      "payerCity",
      // "payerAddress"
    ],
    requiredFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
      "payerCity",
      // "payerAddress",
    ],
  },
  ip: {
    id: 2,
    value: "ip",
    label: "Индивидуальный предприниматель",
    activeFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
      // "payerCity",
      // "payerAddress"
    ],
    requiredFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
      // "payerCity",
      // "payerAddress",
    ]
  },
  ur: {
    id: 3,
    value: "ur",
    label: "Юридическое лицо",
    activeFields: [
      "payerSubjectType",
      "payerName",
      // "payerShortName",
      "payerInn",
      "payerKpp",
      "payerPhone",
      "payerCountryId",
      "payerRegionId",
      "payerCity",
      "payerAddress",
      "payerPostalCode"
    ],
    requiredFields: [
      "payerSubjectType",
      "payerName",
      // "payerShortName",
      "payerInn",
      "payerKpp",
      "payerPhone",
      "payerCountryId",
      "payerRegionId",
      "payerCity",
      "payerAddress",
      // "payerPostalCode"
    ]
  },
};
