import { GetterTree } from "vuex";
import moment from "moment";

import { CommentsState, Comment } from "./interfaces/comments.state";
import { RootState } from "@/store/root.interface";

export const getCommentObject = (comment: Comment) => {
  let newComment = comment.text;
  let date = moment(comment.date).locale("ru").format("LLL");

  if (typeof newComment !== 'string') {
    date = moment(newComment.date).locale("ru").format("LLL");
    newComment = newComment.text;
  }

  return {
    text: newComment,
    date
  }
};

export const getters: GetterTree<CommentsState, RootState> = {
  getFormattedSortedComments(state) {
    if (!state.comments.length) {
      return [];
    }

    const comments = JSON.parse(JSON.stringify(state.comments))
      .sort((a: Comment,b: Comment) => {
        if (a.date > b.date) return -1;
        if (a.date == b.date) return 0;
        if (a.date < b.date) return 1;
      })
      .map((comment: Comment) => {
        return {
          ...comment,
          ...getCommentObject(comment),
        }
      })
    return comments;
  },

  getFormattedLastComment(state) {
    if (!state.lastComment.text) {
      return null;
    }

    return {
      ...state.lastComment,
      ...getCommentObject(state.lastComment),
    };
  },
}
