import { IFilterControls } from "../filter.interfaces";
import { ALL_FILTER_CONTROLS } from "./filter.defaults";

const defaultAppManagerPartnerFilterControls: IFilterControls = {
  applicant: {
    title: "Заявитель",
    name: "applicant",
    controls: {
      fl: {...ALL_FILTER_CONTROLS.fl},
      ip: {...ALL_FILTER_CONTROLS.ip},
      ur: {...ALL_FILTER_CONTROLS.ur},
    },
  },
  status: {
    title: "Статус",
    name: "status",
    controls: {
      statusSendDoc: {...ALL_FILTER_CONTROLS.statusSendDoc},
      statusGenReq: {...ALL_FILTER_CONTROLS.statusGenReq},
      statusOnModeration: {...ALL_FILTER_CONTROLS.statusOnModeration},
      statusRelease: {...ALL_FILTER_CONTROLS.statusRelease},
      statusDone: {...ALL_FILTER_CONTROLS.statusDone},
      statusFail: {...ALL_FILTER_CONTROLS.statusFail},
      statusPayed: {...ALL_FILTER_CONTROLS.statusPayed},
      statusWaitingOriginals: {...ALL_FILTER_CONTROLS.statusWaitingOriginals},
      statusOriginalsIncorrect: {...ALL_FILTER_CONTROLS.statusOriginalsIncorrect},
      statusClosed: {...ALL_FILTER_CONTROLS.statusClosed},
    }
  },
  dateCreate: {
    title: "Период создания",
    name: "dateCreate",
    controls: {
      dateCreateFrom: {...ALL_FILTER_CONTROLS.date},
      dateCreateTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  dateIssue: {
    title: "Период выдачи",
    name: "dateIssue",
    controls: {
      dateIssueFrom: {...ALL_FILTER_CONTROLS.date},
      dateIssueTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  datePayment: {
    title: "Период оплаты",
    name: "datePayment",
    controls: {
      datePaymentFrom: {...ALL_FILTER_CONTROLS.date},
      datePaymentTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  identificationKind: {
    title: "Способ идентификации",
    name: "identificationKind",
    controls: {
      identificationKind: {
        name: "identificationKind",
        type: "select",
        value: -1,
        options: []
      }
    }
  },
  companyGroups: {
    title: "Группа компаний",
    name: "companyGroups",
    controls: {
      companyGroups: {
        name: "companyGroups",
        type: "multiselect",
        value: [],
        options: []
      }
    }
  },
  other: {
    title: "Дополнительные фильтры",
    name: "other",
    controls: {
      managers: {
        type: "multiselect",
        label: "Менеджеры",
        name: "managers",
        value: [],
        options: [],
        disabled: false,
        touched: true
      },
      organizations: {
        type: "multiselect",
        label: "Организации",
        name: "organizations",
        value: [],
        options: [],
        disabled: false,
        touched: true
      }
    }
  }
};

export default defaultAppManagerPartnerFilterControls;
