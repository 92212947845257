import { GetterTree } from 'vuex';

import { RootState } from '@/store/root.interface';
import { getFormattedDate } from '@/helpers';
import {
  STATUSES,
  STATUS_IDS,
  DOCUMENT_TYPE_IDS,
  CERT_SUBJECT_TYPES,
  CERT_SUBJECT_TYPE_IDS,
  FORM_SECTIONS
} from '@/constants/revoke.constants';

import { IRevokeState, TFieldName } from './revoke.types';

export const getters: GetterTree<IRevokeState, RootState> = {
  getStatus(state): string {
    return state.statusId && state.statusId in STATUS_IDS ? STATUSES[state.statusId].name : 'статус неопределён';
  },
  getApplicantType(state): string {
    return state.applicantType && state.applicantType in CERT_SUBJECT_TYPE_IDS ? CERT_SUBJECT_TYPES[state.applicantType] : 'тип неопределён';
  },
  getDateCreate(state): string {
    return state.dateCreate ? getFormattedDate(state.dateCreate, 'L') : 'дата неопределена';
  },
  getDateUpdate(state): string | null {
    return state.dateUpdate ? getFormattedDate(state.dateUpdate, 'L') : null;
  },
  getDateRevoke(state): string | null {
    return state.dateRevoke ? getFormattedDate(state.dateRevoke, 'L') : null;
  },
  getActiveFields(state): (section: FORM_SECTIONS) => Array<TFieldName> {
    return (section: FORM_SECTIONS): Array<TFieldName> => {
      return Object.keys(state.fields).filter(fieldName => {
        const isInSection = state.fields[fieldName as TFieldName].section == section;
        switch(state.applicantType) {
          case CERT_SUBJECT_TYPE_IDS.Person:
            return !['organizationName', 'innOrganization'].includes(fieldName) && isInSection;
          case CERT_SUBJECT_TYPE_IDS.Entrepreneur:
            return !['organizationName', 'innOrganization'].includes(fieldName) && isInSection;
          case CERT_SUBJECT_TYPE_IDS.Legal:
            return isInSection;
          case CERT_SUBJECT_TYPE_IDS.Employee:
            return isInSection;
        }
      }) as TFieldName[];
    }
  },

  /* 
    Из документов нас интересует скан заявления и доверенность.
    Должен быть загружен хотя бы один из файлов.
  */
  enableToCollect(state) {
    return Object.entries(state.clientDocuments)
      .filter(([documentType, _]) => 
        [DOCUMENT_TYPE_IDS.RevokeApplicationScan, DOCUMENT_TYPE_IDS.RevokeProcuration]
          .includes(Number(documentType as unknown as DOCUMENT_TYPE_IDS)))
      .reduce((result, [_, document]) => {
        return result || !!document!.files.length;
      }, false);
  },

  /* 
    Из документов нас интересует скан заявления и доверенность.
    Если заявка в статусе "Подтверждено, ожидает обработки", то смотрим, чтобы все обязательные документы были загружены.
    Если нет, то все обязательные документы должны быть загружены или уже храниться на сервере.
  */
  enableToSign(state) {
    const checks: boolean[] = Object.entries(state.clientDocuments)
      .filter(([documentType, _]) => 
        [DOCUMENT_TYPE_IDS.RevokeApplicationScan, DOCUMENT_TYPE_IDS.RevokeProcuration]
          .includes(Number(documentType as unknown as DOCUMENT_TYPE_IDS)))
      .reduce((result: boolean[], [_, document]) => {
        if (state.statusId === STATUS_IDS.DocumentsReady) {
          return [...result, document!.required && !!document!.files.length];
        }
        return [...result, document!.required && (!!document!.files.length || document!.isCollected)];
      }, []);
    return !checks.includes(false)
  },

  disableToEdit(state) {
    return [STATUS_IDS.Success, STATUS_IDS.DocumentsReady].includes(state.statusId!);
  },

  getPhone(state) {
    return state.fields.phone.value;
  },

  getEmail(state) {
    return state.fields.email.value;
  },
};
