import Vue from "vue";
import {ActionTree} from "vuex";

import {isFormValid, validation} from "@/services";
import { TOAST_OPTIONS } from "@/constants";

import {RootState} from "@/store/root.interface";
import {IRulesValidation} from "@/services/validation";

import {defaultFormControls} from "./change-password.default";

import {
    CLEAR_FORM_CONTROLS,
    SET_FORM_CONTROL,
    SET_IS_FORM_VALID
} from "@/store/modules/change-password/constants/mutations-type";
import {ChangePasswordState} from "@/store/modules/change-password/interfaces/change-password.state";
import {SET_ERROR_MESSAGE, SET_IS_SUBMIT_ACTIVE} from "@/store/modules/authorization/authorization.mutations";
import {fetch, setAccessToken} from "@/services/api";
import {BASE_URL_AUTH} from "@/constants";
import cookies from "js-cookie";
import router from "@/router";

export const actions: ActionTree<ChangePasswordState, RootState> = {

    inputHandler({commit, state}, evt) {
        if (!state.isSubmitActive) {
            const control = {...state.formControls[evt.target.name]};

            control.value = evt.target.value;
            control.isTouched = true;

            if (evt.type === "blur" || control.isBlur) {
                control.isBlur = true;

                const {isValid} = validation(control.value, control.rules as IRulesValidation);
                control.isValid = isValid;
            }

            commit(SET_FORM_CONTROL, {control, name: evt.target.name});

            if ((control.isBlur && !control.isValid) || !state.isFormValid) {
                const formControls = state.formControls;
                commit(SET_IS_FORM_VALID, isFormValid(formControls));
            }
        }
    },

    clear({commit, state}) {
        commit(CLEAR_FORM_CONTROLS);

        Object.keys(state.formControls).forEach((name: string) => {
            commit(SET_FORM_CONTROL, {
                name,
                control: {...(defaultFormControls as any)[name]}
            });
        });
    },

    checkValidity({commit, state}) {
        Object.keys(state.formControls).forEach((name: string) => {
            const control = {...state.formControls[name]};
            control.isTouched = true;
            control.isBlur = true;

            const {isValid} = validation(control.value, control.rules as IRulesValidation);
            control.isValid = isValid;

            commit(SET_FORM_CONTROL, {control, name});
        });

        const formControls = state.formControls;

        commit(SET_IS_FORM_VALID, isFormValid(formControls));

        if (state.formControls["password"].value !== state.formControls["passwordConfirm"].value) {
            state.formControls["password"].isValid = false;
            state.formControls["passwordConfirm"].isValid = false;

            commit(SET_IS_FORM_VALID, false);
        }
    },

    async setPassword({commit, state, dispatch}) {
        dispatch("checkValidity");

        if (state.isFormValid) {
            commit(SET_IS_SUBMIT_ACTIVE, true);

            const body = {
                OldPassword: state.formControls.oldPassword.value,
                NewPassword: state.formControls.password.value,
            };

            try {
                const {status, data} = await fetch.post(
                    `${BASE_URL_AUTH}api/auth/change`,
                    body
                );

                if (status === 200) {
                    const {access_token, refresh_token, expires_in} = data;
                    Vue.$toast.success("Пароль успешно изменён. Пожалуйста, повторите попытку входа с новым паролем", TOAST_OPTIONS.Success);
                    cookies.remove("_rt");
                    setAccessToken(null);
                    commit(SET_ERROR_MESSAGE, null);
                    router.push({name: "login"});
                    dispatch("clear");
                }
            } catch (error) {
                commit(SET_ERROR_MESSAGE, "Ошибка при изменении пароля");

                throw error;
            } finally {
                commit(SET_IS_SUBMIT_ACTIVE, false);
            }
        }
    }
};
