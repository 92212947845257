import { Module } from "vuex";

import { actions } from "./comments.actions";
import { mutations } from "./comments.mutations";
import { getters } from "./comments.getters";


import { CommentsState } from "./interfaces/comments.state";
import { RootState } from "@/store/root.interface";

const namespaced: boolean = true;
export const comments: Module<CommentsState, RootState> = {
  namespaced,
  state: {
    comments: [],
    lastComment: {name: '', position: '', date: '', text: ''},
    isLoaded: false,
  },
  mutations,
  actions,
  getters
};
