


















import { Vue, Component, Prop } from "vue-property-decorator";
import Spiner from "./Spiner.vue";

@Component({ name: "Button", components: { Spiner } })
export default class Button extends Vue {
  @Prop({ type: [String], default: "button" })
  public readonly role!: string;

  @Prop({ type: [Boolean], default: false })
  public readonly isLoader!: boolean;

  @Prop({ type: [Boolean], default: false })
  public readonly disabled!: boolean;

  @Prop({ type: [String], default: "normal" })
  public readonly size!: "small" | "big";

  @Prop({ type: [String], default: "purple" })
  public readonly color!: "purple" | "purple-ghost";

  @Prop({ type: [String], default: "submit" })
  public readonly type?: "button" | "reset" | "submit";

  @Prop({ type: [Object] })
  public readonly styles?: Record<string, string>;

  private get spinerColor() {
    switch (this.color) {
      case "purple":
        return "#fff";
      case "purple-ghost":
        return "#6f61e9";
      default:
        return "#000";
    }
  }
}
