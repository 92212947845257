import { IFilterControls } from "../filter.interfaces";
import { ALL_FILTER_CONTROLS } from "./filter.defaults";

export const defaultCertificatesFilterControls: IFilterControls = {
  dateValid: {
    title: "Период действия",
    name: "dateValid",
    controls: {
      dateValidFrom: {...ALL_FILTER_CONTROLS.date},
      dateValidTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  dateExpired: {
    title: "Период окончания действия",
    name: "dateExpired",
    controls: {
      dateExpiredFrom: {...ALL_FILTER_CONTROLS.date},
      dateExpiredTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
};

export default defaultCertificatesFilterControls;
