import { InitialFileTypes } from "@/constants/InitialFileTypes";
import { TTypeEntre, IApplicantType } from "./application.interfaces";

export const STATUSES = {
  DocumentsSending: {
    id: 1,
    title: "Отправка документов"
  },
  CertificateRequest: {
    id: 2,
    title: "Генерация запроса"
  },
  CertificateIssue: {
    id: 3,
    title: "Выпуск"
  },
  CertificateReady: {
    id: 4,
    title: "Готово"
  },
  Rejection: {
    id: 5,
    title: "Отказ"
  },
  HeadManagerSign: {
    id: 11,
    title: "Ожидание подписи Главного менеджера"
  },
  Moderation: {
    id: 21,
    title: "На модерации"
  },
  SystemProcessing: {
    id: 22,
    title: "Обработка системы"
  },
  EDORegistration: {
    id: 23,
    title: "Регистрация в ЭДО"
  },
  EDORegistrationError: {
    id: 24,
    title: "Ошибка регистрации в ЭДО"
  },
  WaitingForOriginals: {
    id: 26,
    title: "Ожидание оригиналов"
  },
  Closed: {
    id: 27,
    title: "Завершено"
  },
  OriginalsIncorrect: {
    id: 28,
    title: "Оригиналы некорректны"
  }
};

export const EDM_TYPES = {
  Sbis: {
    id: 1,
    title: "ЭДО Сбис"
  },
  EDem: {
    id: 2,
    title: "ЭДО E.Dem"
  }
};

export const errorMessage = {
  size: "Размер файла не должен превышать 15 Мб.",
  type: "Неверный формат.",
};

export const ANOTHER_UPLOADS_MAX_COUNT = 10;

export const DOWNLOAD_DOCUMENTS = [
  {
    typeId: InitialFileTypes.CertificateRequest,
    // img: "/img/doc.svg",
    type: "doc",
    title: "Запрос",
    descr: "сертификата"
  },
  {
    typeId: InitialFileTypes.Application,
    // img: "/img/doc.svg",
    type: "doc",
    title: "Заявление",
    descr: "№ %% на выпуск ЭП"
  },
  {
    typeId: InitialFileTypes.Procuration,
    // img: "/img/doc.svg",
    type: "doc",
    title: "Доверенность",
    descr: ""
  },
  {
    typeId: InitialFileTypes.Bill,
    // img: "/img/doc-score.svg",
    type: "doc-score",
    title: "Счет",
    descr: "№ %% на выпуск ЭП"
  },
  {
    typeId: InitialFileTypes.UPD,
    // img: "/img/doc.svg",
    type: "doc",
    title: "Универсальный передаточный документ",
    descr: ""
  },
  {
    typeId: InitialFileTypes.ClientAgreement,
    // img: "/img/doc-key.svg",
    type: "doc",
    title: "Клиентский договор",
    descr: ""
  },
  {
    typeId: InitialFileTypes.PrintingForm,
    // img: "/img/doc.svg",
    type: "doc",
    title: "Печатная форма",
    descr: "СКП ЭП"
  },
  {
    typeId: InitialFileTypes.KeyCertificate,
    // img: "/img/doс-cert.svg",
    type: "doc-cert",
    title: "Сертификат ключа",
    descr: ""
  },
  {
    typeId: InitialFileTypes.Revocation,
    // img: "/img/doс.svg",
    type: "doc",
    title: "Отзыв",
    descr: "На сертификат %1%"
  },
  {
    typeId: InitialFileTypes.InsurancePolicy,
    // pdf
    type: "doc",
    title: "Полис страхования",
    descr: ""
  },
];

export const PAYER_TYPES = {
  himself: {
    id: 99,
    value: "himself",
    label: "Сам заявитель",
    activeFields: [
      "payerSubjectType",
    ],
    requiredFields: [
      "payerSubjectType",
    ]
  },
  fl: {
    id: 1,
    value: "fl",
    label: "Физическое лицо",
    activeFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
      "payerCity",
      // "payerAddress",
    ],
    requiredFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
    ],
  },
  ip: {
    id: 2,
    value: "ip",
    label: "Индивидуальный предприниматель",
    activeFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId",
      // "payerCity",
      // "payerAddress"
    ],
    requiredFields: [
      "payerSubjectType",
      "payerName",
      "payerInn",
      "payerPhone",
      // "payerCountryId",
      // "payerRegionId"
    ]
  },
  ur: {
    id: 3,
    value: "ur",
    label: "Юридическое лицо",
    activeFields: [
      "payerSubjectType",
      "payerName",
      // "payerShortName",
      "payerInn",
      "payerKpp",
      "payerPhone",
      "payerCountryId",
      "payerRegionId",
      "payerCity",
      "payerAddress",
      "payerPostalCode"
    ],
    requiredFields: [
      "payerSubjectType",
      "payerName",
      // "payerShortName",
      "payerInn",
      "payerKpp",
      "payerPhone",
      "payerCountryId",
      "payerRegionId",
      "payerCity",
      "payerAddress"
    ]
  },
};

export const IDENTIFICATION_DOCUMENT_TYPES = {
  russian: {
    id: 1,
    label: "Паспорт гражданина РФ",
    activeFields: ["passportDivisionCode"]
  },
  foreign: {
    id: 2,
    label: "Документ иностранного гражданина",
    activeFields: ["docSeries"]
  }
};

export const APPLICANT_TYPES: Record<TTypeEntre, IApplicantType> = {
  fl: {
    id: 1,
    label: "Физическое лицо",
    activeFields: [
      "identificationKind",
      "surname",
      "name",
      "patronymic",
      "gender",
      "email",
      "phone",
      "dateDoc",
      "birthDate",
      "identificationDocumentTypeId",
      "docSeries",
      "serialDoc",
      "inn",
      "snils",
      "countryId",
      "regionRealId",
      "cityReal", // определяется отдельно в DEFINE_ADDRESS_FIELDS
      "addressReal", // определяется отдельно в DEFINE_ADDRESS_FIELDS
      "addovert",
      "ksk",
      "kskcom",
      "cryptopro",
      "passportDivision",
      "passportDivisionCode",
      "managerComment",
      "payerSubjectType",
      "companyGroupName",
      "companyGroupInn",
      "payerSubjectType",
      "separatedAddressBuildingType",
      "separatedAddressBuildingValue",
      "separatedAddressLocalityType",
      "separatedAddressLocalityValue",
      "separatedAddressRoadType",
      "separatedAddressRoadValue",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
      "govContract",
    ],
    nonRequiredFields: [
      "companyGroupName",
      "companyGroupInn",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
      "index",
    ],
  },
  ip: {
    id: 2,
    label: "Индивидуальный предприниматель",
    activeFields: [
      "identificationKind",
      "surname",
      "name",
      "patronymic",
      "gender",
      "email",
      "phone",
      "dateDoc",
      "birthDate",
      "identificationDocumentTypeId",
      "docSeries",
      "serialDoc",
      "snils",
      "inn",
      "ogrnip",
      "countryId",
      "regionRealId",
      "cityReal", // определяется отдельно в DEFINE_ADDRESS_FIELDS
      "addressReal", // определяется отдельно в DEFINE_ADDRESS_FIELDS
      "ksk",
      "kskcom",
      "cryptopro",
      "addovert",
      "phoneOrg",
      "passportDivision",
      "passportDivisionCode",
      "managerComment",
      "forInfoSys",
      "companyGroupName",
      "companyGroupInn",
      "forInfoSys",
      "separatedAddressBuildingType",
      "separatedAddressBuildingValue",
      "separatedAddressLocalityType",
      "separatedAddressLocalityValue",
      "separatedAddressRoadType",
      "separatedAddressRoadValue",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
      "govContract",
    ],
    nonRequiredFields: [
      "companyGroupName",
      "companyGroupInn",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
      "index",
    ],
  },
  ur: {
    id: 3,
    label: "Юридическое лицо",
    activeFields: [
      "identificationKind",
      "surname",
      "name",
      "patronymic",
      "gender",
      "email",
      "phone",
      "dateDoc",
      "birthDate",
      "identificationDocumentTypeId",
      "docSeries",
      "serialDoc",
      "passportDivision",
      "passportDivisionCode",
      "personInn",
      "inn",
      "snils",
      "ogrn",
      "department",
      "position",
      "countryId",
      "regionLawId",
      "cityLaw", // определяется отдельно в DEFINE_ADDRESS_FIELDS
      "addressLaw", // определяется отдельно в DEFINE_ADDRESS_FIELDS
      "ksk",
      "kskcom",
      "cryptopro",
      "addovert",
      "surnameOrg",
      "nameOrg",
      "patronymicOrg",
      "phoneOrg",
      "fullNameOrg",
      "shortNameOrg",
      "positionDirector",
      "kpp",
      "index",
      "managerComment",
      "forInfoSys",
      "basisOfActs",
      "companyGroupName",
      "companyGroupInn",
      "separatedAddressBuildingType",
      "separatedAddressBuildingValue",
      "separatedAddressLocalityType",
      "separatedAddressLocalityValue",
      "separatedAddressRoadType",
      "separatedAddressRoadValue",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
      "govContract",
    ],
    nonRequiredFields: [
      "companyGroupName",
      "companyGroupInn",
      "separatedAddressApartmentType",
      "separatedAddressApartmentValue",
      "index",
    ],
  },
};
// Статусы api basis
export const BASIS_STAUSES = {
  SUCCESS: 1,
  // В api basic найдено несколько с таким ИНН
  FOUND_MORE_WITH_THAT_INN: 2
};

export const APPLICATION_TYPES = {
  Reissue: {id: 0},
  Issue: {id: 1}
};

export enum SKZI_STATE_IDS {
  HasNotUnlimitedLicenseProduct = 0,
  HasUnlimitedLicenseProduct = 1,
  UpdStatusUnknown = 2,
  UpdStatusSuccess = 3,
  LicenseRequested = 4,
  LicenseReady = 5,
}
