import axios from "axios";
import cookie from "js-cookie";
import router from "@/router";
import { isNull } from "lodash";
import decoded from "jwt-decode";

import { BASE_URL_AUTH, BASE_URL_API } from "@/constants";

if (!BASE_URL_API) {
  throw new Error("BASE_URL_API is not defined");
}

let accessToken: string | null = null;
let timerId: null | number | NodeJS.Timer = null;
const instance = axios.create({ baseURL: BASE_URL_API });

export const getRole = () => {
  try {
    if (!isNull(accessToken)) {
      const decodedJWT = decoded<{ role: string }>(accessToken);
      return decodedJWT && decodedJWT.role ? decodedJWT.role : "";
    } else {
      throw new Error("accessToken is null")
    }
  } catch(err) {
    return "";
  }
};

export const getNameId = () => {
  const refreshToken = cookie.get("_rt");

  try {
    if (refreshToken && !isNull(refreshToken)) {
      const decodedJWT = decoded<{ nameid: string }>(refreshToken);
      return decodedJWT && decodedJWT.nameid ? Number(decodedJWT.nameid) : null;
    } else {
      throw new Error("refreshToken is null")
    }
  } catch(err) {
    return null;
  }
};

export const isAuthenticate = () => cookie.get("_rt") && !isNull(accessToken);

export const setAccessToken = (token: string | null, expires: number = 0) => {
  accessToken = token;

  if (timerId !== null) {
    clearTimeout(timerId as number);
  }

  if (!token || expires !== 0) {
    const timeout = expires - 30000 > 180000 ? expires - 30000 : 180000;

    timerId = setTimeout(refreshAccessToken, timeout);
  }
};

export const refreshAccessToken = async (): Promise<boolean> => {
  const refreshToken = cookie.get("_rt");

  try {
    const {
      data: { access_token, refresh_token, expires_in },
      status
    } = await axios.post(`${BASE_URL_AUTH}api/auth/refresh`, {
      refreshToken
    });

    if (status === 200) {
      cookie.set("_rt", refresh_token);
      setAccessToken(access_token, expires_in);

      return true;
    }

    return false;
  } catch (error) {
    cookie.remove("_rt");

    return false;
  }
};

instance.interceptors.request.use(
  config => {
    if (!accessToken) {
      return config;
    }

    const newConfig = {
      headers: {},
      ...config
    };

    newConfig.headers.Authorization = `bearer ${accessToken}`;

    return newConfig;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  async error => {
    try {
      const refreshToken = cookie.get("_rt");

      if (
        !refreshToken ||
        error.response.status !== 401 ||
        error.config.retry
      ) {
        throw error;
      }

      await refreshAccessToken();

      const newRequest = {
        ...error.config,
        retry: true
      };

      return instance(newRequest);
    } catch (error) {
      router.push({ name: "login" });
      throw error;
    }
  }
);

export default instance;
