import {MutationTree} from "vuex";

import { ISmsState } from './sms.types';

export const SET_SMS_INFO = 'SET_SMS_INFO';
export const CLEAR_SMS_STATE = 'CLEAR_SMS_STATE';
export const SET_SMS_HISTORY = 'SET_SMS_HISTORY';
export const SET_SMS_ALLOWED_TO_SEND = 'SET_SMS_ALLOWED_TO_SEND';

export const mutations: MutationTree<ISmsState> = {
  [SET_SMS_INFO](state, smsInfo) {
    state.smsInfo = smsInfo;
  },
  [SET_SMS_HISTORY](state, smsHistory) {
    state.smsHistory = smsHistory;
  },
  [SET_SMS_ALLOWED_TO_SEND](state, isAllowedToSend) {
    state.isAllowedToSend = isAllowedToSend;
  },
  [CLEAR_SMS_STATE](state) {
    console.log(CLEAR_SMS_STATE)
    state.smsHistory = [];
    state.isAllowedToSend = true;
    state.smsInfo = {
      filialPhone: '',
      filialAddress: '',
      filialName: '',
    };
  }
};
