import {
  CERT_ISSUER_TYPES,
  CERT_ISSUER_TYPE_IDS,
  APPLICATION_SOURCES,
  APPLICATION_SOURCE_IDS,
  REVOKE_IDENTIFICATION_TYPES,
  REVOKE_IDENTIFICATION_TYPE_IDS,
  REVOKE_REASON_TYPES,
  REVOKE_REASON_TYPE_IDS,
  FORM_SECTIONS
} from '@/constants/revoke.constants';

export const REVOKE_FORM_FIELDS = {
  certSerialNumber: {
    label: 'Серийный номер сертификата',
    type: 'input',
    section: FORM_SECTIONS.Application,
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  certIssuerType: {
    label: 'Издатель',
    type: 'select',
    section: FORM_SECTIONS.Application,
    options: [
      {value: CERT_ISSUER_TYPE_IDS.Uc, label: CERT_ISSUER_TYPES[CERT_ISSUER_TYPE_IDS.Uc]},
      {value: CERT_ISSUER_TYPE_IDS.Fts, label: CERT_ISSUER_TYPES[CERT_ISSUER_TYPE_IDS.Fts]},
      {value: CERT_ISSUER_TYPE_IDS.Lissi, label: CERT_ISSUER_TYPES[CERT_ISSUER_TYPE_IDS.Lissi]},
    ],
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  applicationSource: {
    label: 'Источник заявки',
    type: 'select',
    section: FORM_SECTIONS.Application,
    options: [
      {value: APPLICATION_SOURCE_IDS.Lkk, label: APPLICATION_SOURCES[APPLICATION_SOURCE_IDS.Lkk]},
      {value: APPLICATION_SOURCE_IDS.Api, label: APPLICATION_SOURCES[APPLICATION_SOURCE_IDS.Api]},
      {value: APPLICATION_SOURCE_IDS.Lkf, label: APPLICATION_SOURCES[APPLICATION_SOURCE_IDS.Lkf]},
    ],
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  revokeReasonType: {
    label: 'Причина отзыва',
    type: 'select',
    section: FORM_SECTIONS.Application,
    options: [
      {value: REVOKE_REASON_TYPE_IDS.ReleaseNew, label: REVOKE_REASON_TYPES[REVOKE_REASON_TYPE_IDS.ReleaseNew]},
      {value: REVOKE_REASON_TYPE_IDS.TokenLost, label: REVOKE_REASON_TYPES[REVOKE_REASON_TYPE_IDS.TokenLost]},
      {value: REVOKE_REASON_TYPE_IDS.TokenBroken, label: REVOKE_REASON_TYPES[REVOKE_REASON_TYPE_IDS.TokenBroken]},
      {value: REVOKE_REASON_TYPE_IDS.TokenStolen, label: REVOKE_REASON_TYPES[REVOKE_REASON_TYPE_IDS.TokenStolen]},
      {value: REVOKE_REASON_TYPE_IDS.NotNeeded, label: REVOKE_REASON_TYPES[REVOKE_REASON_TYPE_IDS.NotNeeded]},
    ],
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  identificationType: {
    label: 'Тип идентификации',
    type: 'select',
    section: FORM_SECTIONS.Applicant,
    options: [
      {value: REVOKE_IDENTIFICATION_TYPE_IDS.Direct, label: REVOKE_IDENTIFICATION_TYPES[REVOKE_IDENTIFICATION_TYPE_IDS.Direct]},
      {value: REVOKE_IDENTIFICATION_TYPE_IDS.Signature, label: REVOKE_IDENTIFICATION_TYPES[REVOKE_IDENTIFICATION_TYPE_IDS.Signature]},
    ],
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  organizationName: {
    label: 'Наименование организации',
    type: 'input',
    section: FORM_SECTIONS.Organization,
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  lastName: {
    label: 'Фамилия',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: false,
    disabled: true,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
  },
  firstName: {
    label: 'Имя',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: true,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  middleName: {
    label: 'Отчество',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: false,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  innPersonal: {
    label: 'ИНН заявителя',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: false,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {
      inn: true,
      minLength: 12
    },
  },
  innOrganization: {
    label: 'ИНН организации',
    type: 'input',
    section: FORM_SECTIONS.Organization,
    value: "",
    active: false,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: false,
    rules: {
      inn: true,
      minLength: 10
    },
  },
  snils: {
    label: 'СНИЛС',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: false,
    disabled: true,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      snils: true,
      minLength: 11
    },
    invalidityInfo: "",
  },
  phone: {
    label: 'Телефон',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: true,
    isBlur: false,
    required: true,
    rules: {},
    invalidityInfo: "",
  },
  email: {
    label: 'Электронный адрес',
    type: 'input',
    section: FORM_SECTIONS.Applicant,
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: true,
    isBlur: false,
    required: true,
    rules: {},
    invalidityInfo: "",
  },
};
