// должно совпадать с common-libs/Enums/InitialFileTypes
export enum InitialFileTypes {
  // Файл запроса сертификата
  CertificateRequest,

  // Заявление на выпуск СКП ЭП
  Application,

  // Доверенность на представителя
  Procuration,

  // Счёт на оплату
  Bill,

  // Универсальный передаточный документ (УПД)
  UPD,

  // Клиентский договор на оказание услуг по выпуску СКП ЭП
  ClientAgreement,

  // Печатная форма СКП ЭП
  PrintingForm,

  // Файл сертификата
  KeyCertificate,

  // Архив с документами
  DocumentsZip,

  // Файл подписи архива с документами
  DocumentsZipSig,

  // Файл лицензии КриптоПро 4.0 (годовая)
  CryptoProKey,

  // Файл лицензии КриптоПро 4.0 (бессрочная)
  CryptoProKeyTimeless,

  // (deprecated) Файл лицензии КриптоАрм Стандарт Плюс 5.0 (бессрочная)
  // CryptoArmStandardPlus,
  // Файл паспорта
  Passport = 12,

  // (deprecated) Файл лицензии КриптоАрм Стандарт 5.0 (бессрочная)
  // CryptoArmStandard,
  // Файл СНИЛС
  Snils = 13,

  // (deprecated) Файл лицензии КриптоАрм 5.0 (бессрочная)
  // CryptoArm,
  // Файл с фото клиента
  Photo = 14,

  // (deprecated) Файл лицензии КриптоПро 5.0 (бессрочная)
  // CryptoPro5,
  //Печатная форма руководства по обеспечению безопасности
  SecurityInstructionForm = 15,

  // Файл подписи архива с документами главным менеджером
  DocumentsZipHeadSig = 16,

  // Дополнительные документы         
  ExtraDocs = 17,
  
  //Заявление на отзыв сертификата
  Revocation = 18,

  //Заявление на сертификацию
  VerificationAgreement = 19,

  //Запрос на сертификат, подписанный присоединенной подписью "ФАМИЛИЯ_request_номерЗаявки.sgn"
  CertificateRequestSigned = 20,

  CertificateRevokeSigned = 21,

  //Распечатанный и подписанный клиентом сертификат"
  ApplicantSignedPrintingForm = 22,

  //Распечатанное и подписанное клиентом руководство по безопасности"
  ApplicantSignedSecurityInstructionForm = 23,

  // Подпись к заявлению на выпуск
  ApplicationSignature = 25,

  // Отсоединённая подпись отзыва
  RevocationDetachedSign = 31,

  //Подписанная печатная форма(клиентом через ЭП)
  PrintingFormSignature = 32,

  // Полис страхования
  InsurancePolicy = 37,
}
