import { Module } from "vuex";

import { RootState } from "@/store/root.interface";
import Crypto from "@/services/CryptoProWrapper/CryptoProWrapper";

import { actions } from "./crypto.actions";
import { getters } from "./crypto.getters";
import { mutations } from "./crypto.mutations";
import { ICryptoState } from "./crypto.types";

export const crypto: Crypto = new Crypto();
crypto.load();

export const cryptoState: Module<ICryptoState, RootState> = {
  namespaced: true,
  state: {
    certificates: [],
    currentThumbprint: null,
    errorMessage: null,
  },
  mutations,
  actions,
  getters
};
