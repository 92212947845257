import Vue from 'vue';
import { ActionTree } from 'vuex';

import { RootState } from '@/store/root.interface';
import { logError } from "@/services/api/serverLogger";
import { ANALIT_CENTR_OGRN } from '@/config/common';
import { TOAST_OPTIONS } from '@/constants';

import { crypto } from './crypto.state';
import { ICryptoState } from "./crypto.types";
import {
  CLEAR_CRYPTO_STATE,
  SET_CRYPTO_CERTIFICATES,
  SET_CRYPTO_ERROR_MESSAGE,
  SET_CRYPTO_CURRENT_THUMBPRINT
} from './crypto.mutations';

export const actions: ActionTree<ICryptoState, RootState> = {
  cryptoLoad() {
    crypto.load();
  },

  setCurrentThumbprint({commit}, thumbprint: string) {
    commit(SET_CRYPTO_CURRENT_THUMBPRINT, thumbprint);
  },

  clearCryptoState({commit}) {
    commit(CLEAR_CRYPTO_STATE);
  },

  async getCertificates({commit}) {
    try {
      await crypto.getCertificates({filter: ANALIT_CENTR_OGRN});
      commit(SET_CRYPTO_CERTIFICATES, crypto.certs);
    } catch (error) {
      logError((error as any), "Ошибка чтения сертификатов");
    }
  },

  async getSignedDocument({state, commit}, {document, detached = false}: {document: string; detached?: boolean}) {
    try {
      if (!state.currentThumbprint) throw new Error('Не выбран сертификат для подписи. Код 1');

      const сertificate = crypto.certs.find(cert => cert.value === state.currentThumbprint);
      if (сertificate === undefined) throw new Error('Не выбран сертификат для подписи. Код 2');

      const documentAsBase64 = document.replace(/^.+base64,/, '');
      const signedDocument = await crypto.signRequest(documentAsBase64, state.currentThumbprint, detached);
      return signedDocument;
    } catch(error) {
      const errorMessage = ((error as any).response && (error as any).response.data)
                          || (error as any).message
                          || 'Неизвестная ошибка';
      commit(SET_CRYPTO_ERROR_MESSAGE, errorMessage);
      Vue.$toast.error(`Ошибка при подписи документа: ${errorMessage}`, TOAST_OPTIONS.Error);
    }
  },
};
