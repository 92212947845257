import { Module } from "vuex";

import { RootState } from "@/store/root.interface";

import { actions } from "./application-list.actions";
import { getters } from "./application-list.getters";
import { mutations } from "./application-list.mutations";
import { IApplicationListState } from "./application-list.interfaces";

export const applicationListState: Module<IApplicationListState, RootState> = {
  namespaced: true,
  state: {
    applicationsList: [],
    totalPages: null,
    sizePage: 10,
    activePage: 1,
    isLoaded: false,
    isArchive: false,
    isChiefManager: false,
    isManagerPartner: false,
  },
  mutations,
  actions,
  getters
};
