import { fetch } from '@/services/api';
import { FILTER_LIST_URLS as URL_LIST } from '@/config/urls';
import { IProductOption } from './filter.interfaces';
import { PAGE_NAMES } from './filter.constants';

const getProducts = async (pageName: PAGE_NAMES) => {
  const url = pageName.startsWith('app')
              ?  URL_LIST.GetAppProducts
              : pageName.startsWith('preapp')
                ? URL_LIST.GetPreappProducts
                : pageName.startsWith('certificates')
                  ? ''
                  : pageName.startsWith('revokes')
                    ? ''
                    : '';

  const response: {
    data?: {
      products: Array<IProductOption>;
    };
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.post(url);
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
    response.data = data;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }
  
  return response;
};

export default {
  getProducts,
};
