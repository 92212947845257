import { MutationTree } from 'vuex';

import { ICryptoState } from "./crypto.types";

export const SET_CRYPTO_CERTIFICATES = 'SET_CRYPTO_CERTIFICATES';
export const SET_CRYPTO_ERROR_MESSAGE = 'SET_CRYPTO_ERROR_MESSAGE';
export const SET_CRYPTO_CURRENT_THUMBPRINT = 'SET_CRYPTO_CURRENT_THUMBPRINT';
export const CLEAR_CRYPTO_STATE = 'CLEAR_CRYPTO_STATE';

export const mutations: MutationTree<ICryptoState> = {
  [SET_CRYPTO_CERTIFICATES](state, certificates) {
    state.certificates = certificates;
  },
  [SET_CRYPTO_CURRENT_THUMBPRINT](state, currentThumbprint) {
    state.currentThumbprint = currentThumbprint;
  },
  [SET_CRYPTO_ERROR_MESSAGE](state, errorMessage: string) {
    state.errorMessage = errorMessage;
  },
  [CLEAR_CRYPTO_STATE](state) {
    state.certificates = [];
    state.currentThumbprint = null;
    state.errorMessage = null;
  },
};
