import { CERT_ISSUER_TYPES, CERT_ISSUER_TYPE_IDS } from "@/constants/revoke.constants";

export const MAX_FILTER_PRESETS_ON_PAGE = 5;

export const CREAT_PRESET_OPTION = {label: "+ Создать новый пресет", pageName: "", filterControls: []};

export const REVOKE_ISSUER_TYPE_OPTIONS = [
  {
    value: -1,
    label: "Не выбрано"
  },
  {
    value: CERT_ISSUER_TYPE_IDS.Uc,
    label: CERT_ISSUER_TYPES[CERT_ISSUER_TYPE_IDS.Uc]
  },
  {
    value: CERT_ISSUER_TYPE_IDS.Fts,
    label: CERT_ISSUER_TYPES[CERT_ISSUER_TYPE_IDS.Fts]
  },
  {
    value: CERT_ISSUER_TYPE_IDS.Lissi,
    label: CERT_ISSUER_TYPES[CERT_ISSUER_TYPE_IDS.Lissi]
  },
];

export enum PAGE_NAMES {
  app = "app",
  appArchive = "appArchive",
  appManagerPartner = "appManagerPartner",
  appManagerPartnerArchive = "appManagerPartnerArchive",
  preapp = "preapp",
  certificates = "certificates",
  appHeadManager = "appHeadManager",
  appHeadManagerArchive = "appHeadManagerArchive",
  revokes = "revokes",
};

export enum FILTER_CONTROLS_NAMES {
  app = "appFilterControls",
  appArchive = "appArchiveFilterControls",
  appManagerPartner = "appManagerPartnerFilterControls",
  appManagerPartnerArchive = "appManagerPartnerArchiveFilterControls",
  preapp = "preappFilterControls",
  certificates = "certificatesFilterControls",
  appHeadManager = "appHeadManagerFilterControls",
  appHeadManagerArchive = "appHeadManagerArchiveFilterControls",
  revokes = "revokesFilterControls",
};

export enum SAVED_FILTER_CONTROLS_NAMES {
  app = "savedAppFilterControls",
  appArchive = "savedAppArchiveFilterControls",
  appManagerPartner = "savedAppManagerPartnerFilterControls",
  appManagerPartnerArchive = "savedAppManagerPartnerArchiveFilterControls",
  preapp = "savedPreappFilterControls",
  certificates = "savedCertificatesFilterControls",
  appHeadManager = "savedAppHeadManagerFilterControls",
  appHeadManagerArchive = "savedAppHeadManagerArchiveFilterControls",
  revokes = "savedRevokesFilterControls",
};

export enum LOCAL_STORAGE_FILTER_ITEM_NAMES {
  app = "appFilter",
  appArchive = "appArchiveFilter",
  appManagerPartner = "appManagerPartnerFilter",
  appManagerPartnerArchive = "appManagerPartnerArchiveFilter",
  preapp = "preAppFilter",
  certificates = "certificatesFilter",
  appHeadManager = "appHeadManagerFilter",
  appHeadManagerArchive = "appHeadManagerArchiveFilter",
  revokes = "revokesFilter",
};

export enum FETCH_LIST_METHOD_NAMES {
  app = "applicationListState/getInitialApplication",
  appArchive = "applicationListState/getInitialApplication",
  appManagerPartner = "applicationListState/getInitialApplication",
  appManagerPartnerArchive = "applicationListState/getInitialApplication",
  preapp = "preapplicationList/getPreApplication",
  certificates = "certificateListState/getList",
  appHeadManager = "applicationListState/getInitialApplication",
  appHeadManagerArchive = "applicationListState/getInitialApplication",
  revokes = "revokeListState/getList",
};
