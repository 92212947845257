










































































import { Vue, Component, Prop, Watch, Model } from "vue-property-decorator";
import InputMask from "inputmask";
import moment from "moment";
import { genId } from "@/utils";
import InputControls from "./InputControls.vue";
import Button from "@/components/UI/Button.vue";

InputMask.extendDefinitions({
  $: {
    validator: "[0-9A-Za-z]",
    casing: "upper"
  }
});

@Component({ name: "Input", components: { InputControls, Button } })
export default class Input extends Vue {
  @Model("input") public readonly value!: Date | string | number;
  @Prop({ type: String }) public label!: string;
  @Prop({ type: String }) public id!: string;
  @Prop({ type: String }) public name!: string;
  @Prop({ type: String }) public placeholder!: string;
  @Prop({ type: String }) public mask!: string;
  @Prop({ type: String }) public info!: string;
  @Prop({ type: String, default: "text" }) public type!: string;
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
  @Prop({ type: Boolean }) public touched!: boolean;
  @Prop({ type: Boolean }) public shouldValidate!: boolean;
  @Prop({ type: Boolean }) public valid!: boolean;
  @Prop({ type: String, default: "normal" }) public stype!: "normal" | "min" | "max";
  @Prop({ type: Array, default: () => [] }) public errors!: [];
  @Prop({ type: String }) public invalidityInfo?: string;
  @Prop({ type: Object }) public inputControls?: Object;
  @Prop({ type: Boolean }) public fillByINNDisabled?: Boolean;
  @Prop({ type: Boolean }) public isFilledByINN?: Boolean;
  @Prop({ type: Boolean}) public isFilledByOGRN?: Boolean;

  private isPasswordView = false;
  private complete = false;

  private get inputType(): string {
    if (this.type === "password") {
      return this.isPasswordView ? "text" : "password";
    }
    return this.type === "date" ? "text" : this.type;
  }

  private get inputID(): string {
    return this.id || `${this.name || "input"}-${genId()}`;
  }

  private get optionsMask() {
    return Object.assign(
      { autoUnmask: true },
      this.mask && { mask: this.mask },
      this.type === "date" && {
        alias: "datetime",
        inputFormat: "dd.mm.yyyy",
        outputFormat: "dd.mm.yyyy",
        max: moment()
          .locale("ru")
          .format("L"),
        min: "01.01.1900",
        placeholder: "ДД.ММ.ГГГГ",
        clearMaskOnLostFocus: false,
        jitMasking: false
      }
    );
  }

  public mounted() {
    if (this.mask || this.type === "date") {
      new InputMask(this.optionsMask).mask(
        this.$refs.input as HTMLInputElement
      );
    }
  }

  public beforeUpdate() {
    if ((this.$refs.input as HTMLInputElement).inputmask) {
      (this.$refs.input as any).inputmask.remove();
    }
  }

  public updated() {
    if (this.mask || this.type === "date") {
      new InputMask(this.optionsMask).mask(
        this.$refs.input as HTMLInputElement
      );
    }
  }

  private isValidation = ({
    valid,
    touched,
    shouldValidate
  }: {
    valid: boolean;
    touched: boolean;
    shouldValidate: boolean;
  }) => {
    return !valid && touched && shouldValidate;
  };

  private inputHandler(evt: any) {
    this.$emit("input", evt.target.value);
    this.$emit("change", evt);
  }
}
