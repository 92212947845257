import {IDefaultFormControls, IDefaultUploadFormControls} from "./application.interfaces";
import { errorMessage, ANOTHER_UPLOADS_MAX_COUNT } from "./application.constants";
import { InitialFileTypes } from "@/constants/InitialFileTypes";

export const DEFAULT_FORM_CONTROLS: IDefaultFormControls = {
  typeEntre: {
    value: "",
    active: true,
    disabled: false
  },
  surname: {
    value: "",
    active: true,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  name: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  patronymic: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
  },
  gender: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
  },
  identificationKind: {
    value: 0,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  birthDate: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      date: true
    },
    invalidityInfo: "",
  },
  identificationDocumentTypeId: {
    value: 0,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  dateDoc: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      date: true
    },
    invalidityInfo: "",
  },
  docSeries: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: false,
    rules: {
      //empty: true,
      //minLength: 10
    },
    invalidityInfo: "",
  },
  serialDoc: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      //minLength: 10
      empty: true,
    },
    invalidityInfo: "",
  },
  passportDivision: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
    },
  },
  passportDivisionCode: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 6
    },
    invalidityInfo: "",
  },
  inn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      inn: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  personInn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      inn: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  snils: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      snils: true,
      minLength: 11
    },
    invalidityInfo: "",
  },
  ogrnip: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      ogrnip: true,
      minLength: 15
    },
    invalidityInfo: "",
  },
  position: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  department: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
  },
  ogrn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      ogrn: true,
      minLength: 13
    },
    invalidityInfo: "",
  },
  countryId: {
    value: 193,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  regionRealId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  cityReal: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  addressReal: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
  },
  regionLawId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  cityLaw: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  addressLaw: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  email: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      email: false
    },
    invalidityInfo: "",
  },
  phone: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  addovert: {
    value: 1,
    active: false,
    disabled: false
  },
  kpp: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 9
    },
    invalidityInfo: "",
  },
  index: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {
      minLength: 6
    },
    invalidityInfo: "",
  },
  // TO-DO: убрать ФИО организации
  surnameOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  nameOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  patronymicOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: false,
    rules: {},
  },
  ///
  phoneOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  fullNameOrg: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  shortNameOrg: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  // TO-DO: убрать должность директора
  positionDirector: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  isDirector: {
    value: false,
    active: true,
    disabled: false
  },
  managerComment: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
  },
  forInfoSys: {
    value: false,
    active: false,
    disabled: false  
  },
  basisOfActs: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  fnsCode: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {
      minLength: 4
    },
    invalidityInfo: "",
  },
  payerSubjectType: {
    value: "himself", //До #2610 было - "fl"
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  payerName: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  // payerShortName: {
  //   value: "",
  //   active: false,
  //   disabled: false,
  //   isTouched: false,
  //   isValid: false,
  //   isBlur: true,
  //   required: true,
  //   rules: {empty: true},
  //   invalidityInfo: "",
  // },
  payerInn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      inn: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  payerKpp: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 9
    },
    invalidityInfo: "",
  },
  payerPhone: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  payerCountryId: {
    value: 193,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  payerRegionId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  payerCity: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  payerAddress: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  }, 
  payerPostalCode: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {
      minLength: 6
    },
    invalidityInfo: "",
  },
  companyGroupName: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  companyGroupInn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: false,
    rules: {
      inn: true,
      minLength: 10,
    },
    invalidityInfo: "",
  },
  //////
  separatedAddressBuildingType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressBuildingValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressLocalityType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressLocalityValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressRoadType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressRoadValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressApartmentType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressApartmentValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  ///
  govContract: {
    value: false,
    active: false,
    disabled: false
  },
};

export const DEFAULT_UPLOAD_FORM_CONTROLS: IDefaultUploadFormControls = {
  application: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.Application,
    title: "Заявление",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    errors: [],
    accept: ".png, .jpg, .jpeg, .pdf, .sig, .sgn, .p7s",
    maxFiles: 1,
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""], // TODO: пустое это для .sig-файлов CMS, которые почему-то не получают mime-тип от браузера. Надо понять, почему
    },
    errorMessage,
  },
  applicationSignature: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.ApplicationSignature,
    title: "Подпись заявления",
    descr: "",
    isValid: false,
    isTouched: false,
    required: false,
    errors: [],
    accept: ".sig, .sgn, .p7s",
    maxFiles: 1,
    rules: {
      size: 15728640,
      type: ["application/pkcs7-signature", "application/sig", ""],
    },
    errorMessage,
  },
  passport: {
    // img: "/img/doc-passport.svg",
    type: "doc-passport",
    fileType: InitialFileTypes.Passport,
    title: "Паспорт",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
  },
  // убрали СНИЛС в рамках задачи #465, пусть пока полежит закомментированный, вылежится
  // snils: {
  //   // img: "/img/doc-snils.svg",
  //   type: "doc-snils",
  //   fileType: 13,
  //   title: "СНИЛС",
  //   descr: "",
  //   isValid: false,
  //   isTouched: false,
  //   isCollected: false,
  //   required: false,
  //   accept: ".png, .jpg, .jpeg, .pdf, .sig",
  //   errors: [],
  //   rules: {
  //     size: 15728640,
  //     type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
  //   },
  //   errorMessage,
  // },
  foto: {
    // img: "/img/doc-foto.svg",
    type: "doc-foto",
    fileType: InitialFileTypes.Photo,
    fromCamera: true,
    title: "Фото заявителя",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
  },
  contract: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.ClientAgreement,
    title: "Клиентский договор",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
  },
  // TO-DO: секцию proxy нужно будет убрать
  proxy: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.Procuration,
    title: "Доверенность",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: false,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
  },
  ///
  extra: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.ExtraDocs,
    isExtra: true,
    title: "Дополнительные документы",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: false,
    accept: ".png, .jpg, .jpeg, .pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", "application/sig", ""],
    },
    errorMessage,
  },
  revoke: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.Revocation,
    title: "Отзыв",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf, .sig, .zip, .rar",
    errors: [],
    rules: {
      size: 15728640,
      type: [
        "application/pdf", "image/png", "image/jpeg", "application/sig",
        "application/zip", "application/rar", "application/x-zip-compressed", "application/x-rar-compressed", // если много отзывов, то в архиве
        "application/octet-stream", "" // если после подписания в ЛККЛ, то приходит как base64
      ],
    },
    errorMessage,
  },
  revokeDetachedSign: {
    // img: "/img/doc.svg",
    type: "doc",
    fileType: InitialFileTypes.RevocationDetachedSign,
    title: "Подпись отзыва",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/sig", ""],
    },
    errorMessage,
  },
  certificateSigned: {
    type: "doc",
    fileType: InitialFileTypes.ApplicantSignedPrintingForm,
    title: "Скан печатной формы",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", ""],
    },
    errorMessage,
  },
  printingFormSignature: {
    type: "doc",
    fileType: InitialFileTypes.PrintingFormSignature,
    title: "Подпись печатной формы",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: true,
    accept: ".pdf, .sig",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "application/sig", ""],
    },
    errorMessage,  
  },
  securityInformationSigned: {
    type: "doc",
    fileType: InitialFileTypes.ApplicantSignedSecurityInstructionForm,
    title: "Руководство по безопасности",
    descr: "",
    isValid: false,
    isTouched: false,
    isCollected: false,
    required: false,
    accept: ".png, .jpg, .jpeg, .pdf",
    errors: [],
    rules: {
      size: 15728640,
      type: ["application/pdf", "image/png", "image/jpeg", ""],
    },
    errorMessage,
  },
};

export const DEFAULT_ANOTHER_UPLOAD = {
  type: "doc",
  title: "Другой документ",
  descr: "",
  isValid: false,
  isTouched: false,
  required: false,
  accept: ".png, .jpg, .jpeg, .pdf, .sig, .html, .zip, .p7s, .pdf.sig",
  errors: [],
  rules: {
    size: 15728640,
    // type: ["application/pdf", "image/png", "image/jpeg", ""],
  },
  errorMessage,
};

export const DEFAULT_ANOTHER_UPLOADS = new Array(ANOTHER_UPLOADS_MAX_COUNT)
  .fill("")
  .map((_,i) => `another${i}`)
  .reduce((a, v) => ({...a, [v]: {...DEFAULT_ANOTHER_UPLOAD}}), {});