import Vue from "vue";
import {ActionTree} from "vuex";

import { TOAST_OPTIONS } from "@/constants";

import { RootState } from "../../root.interface";
import { getFormattedDate } from "@/helpers";

import { ISmsState } from "./sms.types";
import {
  SET_SMS_INFO,
  SET_SMS_HISTORY,
  SET_SMS_ALLOWED_TO_SEND,
} from "./sms.mutations";
import { SMS_TYPE_OPTION_IDS } from './sms.config';
import api from './sms.api';

export const actions: ActionTree<ISmsState, RootState> = {
  async getPreappSmsList({commit}: any, orderId: string | number) {
      const { data, error } = await api.getPreappSmsList(orderId);

      if (error || !data) {
        Vue.$toast.error(
          `Ошибка получения списка СМС: ${error || 'нет данных'}`,
          TOAST_OPTIONS.Error,
        );
        return;
      }

      if (data.items?.length) {
        const smsHistory = data.items.map(({sendTime, text}) => ({
          date: getFormattedDate(
            new Date(new Date(sendTime).getTime() -
            new Date(sendTime).getTimezoneOffset() * 60 * 1000),
            'LLL',
          ),
          text
        }));
        const smsAllowedToSend = data.items[data.items.length - 1].allowSend;
        commit(SET_SMS_HISTORY, smsHistory);
        commit(SET_SMS_ALLOWED_TO_SEND, smsAllowedToSend);
      }

      const smsInfo = {
        filialPhone: `${data.phone.phoneNumber}${data.phone.additionalNumber ? ' доб. ' + data.phone.additionalNumber : ''}`,
        filialAddress: data.address,
        filialName: data.orgName.replace(/\//g, ''),
      };
      commit(SET_SMS_INFO, smsInfo);
  },

  async sendPreappSms({state, commit}, {
    id,
    smsType,
    text,
  }: {
    id: string | number;
    smsType: SMS_TYPE_OPTION_IDS;
    text: string;
  }) {
    const { error } = await api.sendPreappSms({id, smsType, text});

    if (error) {
      Vue.$toast.error(
        `Не удалось послать смс заявителю: ${error}`,
        TOAST_OPTIONS.Error,
      );
      return false; //not success
    }

    const newHistoryItem = {
      date: getFormattedDate(new Date(), "LLL"),
      text,
    };
    commit(SET_SMS_HISTORY, state.smsHistory.concat([newHistoryItem]));
    commit(SET_SMS_ALLOWED_TO_SEND, false);
    Vue.$toast.success(`СМС заявителю отправлена!`, TOAST_OPTIONS.Success);
    return true; //success
  },
};
