export const SET_FORM_CONTROL = "SET_FORM_CONTROL";
export const SET_IS_FORM_VALID = "SET_IS_FORM_VALID";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SAVE_USER = "SET_SAVE_USER";
export const CLEAR_FORM_CONTROLS = "CLEAR_FORM_CONTROLS";
export const SET_CERT_REQUEST_FILE = "SET_CERT_REQUEST_FILE";
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_IS_UPLOAD_FORM_LOADED = "isUploadFormLoaded";
export const SET_IS_SUBMIT_ACTIVE = "SET_IS_SUBMIT_ACTIVE";
export const SET_FILTER_CONTROL = "SET_FILTER_CONTROL";
export const SET_SAVE_FILTER_CONTROL = "SET_SAVE_FILTER_CONTROL";
export const SET_IS_OPEN_FILTER = "SET_IS_OPEN_FILTER";
export const SET_ARCHIVE = "SET_ARCHIVE";

export const SET_PROFILE = "SET_PROFILE";
export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_PARTNERMANAGER_ID = "SET_PARTNERMANAGER_ID";
export const SET_FILIAL_ID = "SET_FILIAL_ID";

export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const SET_SIZE_PAGES = "SET_SIZE_PAGES";
export const SET_APPLICATIONS_LIST = "SET_APPLICATIONS_LIST";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const CLEAR_APPLICATION_LIST = "CLEAR_APPLICATION_LIST";

export const TOGGLE_RECOVERY_PASSWORD = "TOGGLE_RECOVERY_PASSWORD";
export const SET_IS_SAME_PASSWORD = "SET_IS_SAME_PASSWORD";
export const SET_IS_CHANGE_PASSWORD_FORM_VALID =
  "SET_IS_CHANGE_PASSWORD_FORM_VALID";
export const SET_ERROR = "SET_ERROR";

export const SET_CHANGE_STATUS_VISIBILITY = "SET_CHANGE_STATUS_VISIBILITY";
export const SET_PREAPP_ADDITIONAL_STATUSES = "SET_PREAPP_ADDITIONAL_STATUSES";
export const SET_PREAPP_FILIAL_COMMENT = "SET_PREAPP_FILIAL_COMMENT";
export const SET_PREAPP_SCANS = "SET_PREAPP_SCANS"
export const SET_PREAPP_PAYMENT = "SET_PREAPP_PAYMENT";
