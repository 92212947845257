import { Module } from "vuex";

import { RootState } from "@/store/root.interface";

import { actions } from "./revoke-list.actions";
import { getters } from "./revoke-list.getters";
import { mutations } from "./revoke-list.mutations";
import { IRevokeListState } from "./revoke-list.types";

export const revokeListState: Module<IRevokeListState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    totalPages: null,
    sizePage: 10,
    activePage: 1,
    isLoaded: false,
    isRevokesAllowed: null,
  },
  mutations,
  actions,
  getters
};
