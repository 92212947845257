import { fetch, setAccessToken } from "@/services/api";
import { BASE_URL_AUTH } from "@/constants";
import router from "@/router";
import cookie from "js-cookie";

import Notifications from "@/lib/notifications";

import {
  SET_PROFILE,
  SET_FORM_CONTROL,
  SET_IS_SAME_PASSWORD,
  SET_IS_CHANGE_PASSWORD_FORM_VALID,
  SET_ERROR
} from "@/constants/mutations-type";

import { validation, isFormValid } from "@/services";

const notify = new Notifications({
  type: "success",
  duration: 6000,
  position: ["left", "top"],
  animationOptions: {
    animateIn: "fadeInDown",
    animateOut: "fadeOutUp"
  }
});

const defaultProfile = {
  id: 0,
  name: "",
  middleName: "",
  surname: "",
  position: "",
  department: "",
  phoneNumber: "",
  orgINN: "",
  orgOGRN: "",
  orgName: "",
  orgShortName: "",
  orgHeadSurname: "",
  orgHeadName: "",
  orgHeadMiddleName: "",
  orgHeadPosition: "",
  orgLegalAddress: "",
  orgKPP: "",
  orgPhoneNumber: "",
  orgPostIndex: ""
};

const defaultFormControls = {
  oldPassword: {
    value: "",
    label: "Старый пароль",
    type: "password",
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  },
  newPassword: {
    value: "",
    label: "Новый пароль",
    type: "password",
    info:
      "Пароль должен содержать не менее 8 символов. Допускаются только буквы латинского алфавита (a-z). Обязательное наличие не менее одной прописной буквы и цифры.",
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      numeric: true,
      alpha: true,
      uppercase: true,
      minLength: 8
    }
  },
  passwordСonfirm: {
    value: "",
    label: "Подтвердите пароль",
    type: "password",
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true
    }
  }
};

const profile = {
  namespaced: true,

  state: {
    profile: { ...defaultProfile },
    error: null,
    isSamePassword: false,
    changePasswordForm: { ...defaultFormControls },
    isChangePasswordFormValid: false
  },
  mutations: {
    [SET_PROFILE](state: any, profileProps: any) {
      state.profile = { ...profileProps };
    },
    [SET_ERROR](state: any, err: string | null) {
      state.error = err;
    },
    [SET_IS_SAME_PASSWORD](state: any, val: boolean) {
      state.isSamePassword = val;
    },
    [SET_IS_CHANGE_PASSWORD_FORM_VALID](state: any, val: boolean) {
      state.isChangePasswordFormValid = val;
    },
    [SET_FORM_CONTROL](state: any, { control, name }: any) {
      state.changePasswordForm[name] = { ...control };
    }
  },
  actions: {
    clearProfile({ commit, dispatch }: any) {
      commit(SET_PROFILE, defaultProfile);
      dispatch("clearChangePasswordForm");
    },

    onChangeHandler({ commit, state }: any, evt: any) {
      const control = { ...state.changePasswordForm[evt.target.name] };
      const { value, name } = evt.target;

      control.value = value;
      control.isTouched = true;

      const {isValid} = validation(control.value, control.rules);
      control.isValid = isValid;
      // control.errors = errors;

      if (name === "passwordСonfirm") {
        control.isValid =
          state.changePasswordForm.newPassword.value === control.value;

        commit(SET_IS_SAME_PASSWORD, control.isValid);
      }

      commit(SET_FORM_CONTROL, { control, name });
      commit(
        SET_IS_CHANGE_PASSWORD_FORM_VALID,
        isFormValid(state.changePasswordForm)
      );
    },

    async passwordChangeSubmit({ commit, state, dispatch }: any) {
      commit(
        SET_IS_CHANGE_PASSWORD_FORM_VALID,
        isFormValid(state.changePasswordForm)
      );

      if (state.isChangePasswordFormValid) {
        try {
          const res = await fetch.post(`${BASE_URL_AUTH}api/auth/change`, {
            OldPassword: state.changePasswordForm.oldPassword.value,
            NewPassword: state.changePasswordForm.newPassword.value
          });

          if (res.status === 200) {
            const { error, result } = res.data;

            if (error) {
              commit(SET_ERROR, error);
            }

            if (result) {
              setAccessToken(null);
              cookie.remove("_rt");
              router.push("/login");
              dispatch("clearChangePasswordForm");
              notify.show({ title: "Пароль успешно изменен" });
            }
          }
        } catch (err) {
          throw err;
        }
      }
    },

    clearChangePasswordForm({ commit, state }: any) {
      commit(SET_ERROR, null);
      commit(SET_IS_SAME_PASSWORD, false);
      commit(SET_IS_CHANGE_PASSWORD_FORM_VALID, false);

      Object.keys(state.changePasswordForm).forEach((name: string) => {
        commit(SET_FORM_CONTROL, {
          control: { ...(defaultFormControls as any)[name] },
          name
        });
      });
    },

    async getProfile({ commit }: any) {
      try {
        const res = await fetch.post(`api/manager/GetProfile`);

        if (res.status === 200) {
          commit(SET_PROFILE, res.data.profile);
        }
      } catch (err) {
        throw err;
      }
    }
  }
};

export default profile;
