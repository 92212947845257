import { MutationTree } from "vuex";

import { ICertificateListState, IListItem } from './certificate-list.types';
import { CERTIFICATE_STATUS_IDS } from "@/constants/certificate.constants";

export const SET_CERTIFICATE_LIST = 'SET_CERTIFICATE_LIST';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_SIZE_PAGES = 'SET_SIZE_PAGES';
export const SET_IS_LOADED = 'SET_IS_LOADED';
export const CLEAR_CERTIFICATE_LIST_STATE = 'CLEAR_CERTIFICATE_LIST_STATE';
export const SET_CERTIFICATE_STATUS_IN_LIST = 'SET_CERTIFICATE_STATUS_IN_LIST';

export const mutations: MutationTree<ICertificateListState> = {
  [SET_CERTIFICATE_LIST](state, value: IListItem[]) {
    state.list = JSON.parse(JSON.stringify(value));
  },
  [SET_TOTAL_PAGES](state, value: number) {
    state.totalPages = value;
  },
  [SET_ACTIVE_PAGE](state, value: number) {
    state.activePage = value;
  },
  [SET_SIZE_PAGES](state, value: number) {
    state.sizePage = value;
  },
  [SET_IS_LOADED](state, value: boolean) {
    state.isLoaded = value;
  },
  [CLEAR_CERTIFICATE_LIST_STATE](state) {
    state.list = [];
    state.sizePage = 10;
    state.activePage = 1;
    state.totalPages = null;
    state.isLoaded = false;
  },
  [SET_CERTIFICATE_STATUS_IN_LIST](state, {id, statusId, status}: {id: number; statusId: CERTIFICATE_STATUS_IDS; status: string;}) {
    const index = state.list.findIndex(({applicationId}) => applicationId === id);
    const item = {...state.list[index], statusId, status};
    state.list.splice(index, 1, item);
  }
};
