import {MutationTree} from "vuex";

import {ChangePasswordState} from "@/store/modules/change-password/interfaces/change-password.state";
import {
    CLEAR_FORM_CONTROLS,
    SET_ERROR_MESSAGE,
    SET_FORM_CONTROL,
    SET_IS_FORM_VALID, SET_IS_SUBMIT_ACTIVE
} from "@/store/modules/change-password/constants/mutations-type";

export const mutations: MutationTree<ChangePasswordState> = {
    [SET_FORM_CONTROL](state, {control, name}: { control: any; name: string }) {
        state.formControls[name] = {...control};
    },
    [SET_IS_FORM_VALID](state, val: boolean) {
        state.isFormValid = val;
    },
    [SET_ERROR_MESSAGE](state, err: null | string) {
        state.errorMessage = err;
    },
    [SET_IS_SUBMIT_ACTIVE](state, val: boolean) {
        state.isSubmitActive = val;
    },
    [CLEAR_FORM_CONTROLS](state) {
        state.isFormValid = true;
        state.isSubmitActive = false;
        state.errorMessage = null;
    },
};
