export enum OrganizationTypeIds {
    Agent = 1,
    Partner = 2,
    Tenzor = 3,
    Contour = 4,
    ITCom = 5,
    Kaluga = 6,
    Takscom = 7,
    ElectronicExpress = 8,
    Corporate = 9,
    Unkwnown = 10,
}