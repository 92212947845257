import { GetterTree } from 'vuex';

import { RootState } from '@/store/root.interface';

import { crypto } from './crypto.state';
import { ICryptoState } from "./crypto.types";

export const getters: GetterTree<ICryptoState, RootState> = {
  isBrowserPluginEnabled() {
    return crypto.browserPluginEnabled;
  },
};
