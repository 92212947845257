import Vue from 'vue';
import { ActionTree } from 'vuex';
import { template } from "lodash";

import {app} from "@/main";
import router from "@/router";
import { RootState } from '@/store/root.interface';
import { TOAST_OPTIONS } from '@/constants';
import { DOCUMENT_TYPE_IDS } from '@/constants/revoke.constants';
import { base64ToFile, fileToBase64 } from '@/helpers';
import { extname } from "@/utils";
import { validationFile } from "@/services";

import api from './revoke.api';
import { IClientDocument, IRevokeState, TFieldName } from './revoke.types';
import {
  SET_REVOKE_GUID,
  CLEAR_REVOKE_STATE,
  SET_REVOKE_ORDER_ID,
  SET_REVOKE_STATUS_ID,
  SET_REVOKE_DATE_CREATE,
  SET_REVOKE_DATE_UPDATE,
  SET_REVOKE_DATE_REVOKE,
  SET_REVOKE_NOT_ALLOWED,
  SET_REVOKE_APPLICANT_TYPE,
  SET_REVOKE_FIELD_VALUE,
  SET_REVOKE_ACTIVE_TAB,
  SET_REVOKE_CLIENT_DOCUMENT,
  SET_REVOKE_IS_APPLICATION_GENERATED,
  FILTER_CLIENT_DOCUMENTS_BY_APPLICANT,
} from './revoke.mutations';

export const actions: ActionTree<IRevokeState, RootState> = {
  async getRevokeApplication({state, commit, getters}) {
    if (state.orderId === null) return;

    const { data, error, status } = await api.getApplication({id: state.orderId});

    if (error || !data) {
      Vue.$toast.error(
        `Ошибка при получении данных заявки на отзыв ${state.orderId}: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      status && status === 404 && router.push('/404');
      return;
    }

    commit(SET_REVOKE_GUID, data.id);
    commit(SET_REVOKE_STATUS_ID, data.status);
    commit(SET_REVOKE_APPLICANT_TYPE, data.certSubjectType);
    commit(SET_REVOKE_DATE_CREATE, data.dateCreate);
    commit(SET_REVOKE_DATE_UPDATE, data.dateUpdate);
    commit(SET_REVOKE_DATE_REVOKE, data.dateRevoke);
    commit(SET_REVOKE_IS_APPLICATION_GENERATED, data.files && data.files[DOCUMENT_TYPE_IDS.RevokeApplication] !== null);
    commit(SET_REVOKE_NOT_ALLOWED, data.revokeNotAllowed ?? true);

    Object.keys(state.fields).forEach(fieldName => {
      if (data.hasOwnProperty(fieldName)) {
        commit(SET_REVOKE_FIELD_VALUE, {fieldName, value: data[fieldName as TFieldName]});
      }
    });

    commit(FILTER_CLIENT_DOCUMENTS_BY_APPLICANT);

    Object.entries(data.files).forEach(([fileType, fileExt]) => {
      if (
        [DOCUMENT_TYPE_IDS.RevokeApplicationScan, DOCUMENT_TYPE_IDS.RevokeProcuration]
          .includes(Number(fileType as unknown as DOCUMENT_TYPE_IDS))
      ) {
        const document: IClientDocument = Object.assign({}, state.clientDocuments[fileType as unknown as DOCUMENT_TYPE_IDS]);
        const isDocumentExist = fileExt !== null;
        document.isCollected = isDocumentExist;
        document.isValid = isDocumentExist;
        document.isWatchable = isDocumentExist;
        commit(SET_REVOKE_CLIENT_DOCUMENT, {fileType, document});
      }
    });
  },

  async getDocument({state}, {documentType}: {documentType: DOCUMENT_TYPE_IDS}) {
    const { data, error } = await api.downloadDocument({guid: state.guid!, documentType});

    if (error || !data) {
      Vue.$toast.error(
        `Ошибка при получении данных заявки на отзыв ${state.orderId}: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    return {
      fileName: data.fileName,
      contentType: data.contentType,
      base64: data.base64
    };
  },

  async inputFileHandler({commit, state, dispatch}, {fileType, files}: {fileType: DOCUMENT_TYPE_IDS, files: any[]}) {
    const document = Object.assign({}, state.clientDocuments[fileType]);

    document.isTouched = true;

    if (files.length) {
      const errors: string[] = [];
      const namesFiles = [];
      const isMaxFiles = files.length > (document.maxFiles ? document.maxFiles : 1);

      const compiled = template(
        `<ul>
          <% _.forEach( names, function(name) { %>
            <li> <%- name %> </li>
          <% }) %>
        </ul>`
      );

      if (!isMaxFiles) {
        const filesArr = isMaxFiles ? document.maxFiles : files.length;
        document.files = [];
        document.errors = [];
        
        for (let i = 0; i < filesArr; i++) {
          const file = files[i];

          const errorsMessage = validationFile(file, {
            rules: document.rules,
            errorMessage: document.errorMessage,
          });

          if (errorsMessage.length) {
            errorsMessage.forEach((error) => {
              if (!~errors.indexOf(error)) {
                  errors.push(error);
              }
            });
          } else {
            namesFiles.push(file.name.replace(extname(file.name), ""));
            document.files.push(file);
          }
        }

        document.descr = compiled({names: namesFiles});
        document.errors = [...errors];

      } else {
        document.files = [];
        document.descr = "";
        document.errors = [`Максимальное количество файлов ${document.maxFiles}`];
        }
    }
    if (document.files) {
      document.isValid = !document.errors.length;
      document.isWatchable = true;
    } else {
      document.isValid = false;
      document.isWatchable = false;
    }

    commit(SET_REVOKE_CLIENT_DOCUMENT, {fileType, document});
  },

  async collectDocuments({state}) {
    const promises = Object.entries(state.clientDocuments).map(async ([documentType, document]) => {
      if (document!.files.length) {
        const base64 = await fileToBase64(document!.files[0]);
        return new Promise(async (resolve) => resolve(await api.attachDocument({
          guid: state.guid!,
          documentType: documentType as unknown as DOCUMENT_TYPE_IDS,
          base64: (base64 as string).replace(/^.+base64,/, ''),
        })));
      }
      return null;
    });

    const errors: string[] = [];
    const result = await Promise.all(promises);
    result.forEach((promiseResult: any) => {
      if (promiseResult && promiseResult.error) {
        errors.push(promiseResult.error);
      }
    });

    if (!errors.length) {
      Vue.$toast.success(`Файлы успешно сохранены`, TOAST_OPTIONS.Error);
      return;
    }

    errors.forEach(error => Vue.$toast.error(
      `Ошибка при сохранении файла документа: ${error}`,
      TOAST_OPTIONS.Error
    ));
  },

  async signAndUploadApplication({state, dispatch}) {
    let applicationScan;

    if (state.clientDocuments[DOCUMENT_TYPE_IDS.RevokeApplicationScan]!.files[0]) {
      applicationScan = await fileToBase64(state.clientDocuments[DOCUMENT_TYPE_IDS.RevokeApplicationScan]!.files[0]);
      await dispatch('collectDocuments');
    } else if (state.clientDocuments[DOCUMENT_TYPE_IDS.RevokeApplicationScan]!.isCollected) {
      const { base64 } = await dispatch('getDocument', {documentType: DOCUMENT_TYPE_IDS.RevokeApplicationScan});
      applicationScan = base64;
    }

    const signedApplicationScan = await dispatch(
      'cryptoState/getSignedDocument',
      {document: applicationScan, detached: true},
      {root: true}
    );

    const { error } = await api.attachSignedApplication({
      guid: state.guid!,
      base64: signedApplicationScan,
    });

    if (error) {
      Vue.$toast.error(
        `Ошибка при загрузке подписанного скана заявления на отзыв: ${error}`,
        TOAST_OPTIONS.Error
      );
    }

    (app as any).$modal.hide('revoke-sign-modal');
    window.location.reload(); 
  },

  async getVerifyingCode({state, commit}, {phone}: {phone?: string;}): Promise<{success: boolean}> {
    const {error} = await api.getVerifyingCode({guid: state.guid!, phone});

    if (error) {
      Vue.$toast.error(
        `Ошибка при получении проверочного кода: ${error || 'данные отсутствуют'}`,
        TOAST_OPTIONS.Error
      );
      return {success: false};
    }

    return {success: true};
  },

  async skipVerifyingCode({state, commit}): Promise<{success: boolean}> {
    const {error} = await api.skipVerifyingCode({guid: state.guid!});

    if (error) {
      Vue.$toast.error(
        `Ошибка при получении проверочного кода: ${error}`,
        TOAST_OPTIONS.Error
      );
      return {success: false};
    }

    return {success: true};
  },

  async checkVerifyingCode({state, commit, getters}, code: string): Promise<{success: boolean; isValid?: boolean;}> {
    const {data, error} = await api.checkVerifyingCode({guid: state.guid!, phone: getters.getPhone, code});

    if (error || !data) {
      Vue.$toast.error(
        `Ошибка при получении проверочного кода: ${error || 'нет данных'}`,
        TOAST_OPTIONS.Error
      );
      return {success: false};
    }

    return {success: true, isValid: data.isValid};
  },

  setOrderId({commit}, orderId: number) {
    commit(SET_REVOKE_ORDER_ID, orderId);
  },

  setActiveTab({commit, state}, activeTab: number) {
    if (activeTab !== state.activeTab) {
        commit(SET_REVOKE_ACTIVE_TAB, activeTab);
    }
  },

  clearState({commit}) {
    commit(CLEAR_REVOKE_STATE);
  },
};
