import { MutationTree } from "vuex";
import { CommentsState, Comment } from "./interfaces/comments.state";

import {
  SET_COMMENTS,
  SET_LAST_COMMENT,
  SET_COMMENTS_LOADED,
} from "./constants/mutations-type";

export const mutations: MutationTree<CommentsState> = {
  [SET_COMMENTS](state, payload: Comment[]) {
    state.comments = JSON.parse(JSON.stringify(payload));
  },
  [SET_LAST_COMMENT](state, payload: Comment) {
    state.lastComment = {...payload};
  },
  [SET_COMMENTS_LOADED](state, payload: boolean) {
    state.isLoaded = payload;
  },
};
