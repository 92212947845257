import { Module } from "vuex";

import { actions } from "./filter.actions";
import { mutations } from "./filter.mutations";
import { getters } from "./filter.getters";
import { IFilterState } from "./filter.interfaces";
import { FILTER_DEFAULTS } from "./defaults";
import { RootState } from "@/store/root.interface";
import { IDENTIFICATION_KINDS, PAYMENT_STATUSES } from "@/constants";
import { PAGE_NAMES, CREAT_PRESET_OPTION } from "./filter.constants";

export const filterState: Module<IFilterState, RootState> = {
  namespaced: true,
  state: {
    pageName: PAGE_NAMES["app"],
    isOpenFilter: false,
    appSearch: "",
    preappSearch: "",
    certificatesSearch: "",
    revokesSearch: "",
    appFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.app)),
    appArchiveFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appArchive)),
    appManagerPartnerFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appManagerPartner)),
    appManagerPartnerArchiveFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appManagerPartnerArchive)),
    appHeadManagerFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appHeadManager)),
    appHeadManagerArchiveFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appHeadManagerArchive)),
    preappFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.preapp)),
    certificatesFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.certificates)),
    revokesFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.revokes)),
    savedAppFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.app)),
    savedAppArchiveFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appArchive)),
    savedAppManagerPartnerFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appManagerPartner)),
    savedAppManagerPartnerArchiveFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appManagerPartnerArchive)),
    savedAppHeadManagerFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appHeadManager)),
    savedAppHeadManagerArchiveFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.appHeadManagerArchive)),
    savedPreappFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.preapp)),
    savedCertificatesFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.certificates)),
    savedRevokesFilterControls: JSON.parse(JSON.stringify(FILTER_DEFAULTS.revokes)),
    filterOptions: {
      managers: [],
      organizations: [],
      identificationKind: Object.values(IDENTIFICATION_KINDS),
      paymentStatus: Object.values(PAYMENT_STATUSES),
      productId: [],
      companyGroups: [],
    },
    currentPreset: null,
    savedCurrentPreset: null,
    presetOptions: [CREAT_PRESET_OPTION],
  },
  mutations,
  actions,
  getters
};
