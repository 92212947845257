import { DOCUMENT_TYPE_IDS, CERT_SUBJECT_TYPE_IDS } from '@/constants/revoke.constants';
import { MIME_TYPES, MAX_FILE_SIZE } from '@/config/files';
import { IClientDocument } from '@/store/modules/revoke/revoke.types';

export const REVOKE_CLIENT_DOCUMENTS: {[key in typeof DOCUMENT_TYPE_IDS[keyof typeof DOCUMENT_TYPE_IDS]]?: IClientDocument}= {
  [DOCUMENT_TYPE_IDS.RevokeApplicationScan]: {
    type: 'doc',
    fileType: DOCUMENT_TYPE_IDS.RevokeApplicationScan,
    title: 'Скан заявления на отзыв',
    descr: '',
    isValid: false,
    isTouched: false,
    isCollected: false,
    isWatchable: false,
    required: true,
    errors: [],
    accept: '.png, .jpg, .jpeg, .pdf, .tiff',
    maxFiles: 1,
    rules: {
      size: MAX_FILE_SIZE,
      type: [MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.jpeg],
    },
    errorMessage: '',
    files: [],
    allowedApplicants: [
      CERT_SUBJECT_TYPE_IDS.Person,
      CERT_SUBJECT_TYPE_IDS.Entrepreneur,
      CERT_SUBJECT_TYPE_IDS.Legal,
      CERT_SUBJECT_TYPE_IDS.Employee,
    ],
  },
  [DOCUMENT_TYPE_IDS.RevokeProcuration]: {
    type: 'doc',
    fileType: DOCUMENT_TYPE_IDS.RevokeProcuration,
    title: 'Скан доверенности',
    descr: '',
    isValid: false,
    isTouched: false,
    isCollected: false,
    isWatchable: false,
    required: true,
    errors: [],
    accept: '.png, .jpg, .jpeg, .pdf, .tiff',
    maxFiles: 1,
    rules: {
      size: MAX_FILE_SIZE,
      type: [MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.jpeg],
    },
    errorMessage: '',
    files: [],
    allowedApplicants: [
      // CERT_SUBJECT_TYPE_IDS.Employee, // пока доверенность никому не нужна
    ],
  },
};
