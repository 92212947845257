export const APP_PAYMENT_STAUSES = {
  NotPaid: {
    value: 0,
    label: "не оплачено"
  },
  PaidInPart: {
    value: 1,
    label: "оплачено частично"
  },
  PaidFully: {
    value: 2,
    label: "оплачено"
  }
};
