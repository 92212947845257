import {Module} from "vuex";

import {actions} from "./change-password.actions";
import {mutations} from "./change-password.mutations";
import {getters} from "./change-password.getters";
import {RootState} from "@/store/root.interface";

import {defaultFormControls} from "./change-password.default";
import {ChangePasswordState} from "@/store/modules/change-password/interfaces/change-password.state";

const namespaced: boolean = true;

export const changePassword: Module<ChangePasswordState, RootState> = {
    namespaced,
    state: {
        isFormValid: true,
        isSubmitActive: false,
        errorMessage: null,
        formControls: {...defaultFormControls}
    },
    mutations,
    actions,
    getters
};
