import { GetterTree } from "vuex";
import { isEqual, isEmpty } from "lodash";

import { getFormattedDate } from "@/helpers";

import { IFilterControls, IFilterState } from "./filter.interfaces";
import { FILTER_DEFAULTS } from "./defaults";
import {
  PAGE_NAMES,
  MAX_FILTER_PRESETS_ON_PAGE,
  FILTER_CONTROLS_NAMES,
  SAVED_FILTER_CONTROLS_NAMES,
  REVOKE_ISSUER_TYPE_OPTIONS,
} from "./filter.constants";
import { RootState } from "@/store/root.interface";

export const getters: GetterTree<IFilterState, RootState> = {
  getFilterControls(state) {
    return (pageName: PAGE_NAMES) => {
      const controlsName = FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
      return state[controlsName];
    }
  },

  getFilterString(state, getters) {
    function filterParams(savedControls: IFilterControls, prefix: string) {
      const result: string[] = [];
      Object.values(savedControls).forEach(group => {
        Object.keys(group.controls).forEach(controlName => {
          let val: any = group.controls[controlName].value;
          if (
            (val !== null && val !== undefined) &&
            (typeof val === "string" && !isEmpty(val)) ||
            (typeof val === "boolean" && val) ||
            (typeof val === "number" && val !== -1) ||
            (val instanceof Date) ||
            (Array.isArray(val) && val.length)
          ) {
            if (controlName.startsWith("date")) {
              val = getFormattedDate(val as Date, "L");
            }
            if (Array.isArray(val) && val.length) {
              val = val.join(".")
            }
            result.push(`${controlName}=${val}`);
          }
        })
      });
      return result.length ? prefix + result.join("&") : "";
    }

    return (pageName: PAGE_NAMES) => {
      const prefix = getters.getSearchValue(pageName) ? "&" : "?";
      const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
      const savedControls = state[savedControlsName];
      return filterParams(savedControls, prefix);
    }   
  },

  getSearchValue(state) {
    return (pageName: PAGE_NAMES) => {
      if (pageName.startsWith("app")) {
        return state.appSearch;
      }
      if (pageName.startsWith("preapp")) {
        return state.preappSearch;
      }
      if (pageName.startsWith("certificates")) {
        return state.certificatesSearch;
      }
      if (pageName.startsWith("revokes")) {
        return state.revokesSearch;
      }
    }
  },

  getSearchString(state, getters) {
    return (pageName: PAGE_NAMES) => {
      return getters.getSearchValue(pageName) && `?search=${getters.getSearchValue(pageName)}`;
    }
  },

  isActiveFilter(state) {
    return (pageName: PAGE_NAMES) => {
      const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
      const savedControls = state[savedControlsName];
      const defaultControls = FILTER_DEFAULTS[PAGE_NAMES[pageName]];
      return !isEqual(savedControls, defaultControls);
    }
  },

  getFilterInfoString(state, getters) {
    const applicantNames: {[key: string]: string} = {
      fl: "Физическое лицо",
      ip: "Индивидуальный предприниматель",
      ur: "Юридическое лицо"
    };
    const revokeApplicantNames: {[key: string]: string} = {
      subjectPerson: "Физическое лицо",
      subjectEntrepreneur: "Индивидуальный предприниматель",
      subjectLegal: "Юридическое лицо",
      subjectEmployee: "Сотрудник ЮЛ",
    };
    const statusNames: {[key: string]: string} = {
      statusSendDoc: "Отправка документов",
      statusGenReq: "Генерация запроса",
      statusOnModeration: "На модерации",
      statusRelease: "Выпуск",
      statusDone: "Готово",
      statusFail: "Отказ",
      statusPayed: "Оплачено",
      statusNew: "Новая",
      statusAppFormed: "Заявка сформирована",
      statusInWork: "В работе",
      statusWaitForConfirm: "Ожидание подтверждения",
      statusWaitForDocs: "Ожидание документов",
      statusClientRefused: "Клиент отказался",
      statusOutOfReach: "Не выходит на связь",
      statusOtherReason: "Прочее",
      statusIdentification: "Идентификация",
      statusWaitingOriginals: "Ожидание оригиналов",
      statusOriginalsIncorrect: "Оригиналы некорректны",
      statusClosed: "Завершено",
      statusDuplicate: "Повтор",
      statusInProcess: "В работе",
      statusDocumentsReady: "Подтверждена, ожидает обработки",
      statusSuccess: "Отозван",
    };
    const identificationNames: {[key: string]: string} = {
      statusIdentificationCreated: "Новая идентификация",
      statusIdentificationConfirmed: "Идентификация подтверждена",
      statusIdentificationCanceled: "Идентификация отклонена",
    };
    const dateCreateNames: {[key: string]: string} = {
      dateCreateFrom: "с",
      dateCreateTo: "по",
    };
    const dateIssueNames: {[key: string]: string} = {
      dateIssueFrom: "с",
      dateIssueTo: "по",
    };
    const datePaymentNames: {[key: string]: string} = {
      datePaymentFrom: "с",
      datePaymentTo: "по",
    };
    const dateReadyNames: {[key: string]: string} = {
      dateReadyFrom: "с",
      dateReadyTo: "по",
    };
    const dateValidNames: {[key: string]: string} = {
      dateValidFrom: "с",
      dateValidTo: "по",
    };
    const dateExpiredNames: {[key: string]: string} = {
      dateExpiredFrom: "с",
      dateExpiredTo: "по",
    };

    const infoString = (savedControls: IFilterControls) => {
      const applicants: string[] = [];
      const revokeApplicants: string[] = [];
      const statuses: string[] = [];
      const identification: string[] = [];
      const createDates: string[] = [];
      const issueDates: string[] = [];
      const paymentDates: string[] = [];
      const readyDates: string[] = [];
      const validDates: string[] = [];
      const expiredDates: string[] = [];
      const managers: string[] = [];
      const organizations: string[] = [];
      let identificationKind: string = "";
      let paymentStatus: string = "";
      let revokeIssuer: string = "";
      let productId: string = "";
      let companyGroups: string[] = [];

      Object.values(savedControls).forEach(group => {
        Object.keys(group.controls).forEach(name => {
          const control = group.controls[name];
          let value: any = control.value;
          if (
            (value !== null && value !== undefined) &&
            (typeof value === "string" && !isEmpty(value)) ||
            (typeof value === "boolean" && value) ||
            (typeof value === "number" && value !== -1) ||
            (value instanceof Date) ||
            (Array.isArray(value) && value.length)
          ) {
            if (Object.keys(dateCreateNames).includes(name)) {
              value = getFormattedDate(value as Date, "L");
              createDates.push(`${dateCreateNames[name]} ${value}`);
            }
            if (Object.keys(dateIssueNames).includes(name)) {
              value = getFormattedDate(value as Date, "L");
              issueDates.push(`${dateIssueNames[name]} ${value}`);
            }
            if (Object.keys(datePaymentNames).includes(name)) {
              value = getFormattedDate(value as Date, "L");
              paymentDates.push(`${datePaymentNames[name]} ${value}`);
            }
            if (Object.keys(dateReadyNames).includes(name)) {
              value = getFormattedDate(value as Date, "L");
              readyDates.push(`${dateReadyNames[name]} ${value}`);
            }
            if (Object.keys(dateValidNames).includes(name)) {
              value = getFormattedDate(value as Date, "L");
              validDates.push(`${dateValidNames[name]} ${value}`);
            }
            if (Object.keys(dateExpiredNames).includes(name)) {
              value = getFormattedDate(value as Date, "L");
              expiredDates.push(`${dateExpiredNames[name]} ${value}`);
            }
            if (Object.keys(applicantNames).includes(name)) {
              applicants.push(applicantNames[name]);
            }
            if (Object.keys(revokeApplicantNames).includes(name)) {
              revokeApplicants.push(revokeApplicantNames[name]);
            }
            if (Object.keys(statusNames).includes(name)) {
              statuses.push(statusNames[name]);
            }
            if (Object.keys(identificationNames).includes(name)) {
              identification.push(identificationNames[name]);
            }
            if (name == "managers") {
              value.forEach((item: any) => {
                const option = state.filterOptions.managers.find(o => o.value == item);
                if (option) {
                  managers.push(option.label);
                }
              })
            }
            if (name == "organizations") {
              value.forEach((item: any) => {
                const option = state.filterOptions.organizations.find(o => o.value == item);
                if (option) {
                  organizations.push(option.label);
                }
              })
            }
            if (name == "identificationKind") {
              identificationKind = state.filterOptions.identificationKind.find(o => o.value === value)!.label;
            }
            if (name == "paymentStatus") {
              paymentStatus = state.filterOptions.paymentStatus.find(o => o.value === value)!.label;
            }
            if (name == "issuerType") {
              revokeIssuer = REVOKE_ISSUER_TYPE_OPTIONS.find(o => o.value === value)!.label;
            }
            if (name == "productId") {
              productId = state.filterOptions.productId.length ? state.filterOptions.productId.find(o => o.value === value)!.label : '';
            }
            if (name == "companyGroups") {
              value.forEach((item: any) => {
                const option = state.filterOptions.companyGroups.find(o => o.value == item);
                if (option) {
                  companyGroups.push(option.label);
                }
              })
            }
          }
        })
      });
      return {
        applicants: applicants.join(", "),
        revokeApplicants: revokeApplicants.join(", "),
        statuses: statuses.join(", "),
        identification: identification.join(", "),
        identificationKind,
        createDates: createDates.join(" "),
        issueDates: issueDates.join(" "),
        paymentDates: paymentDates.join(" "),
        readyDates: readyDates.join(" "),
        validDates: validDates.join(" "),
        expiredDates: expiredDates.join(" "),
        paymentStatus,
        managers: managers.join(", "),
        organizations: organizations.join(", "),
        revokeIssuer,
        productId,
        companyGroups: companyGroups.join(", "),
      };
    };

    return (pageName: PAGE_NAMES) => {
      const presetName = state.savedCurrentPreset &&
        getters["getPrimitivePresetOptions"](pageName)
          .map(({value}: {value: string}) => value)
          .includes(state.savedCurrentPreset)
        ? state.savedCurrentPreset
        : "";
      const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
      const savedControls = state[savedControlsName];
      return {presetName, ...infoString(savedControls)};
    }
  },

  /*
    Для опции "Создать новый пресет" ставим значение value = "";
    Для каждой страницы, будь то Заявки или Предзаявки, отфильтровывает только соответствующий набор пресетов
  */
  getPrimitivePresetOptions(state) {
    return (pageName: PAGE_NAMES) => {
      return state.presetOptions
        .filter(option => option.pageName === pageName || option.pageName === "")
        .map(option => ({ 
          value: Object.keys(option.filterControls).length ? option.label : "",
          label: option.label
        }));
    };
  },

  /*
    На одной странице, будь то список заявок или предзаявок, можно сохранить только 5 фильтров
    + 1 потому что одна опция это создать новый пресет
  */
  isPresetsLimitOver(state, getters) {
    return (pageName: PAGE_NAMES) =>
      getters.getPrimitivePresetOptions(pageName).length === MAX_FILTER_PRESETS_ON_PAGE + 1;
  },
};
