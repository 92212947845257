import Vue from 'vue';
import { ActionTree } from "vuex";
import { isEqual } from "lodash";

import {app} from "@/main";
import { fetchTryCatch } from "@/services/api";
import { RootState } from "@/store/root.interface";
import api from './filter.api';
import { TOAST_OPTIONS } from '@/constants';
import {
  PAGE_NAMES,
  MAX_FILTER_PRESETS_ON_PAGE,
  FILTER_CONTROLS_NAMES,
  SAVED_FILTER_CONTROLS_NAMES,
  LOCAL_STORAGE_FILTER_ITEM_NAMES,
  FETCH_LIST_METHOD_NAMES,
} from "./filter.constants";
import { IFilterControls, IFilterState, TGroupName } from "./filter.interfaces";

import {
  SET_SEARCH,
  CLEAR_FILTER,
  SET_FILTER_CONTROL,
  SET_IS_OPEN_FILTER,
  SET_SAVE_FILTER_CONTROL,
  SET_CURRENT_PRESET,
  SET_SAVED_CURRENT_PRESET,
  SET_PRESET_OPTIONS,
  DELETE_PRESET_OPTION,
  ADD_PRESET_OPTION,
  SET_FILTER_OPTIONS,
} from "./filter.mutations";
import { FILTER_DEFAULTS } from "./defaults";

const controlsToObject = (controls: IFilterControls) => {
  const obj: any = {};
  Object.values(controls).forEach(group => {
    Object.keys(group.controls).forEach(controlName => {
      obj[controlName] = group.controls[controlName].value;
    })
  });
  return obj;
};

export const actions: ActionTree<IFilterState, RootState> = {
  toggleFilter({ state, commit }, {isOpen, pageName}: {isOpen: boolean, pageName: PAGE_NAMES}) {
    commit(SET_IS_OPEN_FILTER, isOpen);

    if (isOpen) return;

    const controlsName = FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const controls = state[controlsName];
    const savedControls = state[savedControlsName];

    if (!isEqual(controls, savedControls)) {
      Object.values(controls).forEach(group => {
          Object.keys(group.controls).forEach(controlName => {
            const value = group.controls[controlName].value;
            commit(SET_FILTER_CONTROL, {
              pageName,
              groupName: group.name,
              controlName,
              value: Array.isArray(value) ? [...value] : value
            });
          })
      });
    }
  },

  inputHandler({ commit, state }, {pageName, groupName, evt}: {pageName: PAGE_NAMES, groupName: TGroupName, evt: any}) {
    const controlName = evt.target.name
    let value;
    console.log(evt.target )
    if (evt.target.type === "checkbox") {
      value = evt.target.checked;
    } else if (["managers", "organizations"].includes(controlName)) {
      value = [...evt.target.value];
    } else {
      value = evt.target.value;
    }

    commit(SET_FILTER_CONTROL, {pageName, groupName, controlName, value});

    if (state.currentPreset !== null) {
      commit(SET_CURRENT_PRESET, null);
    }
  },

  onChangeSearch({commit}, {pageName, evt}) {
    commit(SET_SEARCH, {pageName, value: evt.target.value});
  },

  clearFilter({ commit }, pageName: PAGE_NAMES) {
    commit(CLEAR_FILTER, pageName);
    commit(SET_CURRENT_PRESET, null);
    commit(SET_IS_OPEN_FILTER, false);
  },

  fetchList({dispatch}, pageName: PAGE_NAMES) {
    dispatch(FETCH_LIST_METHOD_NAMES[pageName], null, {root: true});
  },

  clearFilterHandler({ commit, dispatch }, pageName: PAGE_NAMES) {
    commit(CLEAR_FILTER, pageName);
    commit(SET_CURRENT_PRESET, null);
    commit(SET_SAVED_CURRENT_PRESET, null);
    dispatch("clearFilterLocalStorage", pageName);
    dispatch("saveCurrentPresetNameToLocalStorage", pageName);
    dispatch("fetchList", pageName);
    
    commit(SET_IS_OPEN_FILTER, false);
  },

  applyFilter({ state, dispatch, commit }, pageName: PAGE_NAMES) {
    const controlsName = FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const controls = state[controlsName];
    const savedControls = state[savedControlsName];

    if (
      !isEqual(controls, savedControls) || 
      state.currentPreset !== state.savedCurrentPreset
    ) {
      Object.values(controls).forEach(group => {
          Object.keys(group.controls).forEach(controlName => {
            const value = group.controls[controlName].value;
            commit(SET_SAVE_FILTER_CONTROL, {
              pageName,
              groupName: group.name,
              controlName,
              value: Array.isArray(value) ? [...value] : value
            });
          })
      });

      commit(SET_SAVED_CURRENT_PRESET, state.currentPreset);
      dispatch("saveFilterToLocalStorage", pageName);
      dispatch("saveCurrentPresetNameToLocalStorage", pageName);
      dispatch("fetchList", pageName);

      
      commit(SET_IS_OPEN_FILTER, false);
    }
  }, 

  saveFilterToLocalStorage({state}, pageName: PAGE_NAMES) {
    const itemName = LOCAL_STORAGE_FILTER_ITEM_NAMES[PAGE_NAMES[pageName]];
    const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const savedControls = state[savedControlsName];
    const stringifiedFilter = JSON.stringify(controlsToObject(savedControls));
    window.localStorage.setItem(itemName, stringifiedFilter);
  },
  
  parseFilterFromLocaleStorage({commit, state}, pageName: PAGE_NAMES) {
    const itemName = LOCAL_STORAGE_FILTER_ITEM_NAMES[PAGE_NAMES[pageName]];
    const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const savedControls = state[savedControlsName];

    const stringifiedFilter: null | string = window.localStorage.getItem(itemName);
    if (stringifiedFilter) {
      const parsedFilter = JSON.parse(stringifiedFilter);
      Object.values(savedControls).forEach(group => {
        Object.keys(group.controls).forEach(controlName => {
          if (parsedFilter.hasOwnProperty(controlName)) {
            const value = parsedFilter[controlName];
            commit(SET_FILTER_CONTROL, {pageName, groupName: group.name, controlName, value});
            commit(SET_SAVE_FILTER_CONTROL, {pageName, groupName: group.name, controlName, value});
          }
        })
      });
    }
  },
  
  clearFilterLocalStorage({state}, pageName: PAGE_NAMES) {
    const itemName = LOCAL_STORAGE_FILTER_ITEM_NAMES[PAGE_NAMES[pageName]];
    window.localStorage.setItem(itemName, "");
  },

  async getPresetList({commit}, pageName: PAGE_NAMES) {
    fetchTryCatch({
      url: "api/managerSettings/getFilterPreset",
      type: "GET",
      loading: true,
      errorMessage: "Ошибка получения списка пресетов фильтра",
      statusOKHandler: (data) => {
        if (Array.isArray(data) && data.length) {
          const presetOptions = data.map(({label, value}) => {
            const valueObj = JSON.parse(value);
            const pagePrefix = valueObj.page;

            if (!pageName.startsWith(pagePrefix)) return null;

            const defaultControls = FILTER_DEFAULTS[PAGE_NAMES[pageName as PAGE_NAMES]];
            const controls: IFilterControls = JSON.parse(JSON.stringify(defaultControls));

            Object.keys(controls).forEach((groupName) => {
              const group = controls[groupName as TGroupName];
              Object.keys(group.controls).forEach(controlName => {
                if (valueObj.controls.hasOwnProperty(controlName)) {
                  const value = valueObj.controls[controlName];
                  controls[groupName as TGroupName].controls[controlName].value = value;
                }
              })
            });

            return {
              label,
              pageName,
              filterControls: controls,
            }
          }).filter(Boolean);
          commit(SET_PRESET_OPTIONS, presetOptions);
        }
      }
    });
  },

  initFilterPresets({dispatch}, pageName) {
    dispatch("getPresetList", pageName);
    dispatch("parseCurrentPresetNameFromLocalStorage", pageName);
  },

  onChangePreset({state, commit, getters}, {presetName, pageName}) {
    if (presetName === "") {
      if (getters["isPresetsLimitOver"](pageName)) {
        (app as any).$modal.show("dialog", {
          text: `У Вас уже есть ${MAX_FILTER_PRESETS_ON_PAGE} пресетов.<br />` +
                "Прежде чем добавить новый пресет, пожалуйста, удалите один из старых",
          buttons: [
            {title: "Понятно"},
          ]
        });
      } else {
        (app as any).$modal.show("modal-add-filter-preset");
      }
    } else {
      const currentPreset = state.presetOptions.find(option => option.label === presetName);
      if (currentPreset) {
        commit(SET_CURRENT_PRESET, presetName);
        Object.values(currentPreset.filterControls).forEach(group => {
          if (getters["getFilterControls"](currentPreset.pageName).hasOwnProperty(group.name)) {
            Object.keys(group.controls).forEach(controlName => {
              const value = group.controls[controlName].value;
              commit(SET_FILTER_CONTROL, {
                pageName,
                groupName: group.name,
                controlName,
                value
              });
            });
          }
        });
      }
    }
  },

  addNewPreset({commit, getters}, {presetName, pageName}) {
    const filterControls = getters["getFilterControls"](pageName);
    const pagePrefix = pageName.startsWith("app")
      ? "app"
      :  pageName.startsWith("preapp")
        ? "preapp"
        : pageName.startsWith("certificates")
          ? "certificates"
          : pageName.startsWith("revokes")
            ? "revokes"
            : "error";
    fetchTryCatch({
      url: "/api/managerSettings/updateFilterPreset",
      type: "POST",
      body: [{
        label: presetName,
        value: JSON.stringify({
          page: pagePrefix,
          controls: controlsToObject(filterControls)
        })
      }],
      loading: true,
      errorMessage: "Ошибка добавления нового пресета фильтра",
      successMessage: "Новый пресет фильтра добавлен",
      statusOKHandler: () => {
        commit(ADD_PRESET_OPTION, {
          label: presetName,
          pageName,
          filterControls
        });
        commit(SET_CURRENT_PRESET, presetName);
      }
    });
  },

  deletePreset({state, commit}, {presetName}) {
    fetchTryCatch({
      url: "/api/managerSettings/updateFilterPreset",
      type: "POST",
      body: [{
        label: presetName,
        value: null
      }],
      loading: true,
      errorMessage: "Ошибка удаления пресета фильтра",
      successMessage: `Пресет "${presetName}" удалён`,
      statusOKHandler: () => {
        commit(DELETE_PRESET_OPTION, presetName);
        if (state.currentPreset === presetName) {
          commit(SET_CURRENT_PRESET, null);
          commit(SET_SAVED_CURRENT_PRESET, null);
        }
      }
    });
  },

  saveCurrentPresetNameToLocalStorage({state}, pageName: PAGE_NAMES) {
    let newItem = "";
    const oldItem = window.localStorage.getItem("filterPreset");
    if (oldItem) {
      newItem = JSON.stringify({...JSON.parse(oldItem), [pageName]: state.savedCurrentPreset});
    } else {
      newItem = JSON.stringify({[pageName]: state.savedCurrentPreset});
    } 
    window.localStorage.setItem("filterPreset", newItem);
  },

  parseCurrentPresetNameFromLocalStorage({commit}, pageName) {
    const item = window.localStorage.getItem("filterPreset");
    if (item) {
      const currentPreset = JSON.parse(item)[pageName];
      if (currentPreset) {
        commit(SET_CURRENT_PRESET, currentPreset);
        commit(SET_SAVED_CURRENT_PRESET, currentPreset);
      }
    }
  },

  clearPresets({commit}) {
    commit(SET_CURRENT_PRESET, null);
    commit(SET_SAVED_CURRENT_PRESET, null);
    commit(SET_PRESET_OPTIONS, []);
    window.localStorage.setItem("filterPreset", "");
  },

  async getProducts({commit}, pageName: PAGE_NAMES) {
    const {data, error} = await api.getProducts(pageName);

    if (error || !data?.products) {
      Vue.$toast.error(
        `Ошибка загрузки спикска продуктов в фильтре: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    const options = data.products.map(({id, name}) => ({value: id, label: name}));
    options.unshift({value: -1, label: 'Не выбран'});
    commit(SET_FILTER_OPTIONS, {name: 'productId', options});
  },
};
