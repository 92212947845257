import { FormControl } from "./interfaces/formControl";

export const defaultFormControls: FormControl = {
  oldPassword: {
    value: "",
    label: "Старый пароль",
    type: "password",
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      minLength: 1
    }
  },
  password: {
    value: "",
    label: "Новый пароль",
    type: "password",
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      minLength: 1
    }
  },
  passwordConfirm: {
    value: "",
    label: "Подтверждение пароля",
    type: "password",
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      minLength: 1
    }
  }
};
