import { Module } from "vuex";

import { RootState } from "@/store/root.interface";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import COLUMNS from "@/config/table-columns";
import { PAGE_SHORT_NAMES } from "@/config/pages";
import { ITableState } from "./table.interfaces";

import { MIN_CONTAINER_WIDTH } from "@/constants";

export const tableState: Module<ITableState, RootState> = {
  namespaced: true,
  state: {
    containerWidth: {
      [PAGE_SHORT_NAMES.app]: MIN_CONTAINER_WIDTH,
      [PAGE_SHORT_NAMES.preapp]: MIN_CONTAINER_WIDTH,
      [PAGE_SHORT_NAMES.revokes]: MIN_CONTAINER_WIDTH,
      [PAGE_SHORT_NAMES.certificates]: MIN_CONTAINER_WIDTH,
    },
    columns: COLUMNS,
    contentType: PAGE_SHORT_NAMES.app,
  },
  mutations,
  actions,
  getters,
};