import { MutationTree } from "vuex";
import { ITableState } from "./table.interfaces";

export const SET_CONTAINER_WIDTH = "SET_CONTAINER_WIDTH";
export const SET_TABLE_CONTENT_TYPE = "SET_TABLE_CONTENT_TYPE";

export const mutations: MutationTree<ITableState> = {
  [SET_CONTAINER_WIDTH](state, val: {contentType: ITableState['contentType'], containerWidth: string}) {
    const {contentType, containerWidth} = val;
    const width = parseInt(containerWidth.replace("px", ""), 10);
    state.containerWidth[contentType] = width;
  },
  [SET_TABLE_CONTENT_TYPE](state, contentType: ITableState['contentType']) {
    state.contentType = contentType;
  }
};