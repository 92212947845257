import { MutationTree } from "vuex";

import {
  IFilterState,
  TOption,
  TGroupName,
  TControlName,
  IFilterControls,
  TPresetOption
} from "./filter.interfaces";
import { FILTER_DEFAULTS } from "./defaults";

import {
  PAGE_NAMES,
  CREAT_PRESET_OPTION,
  FILTER_CONTROLS_NAMES,
  SAVED_FILTER_CONTROLS_NAMES,
} from "./filter.constants";

export const SET_SEARCH = "SET_SEARCH";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_IS_OPEN_FILTER = "SET_IS_OPEN_FILTER";
export const SET_FILTER_CONTROL = "SET_FILTER_CONTROL";
export const SET_SAVE_FILTER_CONTROL = "SET_SAVE_FILTER_CONTROL";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_PRESET_OPTIONS = "SET_PRESET_OPTIONS";
export const SET_CURRENT_PRESET = "SET_CURRENT_PRESET";
export const SET_SAVED_CURRENT_PRESET = "SET_SAVED_CURRENT_PRESET";
export const SET_FILTER_ORGANIZATION_OPTIONS = "SET_FILTER_ORGANIZATION_OPTIONS";
export const DELETE_PRESET_OPTION = "DELETE_PRESET_OPTION";
export const ADD_PRESET_OPTION = "ADD_PRESET_OPTION";
export const TOGGLE_GROUP_DISABLED = "TOGGLE_GROUP_DISABLED";

interface IControlsSetter {
  pageName: PAGE_NAMES;
  groupName: TGroupName;
  controlName: TControlName;
  value: string | boolean
}

export const mutations: MutationTree<IFilterState> = {
  [SET_IS_OPEN_FILTER](state, isOpenFilter: boolean) {
    state.isOpenFilter = isOpenFilter;
  },
  [SET_FILTER_CONTROL](state, {pageName, groupName, controlName, value}: IControlsSetter) {
    const mutatedControls = (controls: IFilterControls) => {
      const copy = JSON.parse(JSON.stringify(controls));
      copy[groupName].controls[controlName].value = Array.isArray(value) ? [...value] : value;
      return copy;
    };

    const controlsName = FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const controls = state[controlsName];
    state[controlsName] = mutatedControls(controls);
  },
  [SET_SAVE_FILTER_CONTROL](state, {pageName, groupName, controlName, value}: IControlsSetter) {
    const mutatedControls = (controls: IFilterControls) => {
      const copy = JSON.parse(JSON.stringify(controls));
      copy[groupName].controls[controlName].value = Array.isArray(value) ? [...value] : value;
      return copy;
    };

    const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const savedControls = state[savedControlsName];
    state[savedControlsName] = mutatedControls(savedControls);
  },
  [SET_SEARCH](state, {pageName, value}: {pageName: PAGE_NAMES, value: string}) {
    if (pageName.startsWith("app")) {
      state.appSearch = value;
    } else if (pageName.startsWith("preapp")) {
      state.preappSearch = value;
    } else if (pageName.startsWith("certificates")) {
      state.certificatesSearch = value;
    } else if (pageName.startsWith("revokes")) {
      state.revokesSearch = value;
    }
  },
  [SET_FILTER_OPTIONS](state, {name, options}: {name: string, options: Array<TOption>}) {
    const copy = JSON.parse(JSON.stringify(state.filterOptions));
    copy[name] = options;
    state.filterOptions = copy;
  },
  [CLEAR_FILTER](state, pageName: PAGE_NAMES) {
    const controlsName = FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const savedControlsName = SAVED_FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const defaultControls = FILTER_DEFAULTS[PAGE_NAMES[pageName]];
    state[controlsName] = JSON.parse(JSON.stringify(defaultControls));
    state[savedControlsName] = JSON.parse(JSON.stringify(defaultControls));
  },
  [SET_CURRENT_PRESET](state, currentPreset: string | null) {
    state.currentPreset = currentPreset;
  },
  [SET_SAVED_CURRENT_PRESET](state, currentPreset: string | null) {
    state.savedCurrentPreset = currentPreset;
  },
  [SET_PRESET_OPTIONS](state, presetOptions: Array<TPresetOption>) {
    if (Array.isArray(presetOptions) && presetOptions.length) {
      presetOptions.reverse().forEach(option => {
        if (!state.presetOptions.find(o => o.label === option.label)) {
          state.presetOptions.unshift(option);
        }
      });
    } else {
      state.presetOptions = [CREAT_PRESET_OPTION];
    }
  },
  [ADD_PRESET_OPTION](state, presetOption: TPresetOption) {
    const index = state.presetOptions.length - 2; // предпоследний, перед "Создать новый пресет"
    state.presetOptions.splice(index, 0, presetOption);
  },
  [DELETE_PRESET_OPTION](state, presetName: string) {
    const index = state.presetOptions.findIndex(option => option.label === presetName);
    state.presetOptions.splice(index, 1);
  },
  [TOGGLE_GROUP_DISABLED](state, {
    pageName,
    groupName,
    disabled,
  }: {
    pageName: PAGE_NAMES;
    groupName: TGroupName;
    disabled: boolean;
  }) {
    const controlsName = FILTER_CONTROLS_NAMES[PAGE_NAMES[pageName]];
    const _controls = JSON.parse(JSON.stringify(state[controlsName]));
    if (_controls[groupName]) {
      _controls[groupName].disabled = disabled;
      state[controlsName] = _controls;
    }
  },
};
