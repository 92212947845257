import { MutationTree } from "vuex";

import { IApplicationListState } from "./application-list.interfaces";

export const SET_ARCHIVE = "SET_ARCHIVE";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const SET_SIZE_PAGES = "SET_SIZE_PAGES";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_IS_CHIEF_MANAGER  = "SET_IS_CHIEF_MANAGER";
export const SET_APPLICATIONS_LIST = "SET_APPLICATIONS_LIST";
export const CLEAR_APPLICATION_LIST = "CLEAR_APPLICATION_LIST";
export const SET_IS_MANAGER_PARTNER  = "SET_IS_MANAGER_PARTNER";

export const mutations: MutationTree<IApplicationListState> = {
  [SET_TOTAL_PAGES](state, num: number) {
    state.totalPages = num;
  },
  [SET_ACTIVE_PAGE](state, num: number) {
    state.activePage = num;
  },
  [SET_SIZE_PAGES](state, num: number) {
    state.sizePage = num;
  },
  [SET_APPLICATIONS_LIST](state, applications: []) {
    state.applicationsList = [...applications];
  },
  [SET_IS_LOADED](state, val: boolean) {
    state.isLoaded = val;
  },
  [CLEAR_APPLICATION_LIST](state) {
    state.sizePage = 10;
    state.activePage = 1;
    state.totalPages = null;
  },
  [SET_ARCHIVE](state, val: boolean) {
    state.isArchive = val;
  },
  [SET_IS_CHIEF_MANAGER](state, val: boolean) {
    state.isChiefManager = val;
  },
  [SET_IS_MANAGER_PARTNER](state, val: boolean) {
    state.isManagerPartner = val;
  },
};
