import { Module } from "vuex";

import { RootState } from "@/store/root.interface";
import { REVOKE_FORM_FIELDS } from '@/config/form-fields';
import { REVOKE_CLIENT_DOCUMENTS } from "@/config/form-documents";

import { actions } from "./revoke.actions";
import { getters } from "./revoke.getters";
import { mutations } from "./revoke.mutations";
import { IRevokeState } from "./revoke.types";

export const revokeState: Module<IRevokeState, RootState> = {
  namespaced: true,
  state: {
    orderId: null,
    guid: null,
    activeTab: 1,
    statusId: null,
    applicantType: null,
    dateCreate: null,
    dateUpdate: null,
    dateRevoke: null,
    fields: JSON.parse(JSON.stringify(REVOKE_FORM_FIELDS)),
    isApplicationGenerated: false,
    clientDocuments: JSON.parse(JSON.stringify(REVOKE_CLIENT_DOCUMENTS)),
    verifyingCode: null,
    revokeNotAllowed: true,
  },
  mutations,
  actions,
  getters
};
