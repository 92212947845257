import Vue from "vue";
import { fetch } from ".";
import {app} from "@/main";
import { TOAST_OPTIONS } from "@/constants";

interface IFetchTryCatch {
  url: string;
  type: "GET" | "POST";
  body?: any;
  loading?: boolean;
  successMessage?: string;
  errorMessage?: string;
  statusOKHandler: (data?: any) => void;
  statusNOKHandler?: (data?: any) => void;
  catchHandler?: (error?: any) => void;
}

async function fetchTryCatch({
  url,
  type,
  body,
  loading,
  successMessage,
  errorMessage = "Ошибка",
  statusOKHandler,
  statusNOKHandler,
  catchHandler
}: IFetchTryCatch) {
  if (loading && app) {
    (app as any).$modal.show("loader");
  }
  try {
    let res: any;
    switch(type) {
      case "GET":
        res = await fetch.get(url);
        break;
      case "POST":
        res = await fetch.post(url, body);
    }
    if (res && res.status === 200) {
      if (successMessage) {
        Vue.$toast.success(successMessage, TOAST_OPTIONS.Success);
      }

      if (res.data && statusOKHandler && typeof statusOKHandler === "function") {
        statusOKHandler(res.data);
      }
    } 
    else {
      if (res.data && statusNOKHandler && typeof statusNOKHandler === "function") {
        statusNOKHandler(res.data);
      }
    }
  } catch(err) {
    const error = err as any;
    const message = `${errorMessage}: ${error.data
                                          ? error.data || error.data.message
                                          : error.body || error.message}`;
    Vue.$toast.error(message, TOAST_OPTIONS.Error);

    if (catchHandler && typeof catchHandler === "function") {
      catchHandler(err);
    }
  } finally {
    if (loading && app) {
      (app as any).$modal.hide("loader");
    }
  }
}

export default fetchTryCatch;
