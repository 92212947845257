import anime from "animejs";

import getPosition from "@/utils/getPosition";

export const scrollToInvalidFormItem = () => {
    setTimeout(() => {
        const scrollElement =
            window.document.scrollingElement ||
            window.document.body ||
            window.document.documentElement;
        const position: number[] = [];

        document.querySelectorAll(".form-item.invalid").forEach((elem) => {
            position.push(getPosition(elem).top);
        });

        anime({
            targets: scrollElement,
            scrollTop: Math.min.apply(null, position),
            duration: 800,
            easing: "easeInOutQuad",
        });
    }, 50);
}