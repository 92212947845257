import { GetterTree } from "vuex";

import { IAuthorizationState } from "./authorization.interfaces";
import { RootState } from "@/store/root.interface";
import ROLES from "@/config/roles";

export const getters: GetterTree<IAuthorizationState, RootState> = {
  getFormControls(state: any) {
    if (state.isRecoveryPassword) {
      return {
        email: { ...state.formControls.email }
      };
    }

    return state.formControls;
  },

  getRole(state): ROLES {
    return state.role in ROLES ? state.role : ROLES.Manager;
  },

  isChiefManager(state) {
    return state.role === ROLES.ChiefManager;
  },

  isManagerPartner(state) {
    return state.role === ROLES.ManagerPartner;
  },
};
