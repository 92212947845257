import { PAGE_NAMES } from "../filter.constants";
import defaultAppFilterControls from "./filter.defaultControls.app";
import defaultAppArchiveFilterControls from "./filter.defaultControls.app-archive";
import defaultAppManagerPartnerFilterControls from "./filter.defaultControls.app-manager-partner";
import defaultAppManagerPartnerArchiveFilterControls from "./filter.defaultControls.app-manager-partner-archive";
import defaultPreappFilterControls from "./filter.defaultControls.preapp";
import defaultCertificatesFilterControls from "./filter.defaultControls.certificates";
import defaultAppHeadManagerFilterControls from "./filter.defaultControls.app-head-manager";
import defaultAppHeadManagerArchiveFilterControls from "./filter.defaultControls.app-head-manager-archive";
import defaultRevokesFilterControls from "./filter.defaultControls.revokes";

export const FILTER_DEFAULTS = {
  [PAGE_NAMES.app]: defaultAppFilterControls,
  [PAGE_NAMES.appArchive]: defaultAppArchiveFilterControls,
  [PAGE_NAMES.appManagerPartner]: defaultAppManagerPartnerFilterControls,
  [PAGE_NAMES.appManagerPartnerArchive]: defaultAppManagerPartnerArchiveFilterControls,
  [PAGE_NAMES.preapp]: defaultPreappFilterControls,
  [PAGE_NAMES.certificates]: defaultCertificatesFilterControls,
  [PAGE_NAMES.appHeadManager]: defaultAppHeadManagerFilterControls,
  [PAGE_NAMES.appHeadManagerArchive]: defaultAppHeadManagerArchiveFilterControls,
  [PAGE_NAMES.revokes]: defaultRevokesFilterControls,
};
