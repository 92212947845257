import { fetch } from '@/services/api';
import {app} from "@/main";

import { APPLICATION_LIST_URLS as URL_LIST } from '@/config/urls';
import { IApplicationListItem } from './application-list.interfaces';

const getApplicationList = async ({
  sizePage,
  activePage,
  queryParams,
  isArchive,
}: {
  sizePage: number;
  activePage: number;
  queryParams: string;
  isArchive: boolean;
}) => {
  const response: {
    data?: Array<IApplicationListItem>;
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetApplicationList}/${sizePage}/${activePage}${queryParams}${isArchive ? (queryParams ? '&' : '?') + 'statusArchive=true' : ''}`,
    );
  
    if (status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.error) throw new Error(data.error);

    response.data = data;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
  }
  
  return response;
};

const uploadXmlWithApps = async ({ base64 }: { base64: string; }) => {
  const response: {
    data?: {
      errorMessage: string | null;
      notValidNumbers: string[];
      succeedNumbers: Array<Record<string, number>>; // [{[succeedNumber]: orderId}]
      failedNumbers: Array<Record<string, string>>; // [{[failedNumber]: errorMessage}];
      errorsBase64?: string; // base64
    };
    error?: string;
  } = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.UploadXmlWithApps}`,
      {XmlContentBase64: base64},
    );

    if (status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.errorMessage) throw new Error(data.errorMessage);

    response.data = data;
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
  } finally {
    app.$modal.hide("loader");
  }

  return response;
};

export default {
  getApplicationList,
  uploadXmlWithApps,
};
