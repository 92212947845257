import {app} from "@/main";
import { fetch } from '@/services/api';

import { SMS_URLS as URL_LIST } from '@/config/urls';
import { IGetPreappSmsListResponse } from './sms.types';
import { SMS_TYPE_OPTION_IDS } from "./sms.config";

const getPreappSmsList = async (id: string | number) => {
  const response: {data?: IGetPreappSmsListResponse; error?: string} = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetPreappSmsList}/${id}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);

    response.data = data;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка'
      );
  }
  
  return response;
};

const sendPreappSms = async ({
  id,
  smsType,
  text,
}: {
  id: string | number;
  smsType: SMS_TYPE_OPTION_IDS;
  text: string;
}) => {
  const response: {error?: string} = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.SendPreappSms}/${id}`,
      {smsType, text}
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка'
      );
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

export default {
  getPreappSmsList,
  sendPreappSms,
};
