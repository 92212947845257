import { Module } from "vuex";

import { RootState } from "@/store/root.interface";

import { ISmsState } from "./sms.types";
import { mutations } from "./sms.mutations";
import { actions } from "./sms.actions";

export const smsState: Module<ISmsState, RootState> = {
  namespaced: true,

  state: {
    smsHistory: [],
    isAllowedToSend: true,
    smsInfo: {
      filialPhone: '',
      filialAddress: '',
      filialName: '',
    },
  },

  mutations,
  actions,
};
