import { MutationTree } from 'vuex';

import {
  STATUS_IDS,
  CERT_SUBJECT_TYPE_IDS,
  DOCUMENT_TYPE_IDS
} from '@/constants/revoke.constants';
import { REVOKE_FORM_FIELDS } from '@/config/form-fields';
import { REVOKE_CLIENT_DOCUMENTS } from "@/config/form-documents";

import { IRevokeState, TFieldName } from './revoke.types';

export const SET_REVOKE_GUID = 'SET_REVOKE_GUID';
export const CLEAR_REVOKE_STATE = 'CLEAR_REVOKE_STATE';
export const SET_REVOKE_ORDER_ID = 'SET_REVOKE_ORDER_ID';
export const SET_REVOKE_STATUS_ID = 'SET_REVOKE_STATUS_ID';
export const SET_REVOKE_NOT_ALLOWED = 'SET_REVOKE_NOT_ALLOWED';
export const SET_REVOKE_DATE_CREATE = 'SET_REVOKE_DATE_CREATE';
export const SET_REVOKE_DATE_UPDATE = 'SET_REVOKE_DATE_UPDATE';
export const SET_REVOKE_DATE_REVOKE = 'SET_REVOKE_DATE_REVOKE';
export const SET_REVOKE_APPLICANT_TYPE = 'SET_REVOKE_APPLICANT_TYPE';
export const SET_REVOKE_FIELD_VALUE = 'SET_REVOKE_FIELD_VALUE';
export const SET_REVOKE_ACTIVE_TAB = 'SET_REVOKE_ACTIVE_TAB';
export const SET_REVOKE_CLIENT_DOCUMENT = 'SET_REVOKE_CLIENT_DOCUMENT';
export const SET_REVOKE_IS_APPLICATION_GENERATED = 'SET_REVOKE_IS_APPLICATION_GENERATED';
export const FILTER_CLIENT_DOCUMENTS_BY_APPLICANT = 'FILTER_CLIENT_DOCUMENTS_BY_APPLICANT';

export const mutations: MutationTree<IRevokeState> = {
  [SET_REVOKE_GUID](state, guid: string) {
    state.guid = guid;
  },
  [SET_REVOKE_ORDER_ID](state, orderId: number) {
    state.orderId = orderId;
  },
  [SET_REVOKE_ACTIVE_TAB](state, activeTab: number) {
    state.activeTab = activeTab;
  },
  [SET_REVOKE_STATUS_ID](state, status: STATUS_IDS) {
    state.statusId = status;
  },
  [SET_REVOKE_DATE_CREATE](state, dateCreate: Date) {
    state.dateCreate = dateCreate;
  },
  [SET_REVOKE_DATE_UPDATE](state, dateUpdate: Date) {
    state.dateUpdate = dateUpdate;
  },
  [SET_REVOKE_DATE_REVOKE](state, dateRevoke: Date) {
    state.dateRevoke = dateRevoke;
  },
  [SET_REVOKE_APPLICANT_TYPE](state, applicantType: CERT_SUBJECT_TYPE_IDS) {
    state.applicantType = applicantType;
  },
  [SET_REVOKE_FIELD_VALUE](state, {fieldName, value}: {fieldName: TFieldName, value: any}) {
    const field = {...state.fields[fieldName], value};
    state.fields = {...state.fields, [fieldName]: field};
  },
  [SET_REVOKE_CLIENT_DOCUMENT](state, {fileType, document}: {fileType: DOCUMENT_TYPE_IDS, document: any}) {
    state.clientDocuments = {...state.clientDocuments, [fileType]: document};
  },
  [SET_REVOKE_IS_APPLICATION_GENERATED](state, isApplicationGenerated: boolean) {
    state.isApplicationGenerated = isApplicationGenerated;
  },
  [FILTER_CLIENT_DOCUMENTS_BY_APPLICANT](state) {
    for (const documentType in state.clientDocuments){
      if (!state.clientDocuments[documentType as unknown as DOCUMENT_TYPE_IDS]!.allowedApplicants.includes(state.applicantType!)) {
        delete state.clientDocuments[documentType as unknown as DOCUMENT_TYPE_IDS];
      }
    }
  },
  [CLEAR_REVOKE_STATE](state) {
    state.orderId = null;
    state.guid = null;
    state.activeTab = 1;
    state.statusId = null;
    state.applicantType = null;
    state.dateCreate = null;
    state.dateUpdate = null;
    state.dateRevoke = null;
    state.fields = JSON.parse(JSON.stringify(REVOKE_FORM_FIELDS));
    state.isApplicationGenerated = false;
    state.clientDocuments = JSON.parse(JSON.stringify(REVOKE_CLIENT_DOCUMENTS));
  },
  [SET_REVOKE_NOT_ALLOWED](state, revokeNotAllowed: boolean) {
    state.revokeNotAllowed = revokeNotAllowed;
  },
};
