import {app} from "@/main";
import { fetch } from '@/services/api';
import { REVOKE_LIST_URLS as URL_LIST } from '@/config/urls';
import {
  REVOKE_REASON_TYPE_IDS,
  REVOKE_IDENTIFICATION_TYPE_IDS
} from '@/constants/revoke.constants';

import { IListItem, ICertificate, TGetCertificatesByFilterSearchBy } from './revoke-list.types';

const getList = async (
  {sizePage, activePage, queryParams}:
  {sizePage: number; activePage: number, queryParams: string}
) => {
  app.$modal.show("loader");

  const response: {
    data?: {
      applications: IListItem[];
      totalPages: number;
      currentPage: number;
    };
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetList}/${sizePage}/${activePage}${queryParams}`
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getReport = async ({queryParams}: {queryParams: string}) => {
  app.$modal.show("loader");

  const response: {
    data?: string | File | Blob | Uint8Array;
    headers?: any;
    error?: string;
  } = {};

  try {
    const { status, data, headers } = await fetch.get(
      `${URL_LIST.GetReport}${queryParams}`,
      {responseType: "blob"}
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
    response.data = data;
    response.headers = headers;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getCertificatesByFilter = async (
  {searchBy, search}: {searchBy: TGetCertificatesByFilterSearchBy; search: string;}
) => {
  app.$modal.show("loader");

  const response: {
    data?: {
      certificates: ICertificate[];
    };
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetCertificatesByFilter}?search=${search}&searchBy=${searchBy}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const createNewRevoke = async ({
  identificationType,
  reason,
  serialNumber,
  phone,
  thumbprint,
}: {
  identificationType: REVOKE_IDENTIFICATION_TYPE_IDS;
  reason: REVOKE_REASON_TYPE_IDS;
  serialNumber: string;
  phone: string;
  thumbprint: string;
}) => {
  app.$modal.show("loader");

  const response: {
    data?: {
      number: number;
    };
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.CreateNewRevoke}`,
      {
        identificationType,
        reason,
        serialNumber,
        phone,
        thumbprint,
      },
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');
    
    response.data = data.success;

  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getFilialByManager = async (managerId: number) => {
  const response: {
    data?: {
      id: number;
      shortName: string;
      regionId: number;
      regionName: string;
      inn: string;
      legalAddress: string;
      phone: string;
      email: string;
      organizationTypeId: number;
      organizationType: string;
      options: {
        licensed: boolean;
        allowedRevocation: boolean;
      };
    };

    error?: string
  } = {};

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.GetFilialByManager}`,
      {data: managerId}
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    if (data && !data.success) throw new Error('Ответ не успешный');

    response.data = data.success;
    
  } catch(err) {
    const error = err as any;
    response.error = (error.response && JSON.stringify(error.response.data))
                      || error.message
                      || 'Неизвестная ошибка';
  }

  return response;
};

export default {
  getList,
  getReport,
  getCertificatesByFilter,
  createNewRevoke,
  getFilialByManager
};
