import Vue from "vue";
import { fetch } from "@/services/api";
import { ActionTree } from "vuex";
import {POSITION} from "vue-toastification";

import {app} from "@/main";
import { RootState } from "@/store/root.interface";
import { IPreApplicationList } from "./preapplication-list.interfaces";

import {
  SET_ACTIVE_PAGE,
  SET_SIZE_PAGES,
  SET_TOTAL_PAGES,
  SET_PREAPPLICATIONS_LIST,
  SET_IS_LOADED,
  SET_NEW_PREAPP_NUM,
} from "./preapplication-list.mutations";
import {TOAST_OPTIONS} from '@/constants';

export const actions: ActionTree<IPreApplicationList, RootState> = {

  async getNewPreAppNum({ commit }) {
    try {
        const res = await fetch.get(
            `api/external/preApplication/getCountNewPreApplications`
        );

        if (res.status === 200) {
          if (!res.data || !res.data.hasOwnProperty('count') || typeof res.data.count !== 'number') {
            throw new Error('Счётчик предзаявок отсутствует из-за неожиданного типа')
          }

          commit(SET_NEW_PREAPP_NUM, res.data.count);
        }
    } catch (error) {
        const err = error as any
        const errorMessage = (err.response && err.response.data) || err.message || 'Неизвестная ошибка';

        Vue.$toast.error(`Ошибка при попытке получить счётчик предзаявок: ${errorMessage}`, TOAST_OPTIONS.Error);
    }
},

  changePage({ commit, state, dispatch }, page: number) {
    if (state.activePage !== page) {
      commit(SET_ACTIVE_PAGE, page);
      dispatch("getPreApplication", true);
    }
  },

  changeSizePage({ commit, state, dispatch }, sizePage: number) {
    if (state.sizePage !== sizePage) {
      commit(SET_SIZE_PAGES, sizePage);
      dispatch("getPreApplication", true);
    }
  },


  setActivePage({ commit }, num: number) {
    commit(SET_ACTIVE_PAGE, num);
  },

  async getPreApplication({ commit, state, rootGetters }) {
    let errorMessage: string = "";
    let preappList: any[] = [];

    app && (app as any).$modal.show("loader");

    const queryParams = rootGetters["filterState/getSearchString"]("preapp")
      + rootGetters["filterState/getFilterString"]("preapp");
    try {
      const res = await fetch.get(
        `api/InternalPreApplication/${state.sizePage}/${state.activePage}${queryParams}`
      );

      if (res.status === 200) {
        const {
          preapplicationList,
          totalPages, currentPage,
          newPreAppNum,
          lastComments,
          lastManagerComments,
        } = res.data;

        commit(SET_TOTAL_PAGES, totalPages);

        if (currentPage !== state.activePage) {
          commit(SET_ACTIVE_PAGE, currentPage);
        }

        if (!!preapplicationList) {
          preappList = preapplicationList;
          
          if (!!lastComments && !!lastManagerComments) {
            preappList = preappList
              .map((app: any) => {
                const lastComment = lastComments
                  .find((comm: any) => comm.id == app.id)?.comment;
                const lastManagerComment = lastManagerComments
                  .find((comm: any) => comm.id == app.id)?.comment;
  
                return {
                  ...app,
                  comment: lastComment || null,
                  managerComment: lastManagerComment || null
                };
            });
          } else {
            errorMessage = errorMessage + "\nКомментарии к предзаявкам не были загружены и не могут быть отображены в таблице.";
          }
        } else {
          errorMessage = errorMessage + "\nСписок предзаявок не был загружен и не может быть отображён в таблице.";
        }

        commit(SET_PREAPPLICATIONS_LIST, preappList);

        commit(SET_IS_LOADED, true);
      }
    } catch (err) {
      throw err;
    } finally {
      app && (app as any).$modal.hide("loader");
      if (errorMessage) {
        Vue.$toast.error("Ошибка" + errorMessage, {
          position: POSITION.TOP_LEFT,
          timeout: 180000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    }
  },
};
