









import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "Copy" })
export default class Copy extends Vue {
  @Prop({ type: Number, default: 20 })
  public readonly width!: number;
  @Prop({ type: Number, default: 20 })
  public readonly height!: number;
}
