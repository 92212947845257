import { GetterTree } from "vuex";

import { ITableState } from "./table.interfaces";
import { RootState } from "@/store/root.interface";
import { CONTAINER_PADDINGS } from "@/constants";
import ROLES from "@/config/roles";
import { PAGE_SHORT_NAMES } from "@/config/pages";

export const getters: GetterTree<ITableState, RootState> = {
  getTableWidth(state) {
    return {
      app: state.containerWidth[PAGE_SHORT_NAMES.app] - CONTAINER_PADDINGS,
      preapp: state.containerWidth[PAGE_SHORT_NAMES.preapp] - CONTAINER_PADDINGS,
      revokes: state.containerWidth[PAGE_SHORT_NAMES.revokes] - CONTAINER_PADDINGS,
      certificates: state.containerWidth[PAGE_SHORT_NAMES.certificates] - CONTAINER_PADDINGS,
    };
  },

  getColumnTitles(state, getters, rootState, rootGetters) {
    const role: ROLES = rootGetters["authorizationState/getRole"];
    const columns = state.columns[state.contentType][role] ?? state.columns[state.contentType][ROLES.Manager];
    return columns!.map(({title}) => title);
  },
}
