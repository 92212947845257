import { ActionTree } from "vuex";

import { RootState } from "@/store/root.interface";
import { ITableState } from "./table.interfaces";
import { SET_CONTAINER_WIDTH, SET_TABLE_CONTENT_TYPE } from "./mutations";
import { MIN_CONTAINER_WIDTH, MAX_CONTAINER_WIDTH } from "@/constants";

export const actions: ActionTree<ITableState, RootState> = {
  startListenTableWidth({commit, state}) {
    let cursorX: number;
    let containerWidth: number;
    const containerElement = document.getElementById(`${state.contentType}-container`);

    function mouseDown(event: any, type: 'right' | 'left') {
      cursorX = event.clientX;
      const containerStyles = window.getComputedStyle(containerElement as Element);
      containerWidth = parseInt(containerStyles.width, 10);
      document.addEventListener('mousemove', type == 'right' ? mouseMoveRight : mouseMoveLeft);
      document.addEventListener('mouseup', mouseUp);
    }
    function mouseMove(type: 'right' | 'left', event: any) {
      const factor = type == 'right' ? 1 : -1; // определяет, расширяем контейнер или сужаем
      const dx = (event.clientX - cursorX); // дельта, сколько прошёл курсор в зажатом состоянии
      const newContainerWidth = containerWidth + 2 * factor * dx; // умножаем на 2 потому что расширяем и сужаем одновременно вправо и влево
      if (newContainerWidth >= MIN_CONTAINER_WIDTH && newContainerWidth <= MAX_CONTAINER_WIDTH) {
        containerElement!.style.width = `${newContainerWidth}px`;
      }
    }
    function mouseMoveRight(event: any) {
      event.target.onselectstart = () => false;
      mouseMove('right', event);
    }
    function mouseMoveLeft(event: any) {
      event.target.onselectstart = () => false;
      mouseMove('left', event);
    }
    function mouseUp() {
      const containerWidth = containerElement!.style.width;
      commit(SET_CONTAINER_WIDTH, {contentType: state.contentType, containerWidth});
      window.localStorage.setItem(`${state.contentType}-container-width`, containerWidth);
      document.removeEventListener('mousemove', mouseMoveRight);
      document.removeEventListener('mousemove', mouseMoveLeft);
      document.removeEventListener('mouseup', mouseUp);
    }

    const resizerRight = containerElement!.querySelector('.resizer-right');
    const resizerLeft = containerElement!.querySelector('.resizer-left');
    resizerRight!.addEventListener('mousedown', (event) => mouseDown(event, 'right'));
    resizerLeft!.addEventListener('mousedown', (event) => mouseDown(event, 'left'));
  },

  startListenCellsWidth({state, getters}) {
    const cells = document.querySelectorAll(".table-row-thead > td");
    cells.forEach((cell, index) => {
      if (index < cells.length - 1) {
        let cursorX: number;
        let cellWidth: number;
        let nextCellWidth: number;
        let cellPart: number;
        let nextCellPart: number;
        const nextCell = cells[index + 1];
        const paddingRight = index === cells.length - 2 ? 45 : 20;
        const paddingLeft = index === 0 ? 45 : 20;
        function mouseDown(event: any) {
          event.target.onselectstart = () => false;
          cursorX = event.clientX;
          const cellStyles = window.getComputedStyle(cell as Element);
          const nextCellStyles = window.getComputedStyle(nextCell as Element);
          cellWidth = parseInt(cellStyles.width, 10);
          nextCellWidth = parseInt(nextCellStyles.width, 10);
          document.addEventListener('mousemove', mouseMove);
          document.addEventListener('mouseup', mouseUp);
        }
        function mouseMove(event: any) {
          const dx = event.clientX - cursorX; // дельта, сколько прошёл курсор в зажатом состоянии
          const dp = dx / getters.getTableWidth[state.contentType]; // дельта относительно ширины таблицы
          if (
            (dx > 0 && nextCellWidth - paddingRight - dx > 0) ||
            (dx < 0 && dx + cellWidth - paddingLeft > 0) // условия, чтобы курсор не выходил за границы ячеек при расширении и сжатии
          ) {
            cellPart = (cellWidth / getters.getTableWidth[state.contentType]) + dp; // левая ячейка сжимается
            nextCellPart = (nextCellWidth / getters.getTableWidth[state.contentType]) - dp; // правая расширяется на такое же значение, и наоборот
            (cell as HTMLElement).style.width = `${cellPart * 100}%`;
            (nextCell as HTMLElement).style.width = `${nextCellPart * 100}%`;
          }
        }
        function mouseUp() {
          window.localStorage.setItem(`${state.contentType}-column-${index}-width`, (cell as HTMLElement).style.width);
          window.localStorage.setItem(`${state.contentType}-column-${index + 1}-width`, (nextCell as HTMLElement).style.width);
          document.removeEventListener('mousemove', mouseMove);
          document.removeEventListener('mouseup', mouseUp);
        }
        const resizer = cell.querySelector('.table-cell-resizer');
        resizer!.addEventListener('mousedown', mouseDown);
      }
    })          
  },

  setTableContentType({commit}, contentType: ITableState['contentType']) {
    commit(SET_TABLE_CONTENT_TYPE, contentType);
  }
}
