import Vue from "vue";
import { fetch } from "@/services/api";
import { ActionTree } from "vuex";
import {app} from "@/main";
import cookies from "js-cookie";
import {POSITION} from "vue-toastification";

import { RootState } from "@/store/root.interface";
import { IApplicationListState } from "./application-list.interfaces";
import { PAGE_NAMES } from '@/store/modules/filter/filter.constants';

import download from "downloadjs";

import {
  SET_ACTIVE_PAGE,
  SET_SIZE_PAGES,
  SET_ARCHIVE,
  SET_TOTAL_PAGES,
  SET_APPLICATIONS_LIST,
  SET_IS_LOADED,
  SET_IS_CHIEF_MANAGER,
  SET_IS_MANAGER_PARTNER,
} from "./application-list.mutations";
import api from './application-list.api';
import applicationApi from '@/store/modules/application/application.api';
import { TOAST_OPTIONS } from "@/constants";
import { ICompanyGroup } from "../application/application.interfaces";
import { fileToBase64, base64ToFile } from '@/helpers';
import {extname} from "@/utils";

export const actions: ActionTree<IApplicationListState, RootState> = {
  changePage({ commit, state, dispatch }, page: number) {
    if (state.activePage !== page) {
      commit(SET_ACTIVE_PAGE, page);
      dispatch("getInitialApplication");
    }
  },

  changeSizePage({ commit, state, dispatch }, sizePage: number) {
    if (state.sizePage !== sizePage) {
      commit(SET_SIZE_PAGES, sizePage);
      dispatch("getInitialApplication");
    }
  },

  setArchive({ commit }, val: boolean) {
    commit(SET_ARCHIVE, val);
  },

  setActivePage({ commit }, num: number) {
    commit(SET_ACTIVE_PAGE, num);
  },

  async getInitialApplication({ commit, state, getters, rootGetters }) {
    let errorMessage: string = "";
    let appList: any[] = [];

    app?.$modal.show("loader");

    const queryParams = rootGetters["filterState/getSearchString"]("app")
      + rootGetters["filterState/getFilterString"](getters["getPageName"]);

    Promise.allSettled([
      api.getApplicationList({
        sizePage: state.sizePage,
        activePage: state.activePage,
        queryParams,
        isArchive: state.isArchive,
      }),
      applicationApi.companyGroupList(),
    ])
    .then(([getApplicationListResult, companyGroupListResult]: any) => {
      if (getApplicationListResult.status === 'fulfilled') {
        const {data, error} = getApplicationListResult.value;

        if (error || !data) {
          Vue.$toast.error(`Ошибка получения списка заявок: ${error}`);
          return;
        }

        const {
          applicationList,
          totalPages, 
          currentPage,
          isChiefManager,
          newPreAppNum,
          isManagerPartner,
          filterManager,
          filterOrganizations,
          lastComments,
          lastManagerComments,
          smevStatuses,
          companyGroups,
        } = data;

        commit(SET_TOTAL_PAGES, totalPages);

        if (currentPage !== state.activePage) {
          commit(SET_ACTIVE_PAGE, currentPage);
        }

        commit(SET_IS_CHIEF_MANAGER, isChiefManager);
        commit(SET_IS_MANAGER_PARTNER, isManagerPartner);
        commit("filterState/SET_FILTER_OPTIONS", {name: "managers", options: filterManager}, {root: true});
        commit("filterState/SET_FILTER_OPTIONS", {name: "organizations", options: filterOrganizations}, {root: true});

        if (!!applicationList) {
          appList = applicationList;

          if (!!lastComments && !!lastManagerComments) {
            appList = appList
              .map((app: any) => {
                const lastComment = lastComments
                  .find((comm: any) => comm.id == app.id)?.comment;
                const lastManagerComment = lastManagerComments
                  .find((comm: any) => comm.id == app.id)?.comment;
  
                return {
                  ...app,
                  comment: lastComment || null,
                  managerComment: lastManagerComment || null
                };
            });
          } else {
            errorMessage = errorMessage + "\nКомментарии к заявкам не были загружены и не могут быть отображены в таблице.";
          }
          
          if (!!smevStatuses) {
            appList = appList
              .map((app: any) => {
                const statusSMEV = smevStatuses
                  .find((item: any) => item.appId == app.id)?.statusSMEV;
  
                return {
                  ...app,
                  statusSMEV: statusSMEV || null,
                };
            });
          } else {
            errorMessage = errorMessage + "\nСМЭВ статусы к заявкам не были загружены и не могут быть отображены в таблице.";
          }
        } else {
          errorMessage = errorMessage + "\nСписок заявок не был загружен и не может быть отображён в таблице.";
        }
        
        commit(SET_APPLICATIONS_LIST, appList);
        
        if(newPreAppNum!=0 && cookies.get("_new_preapp_shown")!=="true"){
          (app as any).$modal.show("dialog", {
            title: "Внимание",
            text: "У вас есть предзаявки со статусом \"Новая предзаявка\"",
            buttons: [
                {
                    title: "ЗАКРЫТЬ",
                    default: true,
                    handler: () => {
                        (app as any).$modal.hide("dialog");
                    },
                },
                {
                  title: "ПЕРЕЙТИ",
                  default: true,
                  handler: () => {
                    (app as any).$modal.hide("dialog");
                    (app as any).$router.push('/preapplication/list');
                  },
              },
            ],
          });
        }

        cookies.set("_new_preapp_shown","true");
        commit(SET_IS_LOADED, true);
      }

      if (companyGroupListResult.status === 'fulfilled') {
        const {data, error} = companyGroupListResult.value;

        if (error || !data) {
            Vue.$toast.error(`Ошибка получения групп компаний: ${error || 'нет данных'}`, TOAST_OPTIONS.Error);
            return;
        }

        if (!Array.isArray(data.items)) {
            Vue.$toast.error(`Ошибка получения групп компаний: data.items is not array`, TOAST_OPTIONS.Error);
            return;
        }

        Object.values(PAGE_NAMES).forEach((pageName) => {
          if (pageName.startsWith('app')) {
            commit(
              'filterState/TOGGLE_GROUP_DISABLED',
              {
                pageName,
                groupName: 'companyGroups',
                disabled: !data.allowCompanyGroup,
              },
              {root: true}
            );
          }
        });

        commit(
          'filterState/SET_FILTER_OPTIONS',
          {
            name: 'companyGroups',
            options: data.items.map(({id, name}: ICompanyGroup) => ({value: id, label: name})),
          },
          {root: true}
        );
      }
    })
    .finally(() => {
      app?.$modal.hide("loader");
      if (errorMessage) {
        Vue.$toast.error("Ошибка" + errorMessage, TOAST_OPTIONS.Error);
      }
    });
  },

///////////////////////////////////////////////////////
// Save to CSV file
async getReport({ state, getters, rootGetters }) {
  const queryParams = rootGetters["filterState/getSearchString"]("app")
      + rootGetters["filterState/getFilterString"](getters["getPageName"]);
  try {
    const { status, data, headers } 
    = await fetch.get( `api/InitialApplication/report${queryParams}`, { responseType: "blob" } );

    if (status === 200) 
    {
      var d = new Date(Date.now());
      var fName = "Список заявок " + d.toLocaleDateString() + " " + d.toLocaleTimeString() + ".csv";

      try
      {
         fName = decodeURI(headers["content-disposition"].match(/filename=(.*);/)[1]);
      }
      catch{}

      download(
        data,
        fName,
        headers["content-type"],
      );
    }
  } catch (err) {
    throw err;
  }
},

  async uploadXmlWithApps({}, {file, fileName}: {file: File; fileName: string;}) {
    let base64;
    try {
      base64 = await fileToBase64(file);
      if (!base64) throw new Error('base64 is empty');
    } catch (err) {
      Vue.$toast.error(`Не удалось преобразовать xml в base64: ${JSON.stringify(err)}`);
      return;
    }

    const {data, error} = await api.uploadXmlWithApps({
      base64: (base64 as string).replace(/^.+base64,/, ""),
    });

    if (error || !data) {
      Vue.$toast.error(`Не удалось загрузить xml: ${error || 'нет данных'}`, TOAST_OPTIONS.Error);
      return null;
    }

    if (data.hasOwnProperty('errorsBase64') && data.errorsBase64) {
      try {
        const {fileJS} = base64ToFile(fileName, 'text/csv', data.errorsBase64);
        download(
          fileJS,
          `${fileName.replace(extname(fileJS.name), '')}.csv`,
          'text/csv',
        );
      } catch (err) {
        Vue.$toast.error(`Не удалось преобразовать base64 в csv: ${JSON.stringify(err)}`, TOAST_OPTIONS.Error);
        return null;
      }
    }
    
    return {
      notValidNumbers: data.notValidNumbers,
      succeedOrderIds: Object.values(data.succeedNumbers),
      failedNumbers: Object.keys(data.failedNumbers),
    };
  },
};
