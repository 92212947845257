import {GetterTree} from "vuex";
import moment from "moment";
import {isEmpty} from "lodash";
import {crypto} from "./index";

import {IApplicationState, TControlName, TTypeEntre, IFieldUpload, IDocInfo} from "./application.interfaces";
import {RootState} from "@/store/root.interface";
import {
    ANOTHER_UPLOADS_MAX_COUNT,
    DOWNLOAD_DOCUMENTS,
    STATUSES,
    PAYER_TYPES,
    APPLICANT_TYPES,
    SKZI_STATE_IDS,
} from "./application.constants";
import { DEFAULT_ANOTHER_UPLOAD } from "./application.defaults";
import { SHOW_FILIAL_CLIENT_LINK, IDENTIFICATION_KINDS } from "@/constants";
import { InitialFileTypes } from "@/constants/InitialFileTypes";
import { ORGANIZATION_TYPE_IDS } from "@/constants/organization";

const typeEntreLabel = (typeEntreValue: TTypeEntre) => {
    if (!Object.keys(APPLICANT_TYPES).includes(typeEntreValue)) {
        return "";
    }
    return APPLICANT_TYPES[typeEntreValue].label;
};

export const getters: GetterTree<IApplicationState, RootState> = {
    getVisibleBodyForm(state) {
        return !isEmpty(state.formControls.typeEntre.value);
    },

    getDocumentsToUpload(state: any) {
        const uploads = state.uploadFormControls;
        const addovert = state.formControls.addovert;
        const needToAttachRevoke = state.needToAttachRevoke;
        const identifikationKind = state.formControls.identificationKind;
        const isCurrentSign = state.formControls["identificationKind"].value === (IDENTIFICATION_KINDS.CurrentSign.value + 1); // костылёк с +1, убрать при возможности
        const result: any[] = [];
        Object.keys(uploads).forEach(name => {
            if (
                !([
                    "application",
                    "applicationSignature",
                    "passport",
                    "foto",
                    "contract",
                    "proxy",
                    "revoke",
                    "revokeDetachedSign",
                ].includes(name) && state.isDocumentsCollected) &&
                !uploads[name].isCollected &&
                (![
                    "contract",
                    "revoke",
                    "revokeDetachedSign",
                    "certificateSigned",
                    "securityInformationSigned",
                    "extra",
                    "applicationSignature",
                    "printingFormSignature"
                ].includes(name) ||
                (name === "revoke" && needToAttachRevoke) ||
                (name === "revokeDetachedSign" && needToAttachRevoke && +identifikationKind.value === 2) ||
                (name === "contract" && +addovert.value === 0) ||
                (name === "applicationSignature" && +identifikationKind.value === 2) ||
                ((name === "certificateSigned" || name === "securityInformationSigned" || name === "printingFormSignature") &&
                [STATUSES.CertificateReady.id, STATUSES.WaitingForOriginals.id, STATUSES.OriginalsIncorrect.id].includes(state.statusId))) &&
                (name !== "printingFormSignature")
            ) {
                result.push({...uploads[name], name});
            }
        });
        return result;
    },

    getCollectedDocuments(state: any) {
        const uploads = state.uploadFormControls;
        const addovert = state.formControls.addovert;
        const needToAttachRevoke = state.needToAttachRevoke;
        const identifikationKind = state.formControls.identificationKind;
        const sigCheckStatus = state.sigCheckStatus;
        const sigIssuerCN = state.sigIssuerCN;
        const revocSigCheckStatus = state.revocSigCheckStatus;
        const revocSigIssuerCN = state.revocSigIssuerCN;
        const result: any[] = [];
        Object.keys(uploads).forEach(name => {
            if (
                uploads[name].isCollected &&
                (!["contract", "revoke", "revokeDetachedSign", "applicationSignature"].includes(name) ||
                (name === "revoke" && needToAttachRevoke) ||
                (name === "revokeDetachedSign" && needToAttachRevoke && +identifikationKind.value === 2) ||
                (name === "contract" && +addovert.value === 0) ||
                (name === "applicationSignature" && +identifikationKind.value === 2))
            ) {
                if(identifikationKind.value === 2 && ["application", "applicationSignature", "revokeDetachedSign"].includes(name)){
                    if(name === "application" && !uploads["applicationSignature"].isCollected || name === "applicationSignature"){
                        let fileItem: IFieldUpload = {...uploads[name]};
                        fileItem.descr = sigCheckStatus+", издатель: "+sigIssuerCN;
                        result.push({...fileItem,name,});
                    } else if (name === "revokeDetachedSign") {
                        let fileItem: IFieldUpload = {...uploads[name]};
                        fileItem.descr = revocSigCheckStatus+", издатель: "+revocSigIssuerCN;
                        result.push({...fileItem, name});
                    } else {
                        result.push({...uploads[name], name,});    
                    }
                } else {
                    result.push({...uploads[name], name,});
                }
            }
        });
        return result;
    },

    getAnotherDocuments(state) {
        const uploads = state.anotherUploads;
        const result: any[] = [];
        let anotherUploadsCount: number = 0;
        Object.keys(uploads).forEach(name => {
            if (uploads[name].isTouched) {
                result.push({...uploads[name], name});
                anotherUploadsCount++;
            }
        })
        if (!result.find((item: any) => !item.isTouched) &&
            anotherUploadsCount < ANOTHER_UPLOADS_MAX_COUNT)
        {
            result.push({...DEFAULT_ANOTHER_UPLOAD, name: `another${anotherUploadsCount}`});
        }
        return result;
    },

    getUploadTabDescription(state: any) {
        if((state.filialClientFlags & 0x0001)==0x0001) {
            return "Клиент прислал подписанные документы. Для завершения процесса выпуска ЭП необходимо прикрепить отсканированные копии остальных документов."
        } else {
            return "Для завершения процесса выпуска ЭП необходимо прикрепить отсканированные копии следующих документов.";
        }
    },

    getApplicationInfo(state: any) {
        return {
            status: state.status,
            statusId: state.statusId,
            orderId: state.orderId,
            creationDate: moment(state.creationDate)
                .locale("ru")
                .format("LLL"),
            changeDate: moment(state.changeDate)
                .locale("ru")
                .format("LLL"),
            certificateIssueDate: state.certificateIssueDate
                ? moment(state.certificateIssueDate).locale("ru").format("LLL")
                : null,
            typeEntre: typeEntreLabel(state.formControls.typeEntre.value),
            paymentStatusDescription: state.paymentStatusDescription,
            lastPaymentDate: state.lastPaymentDate ?
                ' ' + moment(state.lastPaymentDate)
                    .locale("ru")
                    .format("DD.MM.YYYY HH:mm")
                : null,
        };
    },

    getBrowserPluginEnabled() {
        return crypto.browserPluginEnabled;
    },

    getApplicationEmail(state: any) {
        return state.formControls.email.value;
    },

    getIsSystemProcessing(state) {
        return state.statusId === STATUSES.SystemProcessing.id;
    },
    
    getIsFormValid(state) {
        let isFormValid = true;
        Object.keys(state.formControls).forEach(controlName => {
            const control: any = state.formControls[controlName as TControlName];
            if (control.hasOwnProperty("isValid") && control.active) {
                isFormValid = control.isValid && isFormValid;
            }
        });
        return isFormValid;
    },

    getFnsExistingCerts(state) {
        const certs = state.fnsExistingCerts.map(((cert: any) => ({
            ...cert, issued: cert.issued.replace(/\s\d\d:\d\d:\d\d/, "")
        })))
        return certs;
    },

    getDownloadDocuments(state) {
        return state.filesDownload
        .filter(({typeId}: any) => typeId !== InitialFileTypes.Procuration)
        .map((file: any) => {
            for (const doc of DOWNLOAD_DOCUMENTS) {
                if (doc.typeId === file.typeId) {
                    const { descr, ...download } = doc;
                    return {
                        ...download,
                        ...file,
                        descr: descr
                            .replace("%%", file.orderId)
                            .replace("%1%", file.fileMarker)
                    };
                }
            }
        });
    },

    getIsRevokeUploaded(state) {
        return state.uploadFormControls["revoke"].files
            && !!state.uploadFormControls["revoke"].files.length
            && state.uploadFormControls["revoke"].isValid;
    },

    getApplicationStatuses() {
        return STATUSES;
    },

    getPayerTypeOptions() {
        return Object.values(PAYER_TYPES);
    },

    getIsFilledByOGRN(state) {
        return state.isFilledByOGRN;
    },

    getIsReadonlyKeys(state) {
        return !isEmpty(state.fieldsReadonlyKeys);
    },

    getAddressFNS(state) {
        return state.fieldsFromBasisApi.addressFNS;
    },

    getIsDocumentsReadyToUpload(state, getters) {
        let result = true;
        const addovert = state.formControls.addovert;
        const needToAttachRevoke = state.needToAttachRevoke;
        const identifikationKind = state.formControls.identificationKind;
        if (getters.getDocumentsToUpload.length === 1 && !getters.getDocumentsToUpload[0].isTouched) {
            return false;
        }
        getters.getDocumentsToUpload.forEach((item: any) => {
            if (
                (item.required && !item.isTouched) ||
                (item.isTouched &&
                (!["contract", "revoke", "revokeDetachedSign", "applicationSignature"].includes(item.name)
                || (item.name === "revoke" && needToAttachRevoke)
                || (item.name === "revokeDetachedSign" && needToAttachRevoke && +identifikationKind.value === 2)
                || (item.name === "contract" && +addovert.value === 0)
                || (item.name === "applicationSignature" && +identifikationKind.value === 2))
                && (!item.isValid || !!item.errors.length))
            ) {
                result = false; 
            }
        })
        return result;
    },

    getTypeEntreValue(state) {
        return state.formControls.typeEntre.value;
    },

    getShowFilialClientLink() {
        return SHOW_FILIAL_CLIENT_LINK==="true"
    }, 

    getGenderControl(state) {
        return state.formControls.gender;
    },

    companyGroupOptions(state) {
        return state.companyGroups.map(({ name, inn }) => ({ value: inn, label: name }));
    },

    showLicenseRequestButton(state) {
        if (!state.skziState || !(state.skziState.state in SKZI_STATE_IDS)) {
            return false;
        }

        return [
            SKZI_STATE_IDS.HasUnlimitedLicenseProduct,
            SKZI_STATE_IDS.LicenseReady,
            SKZI_STATE_IDS.LicenseRequested,
            SKZI_STATE_IDS.UpdStatusSuccess,
            SKZI_STATE_IDS.UpdStatusUnknown,
        ].includes(state.skziState.state);
    },

    enableLicenseRequestButton(state) {
        if (!state.skziState?.state) return true;
        return state.skziState.state === SKZI_STATE_IDS.UpdStatusSuccess;
    },

    getSkziStatus(state) {
        if (!state.skziState || !(state.skziState.state in SKZI_STATE_IDS)) {
            return 'Статус СКЗИ не определён';
        }

        if (state.skziState.state === SKZI_STATE_IDS.LicenseReady && !state.skziState.license?.id) {
            return 'Файл лицензии формируется. Обновите страницу';
        }

        if ((state.certificate as IDocInfo[]).filter(({ licenseType }) => licenseType != null).length !== 0) {
            return 'Лицензия была сформирована вручную';
        }

        return {
            [SKZI_STATE_IDS.HasNotUnlimitedLicenseProduct]: 'В заявке нет продуктов с бессрочной лицензией',
            [SKZI_STATE_IDS.HasUnlimitedLicenseProduct]: 'Заявка не готова для формирования лицензии',
            [SKZI_STATE_IDS.UpdStatusUnknown]: 'УПД не готов, нельзя формировать лицензию',
            [SKZI_STATE_IDS.UpdStatusSuccess]: 'УПД готов, можно формировать лицензию',
            [SKZI_STATE_IDS.LicenseRequested]: 'Лицензия на формировании',
            [SKZI_STATE_IDS.LicenseReady]: 'Лицензия сформирована',
        }[state.skziState.state];
    },

    /*
        если заявка с идентификацией "Лично" и тарифом ФНС,
        нужно приложить подпись файла запроса
    */
    needSignCertRequest(state, getters, rootState, rootGetters) {
        return (
            rootGetters["productsState/getHasTariffFNS"] && 
            state.formControls["identificationKind"].value === (IDENTIFICATION_KINDS.Personaly.value + 1)
        );
    },

    getPayerSectionShown(state, getters, rootState) {
        const organizationTypeId = rootState.filialInfo?.organizationTypeId;
        return organizationTypeId === ORGANIZATION_TYPE_IDS.Agent;
    },
};
