export enum ORGANIZATION_TYPE_IDS {
  Agent = 1,
  Partner = 2,
  Tenzor = 3,
  Contour = 4,
  ITCom = 5,
  Kaluga = 6,
  Takscom = 7,
  ElectronicExpress = 8,
  Corporate = 9,
  Unknown = 10,
}

export const ORGANIZATION_TYPES = {
  [ORGANIZATION_TYPE_IDS.Agent]: 'Агентский',
  [ORGANIZATION_TYPE_IDS.Partner]: 'Партнёрский',
  [ORGANIZATION_TYPE_IDS.Tenzor]: 'Тензор',
  [ORGANIZATION_TYPE_IDS.Contour]: 'Контур',
  [ORGANIZATION_TYPE_IDS.ITCom]: 'Айтиком',
  [ORGANIZATION_TYPE_IDS.Kaluga]: 'Калуга',
  [ORGANIZATION_TYPE_IDS.Takscom]: 'Такском',
  [ORGANIZATION_TYPE_IDS.ElectronicExpress]: 'Электронный экспресс',
  [ORGANIZATION_TYPE_IDS.Corporate]: 'Корпоративный',
  [ORGANIZATION_TYPE_IDS.Unknown]: 'Неопределённый',
};
