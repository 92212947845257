import Vue from "vue";
import { fetch } from "@/services/api";
import { isObject } from "lodash";
import { ActionTree } from "vuex";

import { RootState } from "@/store/root.interface";
import { IProductsState, IProduct, ISubitem } from "./products.interfaces";

import { TOAST_OPTIONS } from "@/constants";

import {
  SET_PRODUCTS,
  SET_PRODUCTS_SELECTED,
  REMOVE_PRODUCTS_SELECTED,
  RESET_PRODUCT
} from "./products.mutations";

export const actions: ActionTree<IProductsState, RootState> = {
  async fetchProducts({ state, dispatch }, {type}: {type: "app" | "preapp"}) {
    if (!state.products.length) {
      try {
        const url = type === "app"
          ? "api/initialApplication/getProducts"
          : "api/PreApplication/getProducts";
        const res = await fetch.post(url);

        if (res.status === 200 && res.data && res.data.products) {
          dispatch("initProducts", {products: res.data.products}, {root: true});
          dispatch("verificationRequiredProducts");
        } else {
          const message = res.data && res.data.error
            ? `Ошибка при загрузке списка продуктов. ${res.data.error}`
            : "Неизвестная ошибка при загрузке списка продуктов";
          throw new Error(message);
        }
      } catch(err) {
        const error = (err as any);
        let message = error.data && error.data.mesage
          ? `Ошибка при загрузке списка продуктов. ${error.data.mesage}`
          : "Неизвестная ошибка при загрузке списка продуктов";
        Vue.$toast.error(message, TOAST_OPTIONS.Error);
      }
    }
  },

  initProducts: {
    root: true,

    handler: (
      { commit },
      {
        products,
        productsSelected = []
      }: {
        products: IProduct[];
        productsSelected: Array<{
          id: number;
          subitems?: number[];
          price: number;
        }>;
      }
    ) => {
      commit(RESET_PRODUCT);

      productsSelected.forEach(({ id, subitems }) => {
        commit(SET_PRODUCTS_SELECTED, id);
        if (subitems && subitems.length) {
          subitems.forEach(subitemID => {
            commit(SET_PRODUCTS_SELECTED, subitemID);
          });
        }
      });

      commit(SET_PRODUCTS, products);
    }
  },

  getProductsSelected: {
    root: true,

    handler: ({ state }) => {
      const result = new Set();

      state.products.forEach(({ id, subitems }) => {
        if (state.productsSelected.includes(id)) {
          result.add(id);

          if (subitems?.length) {
            subitems.forEach(({ id }) => {
              if (state.productsSelected.includes(id)) {
                result.add(id);
              }
            });
          }
        }
      });

      return [...result];
    }
  },

  getOIDS: {
    root: true,

    handler: ({ state }) => {
      const findOIDS = (
        productsSelected: number[],
        target: Array<IProduct | ISubitem>
      ): string[] => {
        let result: string[] = [];

        productsSelected.forEach(id => {
          const prod: IProduct | ISubitem | undefined = target.find(
            product => product.id === id
          );

          if (prod && prod.oids && prod.oids.length) {
            result = result.concat(
              prod.oids.map(o => (isObject(o) ? o.oid : o))
            );
          } else if (prod && prod.subitems) {
            result = result.concat(findOIDS(productsSelected, prod.subitems));
          }
        });

        return result;
      };

      return [...new Set(findOIDS(state.productsSelected, state.products))];
    }
  },

  verificationRequiredProducts({ state, commit }) {
    state.products.forEach(({ required, id }: IProduct) => {
      if (required && !state.productsSelected.includes(id)) {
        commit(SET_PRODUCTS_SELECTED, id);
      }
    });
  },

  toggleProductsSelected({ commit }, { id, checked }) {
    if (checked) {
      commit(SET_PRODUCTS_SELECTED, id);
    } else {
      commit(REMOVE_PRODUCTS_SELECTED, id);
    }
  },

  resetProducts({ commit }) {
    commit(RESET_PRODUCT);
  },

  removeProductById({commit}, id) {
    commit(REMOVE_PRODUCTS_SELECTED, id);
  }
};
