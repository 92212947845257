









import Vue from "vue";
import { mapActions } from 'vuex';
import { getRole, getNameId } from '@/services/api/fetch';
import ROLES from "@/config/roles";
import { ORGANIZATION_TYPE_IDS } from '@/constants/organization';

export default Vue.extend({
  name: 'PageLayout',

  methods: {
    ...mapActions('revokeListState', ['getFilialByManager']),
  },

  computed: {
    isRevokesVisible() {
      const role = getRole();
      return role in ROLES && [ROLES.ChiefManager, ROLES.Manager].includes(role as ROLES);
    },
  },

  async beforeMount() {
    if (
      this.$route.path !== '/login' &&
      this.$route.path !== '/about' &&
      this.$store.state.filialInfo === null
    ) {
      const managerId = getNameId();
      const filialInfo = await this.getFilialByManager(managerId);
      this.$store.commit('SET_FILIAL_INFO', filialInfo);
      const isRevokesAllowed = filialInfo?.options.allowedRevocation ?? false;
      this.$store.commit(`revokeListState/SET_IS_REVOKES_ALLOWED`, isRevokesAllowed && this.isRevokesVisible);
    }
  },
})
