const BITRIX_CHAT_ID = 'bitrix_chat';

export const injectBitrixScriptOrShowButton = () => {
    hideBitrixButton();

    if (window.BX) {
        window.BX.SiteButton.show();
    } else {
        const scriptElement = document.createElement('script');
        scriptElement.id = BITRIX_CHAT_ID;
        scriptElement.async = true;
        scriptElement.src = 'https://btrx.iecp.ru/upload/crm/site_button/loader_2_rhnli1.js' + '?' + (Date.now() / 60000 | 0);
        const headerScripts = document.getElementsByTagName('script')[0];
        headerScripts?.parentNode?.insertBefore(scriptElement, headerScripts);
    }
}

export const hideBitrixButton = () => {
    window.BX?.SiteButton.hide();
}