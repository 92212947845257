import { GetterTree } from "vuex";
import { isEmpty } from "lodash";

import { IPreApplicationList } from "./preapplication-list.interfaces";
import { RootState } from "../../root.interface";
import { APP_PAYMENT_STAUSES } from "./preapplication-list.constants";

const getAppPaymentStatus = (val: number) => {
  const status = Object.values(APP_PAYMENT_STAUSES).find(s => s.value === val);
  return status ? ", "+status.label : "";
};

export const getters: GetterTree<IPreApplicationList, RootState> = {
  getPreApplicationList(state) {
    return state.preapplicationsList.map((el: any) => {
      return [
        {id: el.id || ""},
        el.creationDate || "",
        { 
          status: el.identificationStatus || el.status || "",
          comment: isEmpty(el.managerComment) ? null : el.managerComment,
          applicationId: el.applicationId,
          paymentStatus: el.identificationStatus ? "" : el.payment,
          appStatus: el.initialApplicationStatus
            ?`(${el.initialApplicationStatus}${getAppPaymentStatus(el.initialApplicationPaymentStatus)})`
            : "",
        },
        {
          organizationShortName: el.organizationShortName || "",
          fnsStatusId: el.fnsStatusId || 0
        },
        `${el.surname || ""} ${el.name || ""} ${el.middleName || ""}`,
        el.applicantType || "",
        el.phoneNumber || "",
        el.order || "",
        el.insuranceType ? 'Да' : 'Нет',
        el.totalPrice=="-1" ? "нет доступных продуктов":`<b>${!el.totalPrice && el.totalPrice !== 0 ? "" : el.totalPrice} ₽</b>`
      ];
    });
  },

  newPreAppNum(state) {
    return state.newPreAppNum;
  },
};
