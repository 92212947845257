import { MutationTree } from "vuex";

import {validation} from "@/services";

import {
  DEFAULT_FORM_CONTROLS,
  DEFAULT_UPLOAD_FORM_CONTROLS
} from "./application.defaults";
import { DEFAULT_ANOTHER_UPLOADS } from "./application.defaults";
import { IApplicationState, TFieldUploadName, TPayerType, TTypeEntre, IFieldsFromBasisApi, ICompanyGroup } from "./application.interfaces";
import { PAYER_TYPES, IDENTIFICATION_DOCUMENT_TYPES, APPLICANT_TYPES } from "./application.constants";

export const SET_OPTIONS_COUNTRY = "SET_OPTIONS_COUNTRY";
export const SET_OPTIONS_REGION = "SET_OPTIONS_REGION";
export const SET_OPTIONS_IDENTIFICATION_DOCUMENT_TYPES = "SET_OPTIONS_IDENTIFICATION_DOCUMENT_TYPES";
export const CLEAR_FORM_CONTROLS = "CLEAR_FORM_CONTROLS";
export const CLEAR_REGIONS = "CLEAR_REGIONS";
export const SET_FORM_CONTROL = "SET_FORM_CONTROL";
export const SET_IS_FORM_VALID = "SET_IS_FORM_VALID";
export const SET_DISABLED = "SET_DISABLED";
export const SET_FILES_DOWNLOAD = "SET_FILES_DOWNLOAD";
export const SET_DOCUMENT_DOWNLOAD = "SET_DOCUMENT_DOWNLOAD";
export const SET_TYPE_SELECTION = "SET_TYPE_SELECTION";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const NEXT_ACTIVE_TAB = "NEXT_ACTIVE_TAB";
export const SET_FORM_CONTROLS_FILE = "SET_FORM_CONTROLS_FILE";
export const SET_CERT_REQUEST_FILE = "SET_CERT_REQUEST_FILE";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_IS_EDIT = "SET_IS_EDIT";
export const SET_IS_EDIT_MANAGERCOMMENT = "SET_IS_EDIT_MANAGERCOMMENT";
export const SET_IS_MANAGERCOMMENT_EDITABLE = "SET_IS_MANAGERCOMMENT_EDITABLE";
export const SET_IS_SUBMITED = "SET_IS_SUBMITED";
export const SET_IS_SUBMITED_MANAGERCOMMENT = "SET_IS_SUBMITED_MANAGERCOMMENT";
export const SET_STATUS = "SET_STATUS";
export const SET_CREATION_DATE = "SET_CREATION_DATE";
export const SET_CHANGE_DATE = "SET_CHANGE_DATE";
export const CLEAR_UPLOAD_FORM_CONTROLS = "CLEAR_UPLOAD_FORM_CONTROLS";
export const SET_IS_EDIT_UPLOAD = "SET_IS_EDIT_UPLOAD";
export const SET_IS_DOCUMENTS_COLLECTED = "SET_IS_DOCUMENTS_COLLECTED";
export const SET_SIG_CHECK_STATUS = "SET_SIG_CHECK_STATUS";
export const SET_SIG_ISSUER_CN = "SET_SIG_ISSUER_CN";
export const SET_REVOC_SIG_CHECK_STATUS = "SET_REVOC_SIG_CHECK_STATUS";
export const SET_REVOC_SIG_ISSUER_CN = "SET_REVOC_SIG_ISSUER_CN";
export const SET_IS_CERTIFICATE_REQUESTED = "SET_IS_CERTIFICATE_REQUESTED";
export const SET_CURRENT_CERT_THUMBPRINT = "SET_CURRENT_CERT_THUMBPRINT";
export const SET_IS_UPLOAD_FORM_LOADED = "SET_IS_UPLOAD_FORM_LOADED";
export const SET_CERT_IS_VALID = "SET_CERT_IS_VALID";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_CERTIFICATE = "SET_CERTIFICATE";
export const SET_COMMENT = "SET_COMMENT";
export const SET_MANAGERCOMMENT = "SET_MANAGERCOMMENT";
export const SET_STATUS_ID = "SET_STATUS_ID";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_READONLY_FIELDS_KEY = "SET_READONLY_FIELDS_KEY";
export const RESET_READONLY_FIELDS_KEY = "RESET_READONLY_FIELDS_KEY";
export const SET_READONLY_FIELDS_KEY_ALL = "SET_READONLY_FIELDS_KEY_ALL";
export const SET_EXTERNAL_SOURCE_PKCS_10 = "SET_EXTERNAL_SOURCE_PKCS_10";
export const SET_ARCHIVED = "SET_ARCHIVED";
export const SET_MANAGER_ID = "SET_MANAGER_ID";
export const SET_PAYMENT_STATUS_ID = "SET_PAYMENT_STATUS_ID";
export const SET_PAYMENT_STATUS_DESCRIPTION = "SET_PAYMENT_STATUS_DESCRIPTION";
export const SET_LAST_PAYMENT_DATE = "SET_LAST_PAYMENT_DATE";
export const SET_UPD_REQUESTED = "SET_UPD_REQUESTED";
export const SET_SEND_UPD_TO_EDM = "SET_SEND_UPD_TO_EDM";
export const SET_UPD_STATUS = "SET_UPD_STATUS";
export const SET_UPD_STATUS_ID = "SET_UPD_STATUS_ID";
export const SET_UPD_DOCUMENT = "SET_UPD_DOCUMENT";
export const SET_HIDE_UTD = "SET_HIDE_UTD";
export const SET_ORG_SURNAME = "SET_ORG_SURNAME";
export const SET_ORG_NAME = "SET_ORG_NAME";
export const SET_ORG_PATRONYMIC = "SET_ORG_PATRONYMIC";
export const SET_POSITION_DIRECTOR = "SET_POSITION_DIRECTOR";
export const SET_DIRECTOR = "SET_DIRECTOR";
export const SET_ORG_SURNAME_DISABLED = "SET_ORG_SURNAME_DISABLED";
export const SET_ORG_NAME_DISABLED = "SET_ORG_NAME_DISABLED";
export const SET_ORG_PATRONYMIC_DISABLED = "SET_ORG_PATRONYMIC_DISABLED";
export const SET_POSITION_DIRECTOR_DISABLED = "SET_POSITION_DIRECTOR_DISABLED";
export const SET_IS_KKMRECEIPT_LAUNCHABLE = "SET_IS_KKMRECEIPT_LAUNCHABLE";
export const SET_IS_FNS_ISSUE_AVAILABLE = "SET_IS_FNS_ISSUE_AVAILABLE";
export const SET_PRIVATEKEY_EXPORT_POLICY = "SET_PRIVATEKEY_EXPORT_POLICY";
export const SET_CHECK_CERT_IN_FNS_MESSAGE = "SET_CHECK_CERT_IN_FNS_MESSAGE";
export const SET_NEED_TO_ATTACH_REVOKE = "SET_NEED_TO_ATTACH_REVOKE";
export const SET_SCANS_FROM_PREAPP = "SET_SCANS_FROM_PREAPP";
export const SET_SMEV_CHECKS = "SET_SMEV_CHECKS";
export const SET_DOCUMENT_UPLOAD_REQUIRED = "SET_DOCUMENT_UPLOAD_REQUIRED";
export const SET_EDM_TYPES = "SET_EDM_TYPES";
export const SET_EDM_TYPE_ID = "SET_EDM_TYPE_ID";
export const SET_ANOTHER_UPLOAD = "SET_ANOTHER_UPLOAD";
export const SET_FNS_EXISTING_CERTS = "SET_FNS_EXISTING_CERTS";
export const CLEAN_FNS_EXISTING_CERTS = "CLEAN_FNS_EXISTING_CERTS";
export const SET_FIELDS_FROM_BASIS_API = "SET_FIELDS_FROM_BASIS_API";
export const SET_NEW_CERT_REQUEST = "SET_NEW_CERT_REQUEST";
export const SET_SIGNED_CERT_REQUEST = "SET_SIGNED_CERT_REQUEST";
export const CLEAR_NEW_CERT_REQUEST = "CLEAR_NEW_CERT_REQUEST";
export const CLEAR_FILIAL_CLIENT_CERT_REQUEST = "CLEAR_FILIAL_CLIENT_CERT_REQUEST";
export const CLEAR_SIGNED_CERT_REQUEST = "CLEAR_SIGNED_CERT_REQUEST";
export const SET_NEW_CERT_REVOKE = "SET_NEW_CERT_REVOKE";
export const SET_SIGNED_CERT_REVOKE = "SET_SIGNED_CERT_REVOKE";
export const CLEAR_NEW_CERT_REVOKE = "CLEAR_NEW_CERT_REVOKE";
export const CLEAR_SIGNED_CERT_REVOKE = "CLEAR_SIGNED_CERT_REVOKE";
export const DEFINE_FIELDS_ACTIVE = "DEFINE_FIELDS_ACTIVE";
export const DEFINE_PAYER_FIELDS_ACTIVE = "DEFINE_PAYER_FIELDS_ACTIVE";
export const DEFINE_IDENTIFICATION_DOCUMENT_FIELDS_ACTIVE = "DEFINE_IDENTIFICATION_DOCUMENT_FIELDS_ACTIVE";
export const TOGGLE_IS_FILLED_BY_ORGN = "TOGGLE_IS_FILLED_BY_ORGN";
export const SET_REQUEST_GUID = "SET_REQUEST_GUID";
export const CLEAR_FIELD_VALUE = "CLEAR_FIELD_VALUE";
export const SET_IS_PRESET_CONTAINER = "SET_IS_PRESET_CONTAINER";
export const SET_FILIAL_CLIENT_FLAGS = "SET_FILIAL_CLIENT_FLAGS";
export const SET_FILIAL_CLIENT_LINK = "SET_FILIAL_CLIENT_LINK";
export const SET_FILIAL_CLIENT_CERT_REQUEST = "SET_FILIAL_CLIENT_CERT_REQUEST";
export const SET_IS_PDN_USING_PROHIBITED = "SET_IS_PDN_USING_PROHIBITED";
export const SET_COMPANY_GROUPS = "SET_COMPANY_GROUPS";
export const SET_ALLOW_COMPANY_GROUP = "SET_ALLOW_COMPANY_GROUP";
export const DEFINE_FIELDS_REQUIRED = "DEFINE_FIELDS_REQUIRED";
export const DEFINE_ADDRESS_FIELDS = "DEFINE_ADDRESS_FIELDS";
export const SET_SKZI_STATE = "SET_SKZI_STATE";
export const SET_CERTIFICATE_ISSUE_DATE = "SET_CERTIFICATE_ISSUE_DATE";
export const SET_PASSPORT_DIVISION = "SET_PASSPORT_DIVISION";
export const SET_OPTIONS_PASSPORT_DIVISIONS = "SET_OPTIONS_PASSPORT_DIVISIONS";
export const CLEAR_OPTIONS_PASSPORT_DIVISIONS = "CLEAR_OPTIONS_PASSPORT_DIVISIONS";

export const mutations: MutationTree<IApplicationState> = {
  [SET_ORDER_ID](state, val: number | null) {
    state.orderId = val;
  },
  [SET_OPTIONS_COUNTRY](state, country: []) {
    state.optionsCountry = [...country];
  },
  [SET_READONLY_FIELDS_KEY](state, key: string) {
    const result = new Set(state.fieldsReadonlyKeys);

    result.add(key);
    state.fieldsReadonlyKeys = [...result];
  },
  [SET_READONLY_FIELDS_KEY_ALL](state, keys: string[]) {
    state.fieldsReadonlyKeys = [...state.fieldsReadonlyKeys, ...keys];
  },
  [RESET_READONLY_FIELDS_KEY](state) {
    state.fieldsReadonlyKeys = [];
  },
  [SET_OPTIONS_REGION](state, region: []) {
    state.optionsRegion = [...region];
  },
  [SET_OPTIONS_IDENTIFICATION_DOCUMENT_TYPES](state, identificationDocumentTypes: []) {
    state.optionsIdentificationDocumentTypes = [...identificationDocumentTypes];
  },
  [SET_OPTIONS_PASSPORT_DIVISIONS](state, passportDivisions: []) {
    state.optionsPassportDivisions = [
      {label: "Ввести вручную", value: null}, 
      ...passportDivisions
    ];
  },
  [SET_ERROR_MESSAGE](state, val: string | null | string[]) {
    state.errorMessage = val;
  },
  [SET_FORM_CONTROL](state: any, { control, name }: any) {
    state.formControls[name] = { ...control };
  },
  [SET_FORM_CONTROLS_FILE](state: any, { control, name }: any) {
    state.uploadFormControls[name] = { ...control };
  },
  [SET_ANOTHER_UPLOAD](state: any, { control, name }: any) {
    state.anotherUploads[name] = { ...control };
  },
  [SET_CERT_REQUEST_FILE](state, val: string) {
    state.certRequest = val;
  },
  [SET_IS_DOCUMENTS_COLLECTED](state, val: boolean) {
    state.isDocumentsCollected = val;
  },
  [SET_SIG_CHECK_STATUS](state, val: string) {
    state.sigCheckStatus = val;
  },
  [SET_SIG_ISSUER_CN](state, val: string) {
    state.sigIssuerCN = val;
  },
  [SET_REVOC_SIG_CHECK_STATUS](state, val: string) {
    state.revocSigCheckStatus = val;
  },
  [SET_REVOC_SIG_ISSUER_CN](state, val: string) {
    state.revocSigIssuerCN = val;
  },
  [SET_FILIAL_CLIENT_FLAGS](state,val: number){
    state.filialClientFlags = val;
  },
  [SET_IS_CERTIFICATE_REQUESTED](state, val: boolean) {
    state.isCertificateRequested = val;
  },
  [SET_IS_FORM_VALID](state, val: boolean) {
    state.isFormValid = val;
  },
  [SET_ACTIVE_TAB](state, num: number) {
    state.activeTab = num;
  },
  [SET_IS_UPLOAD_FORM_LOADED](state, val: boolean) {
    state.isUploadFormLoaded = val;
  },
  [SET_IS_EDIT](state, val: boolean | null) {
    state.isEdit = val;
  },
  [SET_IS_EDIT_MANAGERCOMMENT](state, val: boolean | null) {
    state.isEditManagerComment = val;
  },
  [SET_IS_EDIT_UPLOAD](state, val: boolean | null) {
    state.isEditUpload = val;
  },
  [SET_IS_SUBMITED](state, val: boolean | null){
    state.isSubmited=val;
  },
  [SET_IS_SUBMITED_MANAGERCOMMENT](state, val: boolean | null){
    state.isSubmitedManagerComment=val;
  },
  [SET_CREATION_DATE](state, date: Date | string | null) {
    state.creationDate = date;
  },
  [SET_CHANGE_DATE](state, date: Date | string | null) {
    state.changeDate = date;
  },
  [SET_CURRENT_CERT_THUMBPRINT](state, val: string | null) {
    state.currentCertThumbprint = val;
  },
  [SET_CERT_IS_VALID](state, val: boolean) {
    state.certIsValid = val;
  },
  [SET_ARCHIVED](state, val: boolean) {
    state.archived = val;
  },
  [SET_EXTERNAL_SOURCE_PKCS_10](state, val: boolean) {
    state.externalSourcePkcs10 = val;
  },
  [SET_COMMENT](state, val: string | null) {
    state.comment = val;
  },
  [SET_MANAGERCOMMENT](state, val: string | null) {
    state.managerComment = val;
  },
  [SET_MANAGER_ID](state, payload: number | null) {
    state.managerId = payload;
  },
  [NEXT_ACTIVE_TAB](state) {
    const num = state.activeTab + 1;

    if (num <= 5) {
      state.activeTab = num;
    }
  },
  [CLEAR_FORM_CONTROLS](state) {
    state.formControls = { ...DEFAULT_FORM_CONTROLS };
    state.uploadFormControls = { ...DEFAULT_UPLOAD_FORM_CONTROLS };
    state.anotherUploads = JSON.parse(JSON.stringify(DEFAULT_ANOTHER_UPLOADS));
    state.isDocumentDownload = false;
    state.filesDownload.length = 0;
    state.isFormValid = null;
    state.isEdit = null;
    state.isEditUpload = null;
    state.managerId = null;
    state.orderId = null;
    state.status = null;
    state.statusId = null;
    state.products = [];
    state.comment = null;
    state.creationDate = null;
    state.changeDate = null;
    state.activeTab = 1;
    state.certificate = [];
    state.isDocumentsCollected = false;
    state.sigCheckStatus = null;
    state.sigIssuerCN = null;
    state.filialClientFlags = null;
    state.isCertificateRequested = false;
    state.isUploadFormLoaded = true;
    state.currentCertThumbprint = null;
    state.certIsValid = false;
    state.errorMessage = null;
    state.paymentStatusId = null;
    state.paymentStatusDescription = null;
    state.lastPaymentDate = null;
    state.isUPDrequested = false;
    state.hideUtd = false;
    state.isSendUPDtoEDM = false;
    state.updStatus = null;
    state.updStatusId = null;
    state.updocument = null;
    state.isKKMReceiptLaunchable = false;
    state.isFNSIssueAvailable = false;
    state.privateKeyExportPolicy=-1;
    state.checkCertInFnsMessage = null;
    state.needToAttachRevoke = null;
    state.scansFromPreApp = [];
    state.filialClientLink = null;
    state.filialClientCertRequest = null;
  },
  [CLEAR_REGIONS](state) {
    state.optionsRegion = [];
  },
  [CLEAR_UPLOAD_FORM_CONTROLS](state) {
    state.uploadFormControls = { ...DEFAULT_UPLOAD_FORM_CONTROLS };
  },
  [SET_FILES_DOWNLOAD](state, files: []) {
    state.filesDownload = [...files];
  },
  [SET_CERTIFICATE](state, arr: object[]) {
    state.certificate = [...arr];
  },
  [SET_DOCUMENT_DOWNLOAD](state, val: boolean) {
    state.isDocumentDownload = val;
  },
  [SET_STATUS](state, val: string) {
    state.status = val;
  },
  [SET_STATUS_ID](state, val: number | null) {
    state.statusId = val;
  },
  [SET_PRODUCTS](state, arr: object[]) {
    state.products = [...arr];
  },
  [SET_TYPE_SELECTION](state: any, val: string) {
    state.formControls.typeEntre.value = val;
  },
  [SET_DISABLED](state: any, toggle: boolean = false) {
    Object.keys(state.formControls).forEach(nameControl => {
      const control = { ...state.formControls[nameControl] };

      if (!state.fieldsReadonlyKeys.includes(nameControl)) {
        control.disabled = toggle;
        state.formControls[nameControl] = { ...control };
      }
    });
  },
  [SET_PAYMENT_STATUS_ID](state,val :number|null){
    state.paymentStatusId = val;
  },
  [SET_PAYMENT_STATUS_DESCRIPTION](state, val: string | null) {
    state.paymentStatusDescription = val;
  },
  [SET_LAST_PAYMENT_DATE](state, date: Date | string | null) {
    state.lastPaymentDate = date;
  },
  [SET_UPD_REQUESTED](state, val: boolean) {
    state.isUPDrequested = val;
  },
  [SET_HIDE_UTD](state, val: boolean) {
    state.hideUtd = val;
  },
  [SET_SEND_UPD_TO_EDM](state, val: boolean) {
    state.isSendUPDtoEDM = val;
  },
  [SET_UPD_STATUS](state, val: string | null) {
    state.updStatus = val;
  },
  [SET_UPD_STATUS_ID](state, val: number | null) {
    state.updStatusId = val;
  },
  [SET_UPD_DOCUMENT](state, val: object | null) {
    state.updocument = val;
  },
  [SET_IS_MANAGERCOMMENT_EDITABLE](state, val: boolean) {
    state.isManagerCommentEditable = val;
  },
  [SET_ORG_SURNAME](state, val: string | null) {
    state.formControls['surnameOrg'].value = val;
  },
  [SET_ORG_NAME](state, val: string | null) {
    state.formControls['nameOrg'].value = val;
  },
  [SET_ORG_PATRONYMIC](state, val: string | null) {
    state.formControls['patronymicOrg'].value = val;
  },
  [SET_POSITION_DIRECTOR](state, val: string | null) {
    state.formControls['positionDirector'].value = val;
  },
  [SET_DIRECTOR](state, val: boolean) {
    state.formControls['isDirector'].value = val;
  },
  [SET_ORG_SURNAME_DISABLED](state, val: boolean) {
    state.formControls['surnameOrg'].disabled = val;
  },
  [SET_ORG_NAME_DISABLED](state, val: boolean) {
    state.formControls['nameOrg'].disabled = val;
  },
  [SET_ORG_PATRONYMIC_DISABLED](state, val: boolean) {
    state.formControls['patronymicOrg'].disabled = val;
  },
  [SET_POSITION_DIRECTOR_DISABLED](state, val: boolean) {
    state.formControls['positionDirector'].disabled = val;
  },
  [SET_PASSPORT_DIVISION](state, val: string) {
    state.formControls['passportDivision'].isValid = true;
    state.formControls['passportDivision'].value = val;
  },
  [SET_IS_KKMRECEIPT_LAUNCHABLE](state, val: boolean) {
    state.isKKMReceiptLaunchable = val;
  },
  [SET_IS_FNS_ISSUE_AVAILABLE](state, val: boolean) {
    state.isFNSIssueAvailable = val;
  },
  [SET_PRIVATEKEY_EXPORT_POLICY](state, val: number) {
    state.privateKeyExportPolicy = val;
  },
  [SET_CHECK_CERT_IN_FNS_MESSAGE](state, val: string | null | string[]) {
    state.checkCertInFnsMessage = val;
  },
  [SET_NEED_TO_ATTACH_REVOKE](state, val: boolean) {
    state.needToAttachRevoke = val;
  },
  [SET_SCANS_FROM_PREAPP](state, scans: any[]) {
    state.scansFromPreApp = JSON.parse(JSON.stringify(scans));
  },
  [SET_SMEV_CHECKS](state, checks: any[]) {
    state.smevChecks = JSON.parse(JSON.stringify(checks));
  },
  [SET_DOCUMENT_UPLOAD_REQUIRED](state, val: {fieldUploadName: TFieldUploadName, required: boolean}) {
    const {fieldUploadName, required} = val;
    state.uploadFormControls[fieldUploadName].required = required;
  },
  [SET_EDM_TYPES](state, arr: any[]) {
    state.edmTypes = JSON.parse(JSON.stringify(arr));
  },
  [SET_EDM_TYPE_ID](state, edmTypeId: number | null) {
    state.edmTypeId = edmTypeId;
  },
  [SET_FIELDS_FROM_BASIS_API](state, fieldsFromBasisApi: IFieldsFromBasisApi) {
    state.fieldsFromBasisApi = fieldsFromBasisApi;
  },
  [SET_FNS_EXISTING_CERTS](state, fnsExistingCerts: any[]) {
    state.fnsExistingCerts = JSON.parse(JSON.stringify(fnsExistingCerts));
  },
  [CLEAN_FNS_EXISTING_CERTS](state) {
    state.fnsExistingCerts = [];
  },
  [SET_NEW_CERT_REQUEST](state, newCertRequest: any) {
    state.newCertRequest = newCertRequest;
  },
  [SET_FILIAL_CLIENT_CERT_REQUEST](state, filialClientCertRequest: any) {
    state.filialClientCertRequest = filialClientCertRequest;
  },
  [SET_SIGNED_CERT_REQUEST](state, signedCertRequest: null | string) {
    state.signedCertRequest = signedCertRequest;
  },
  [CLEAR_NEW_CERT_REQUEST](state) {
    state.newCertRequest = null;
  },
  [CLEAR_FILIAL_CLIENT_CERT_REQUEST](state) {
    state.filialClientCertRequest = null;
  },
  [CLEAR_SIGNED_CERT_REQUEST](state) {
    state.signedCertRequest = null;
  },
  [SET_NEW_CERT_REVOKE](state, newCertRevoke: any) {
    state.newCertRevoke = newCertRevoke;
  },
  [SET_SIGNED_CERT_REVOKE](state, signedCertRevoke: null | string) {
    state.signedCertRevoke = signedCertRevoke;
  },
  [CLEAR_NEW_CERT_REVOKE](state) {
    state.newCertRevoke = null;
  },
  [CLEAR_SIGNED_CERT_REVOKE](state) {
    state.signedCertRevoke = null;
  },
  [CLEAR_OPTIONS_PASSPORT_DIVISIONS](state) {
    state.optionsPassportDivisions = [];
  },
  [DEFINE_FIELDS_ACTIVE](state: any, typeEntre: TTypeEntre) {
    Object.keys(state.formControls).forEach(controlName => {
      // для адреса определяем отдельно в DEFINE_ADDRESS_FIELDS
      if (
        !controlName.startsWith('separatedAddress') &&
        !['cityReal', 'addressReal', 'cityLaw', 'addressLaw'].includes(controlName)
      ) {
        if (
          !!APPLICANT_TYPES[typeEntre] &&
          (controlName === "typeEntre" || APPLICANT_TYPES[typeEntre].activeFields.includes(controlName))
        ) {
          state.formControls[controlName].active = true;
        } else {
          state.formControls[controlName].active = false;
        }
      }
    });
  },

  [DEFINE_FIELDS_REQUIRED](state: any, typeEntre: TTypeEntre) {
    const nonRequiredFields = APPLICANT_TYPES[typeEntre].nonRequiredFields;
    const isForeignPassport =
      state.formControls.identificationDocumentTypeId.value === IDENTIFICATION_DOCUMENT_TYPES.foreign.id;

    Object.keys(state.formControls).forEach(controlName => {
      // для плательщика определяем отдельно в DEFINE_PAYER_FIELDS_ACTIVE
      // для адреса определяем отдельно в DEFINE_ADDRESS_FIELDS
      if (
        !controlName.startsWith("payer") &&
        !controlName.startsWith('separatedAddress') &&
        !['cityReal', 'addressReal', 'cityLaw', 'addressLaw'].includes(controlName) &&
        controlName !== 'passportDivision'
      ) {
        if (nonRequiredFields.includes(controlName)) {
          state.formControls[controlName].required = false;
          state.formControls[controlName].rules = {
            ...state.formControls[controlName].rules,
            empty: !nonRequiredFields.includes(controlName),
          };
        }
      } else if (controlName === 'passportDivision') {
        state.formControls[controlName].required = !isForeignPassport;
        state.formControls[controlName].rules.empty = !isForeignPassport;
      }
    });
  },

  [DEFINE_PAYER_FIELDS_ACTIVE](state: any, payerSubjectType: TPayerType) {
    Object.keys(state.formControls).forEach((controlName: string) => {
      if (controlName.startsWith("payer")) {
        if ((PAYER_TYPES[payerSubjectType].activeFields as any).includes(controlName)) {
          state.formControls[controlName].active = true;
        } else {
          state.formControls[controlName].active = false;
        }

        if ((PAYER_TYPES[payerSubjectType].requiredFields as any).includes(controlName)) {
          state.formControls[controlName].required = true;
          state.formControls[controlName].rules.empty = true;
        } else {
          state.formControls[controlName].required = false;
          state.formControls[controlName].rules.empty = false;
        }

        if (controlName === "payerInn") {
          state.formControls[controlName].rules.minLength = state.formControls["payerSubjectType"].value === "ur" ? 10 : 12;
        }

        const {isValid} = validation(state.formControls[controlName].value, state.formControls[controlName].rules);
        state.formControls[controlName].isValid = isValid;
      }
    });
  },
  [DEFINE_IDENTIFICATION_DOCUMENT_FIELDS_ACTIVE](state: any, identificationDocumentTypeId: number) {
    Object.keys(state.formControls).forEach((controlName: string) => {
      if (controlName === "passportDivisionCode" || controlName === "docSeries") {
        const identificationDocumentType = Object.values(IDENTIFICATION_DOCUMENT_TYPES).find(t => t.id === identificationDocumentTypeId);
        if (identificationDocumentType && identificationDocumentType.activeFields.includes(controlName)) {
          state.formControls[controlName].active = true;
        } else {
          state.formControls[controlName].active = false;
        }
      }
    });
  },
  [TOGGLE_IS_FILLED_BY_ORGN](state) {
    state.isFilledByOGRN = !state.isFilledByOGRN;
  },
  [SET_REQUEST_GUID](state, value: string) {
    state.requestGuid = value;
  },
  [CLEAR_FIELD_VALUE](state: any, key: string) {
    if (['typeEntre', 'addovert', 'countryId', 'payerSubjectType'].includes(key)) return;

    if (typeof state.formControls[key].value === 'boolean') {
        state.formControls[key].value = false;
    } else {
        state.formControls[key].value = '';
    }
  },
  [SET_IS_PRESET_CONTAINER](state, value: boolean) {
    state.isPresetContainer = value;
  },
  [SET_FILIAL_CLIENT_LINK](state,val: string | null) {
    state.filialClientLink = val;
  },
  [SET_IS_PDN_USING_PROHIBITED](state, isPdnUsingProhibited: boolean) {
    state.isPdnUsingProhibited = isPdnUsingProhibited;
  },
  [SET_COMPANY_GROUPS](state, companyGroups: Array<ICompanyGroup>) {
    state.companyGroups = JSON.parse(JSON.stringify(companyGroups));
  },
  [SET_ALLOW_COMPANY_GROUP](state, allowCompanyGroup: boolean) {
    state.allowCompanyGroup = allowCompanyGroup;
  },
  ////// определяет, какие поля адреса показывать - раздельные или единые, какие обязательные и валидирует их
  [DEFINE_ADDRESS_FIELDS](state: any, {typeEntre, hasTariffInsurance}: {typeEntre: TTypeEntre, hasTariffInsurance: boolean}) {
    const activeFields = APPLICANT_TYPES[typeEntre].activeFields;
    const nonRequiredFields = APPLICANT_TYPES[typeEntre].nonRequiredFields;

    Object.keys(state.formControls).forEach((controlName: string) => {
      if (controlName.startsWith("separatedAddress")) {
        if (hasTariffInsurance) {
          state.formControls[controlName].active = true;
          state.formControls[controlName].required = !nonRequiredFields.includes(controlName);
          state.formControls[controlName].rules.empty = !nonRequiredFields.includes(controlName);
        } else {
          state.formControls[controlName].active = false;
          state.formControls[controlName].required = false;
          state.formControls[controlName].rules.empty = false;
        }
      }

      if (['cityReal', 'addressReal', 'cityLaw', 'addressLaw'].includes(controlName)) {
        if (hasTariffInsurance) {
          state.formControls[controlName].active = false;
        } else {
          state.formControls[controlName].active = activeFields.includes(controlName);
        }
      }

      if (controlName === 'index') {
        state.formControls[controlName].active = hasTariffInsurance || activeFields.includes(controlName);
        state.formControls[controlName].required = hasTariffInsurance || !nonRequiredFields.includes(controlName);
        state.formControls[controlName].rules.empty = hasTariffInsurance || !nonRequiredFields.includes(controlName);
        
      }
    });
  },
  [SET_SKZI_STATE](state, skziState) {
    state.skziState = JSON.parse(JSON.stringify(skziState));
  },
  [SET_CERTIFICATE_ISSUE_DATE](state, certificateIssueDate: Date | string | null) {
    state.certificateIssueDate = certificateIssueDate;
  },
};
