export const LOCALITY_TYPES = [
  {value: 1, label: 'Город', short: 'г.'},
  {value: 2, label: 'Населенный пункт', short: 'нп.'},
  {value: 3, label: 'Дачный поселок', short: 'дп.'},
  {value: 4, label: 'Сельский поселок', short: 'СП.'},
  {value: 5, label: 'Поселок городского типа', short: 'пгт.'},
  {value: 6, label: 'Рабочий поселок', short: 'рп.'},
  {value: 7, label: 'Курортный поселок', short: 'кп.'},
  {value: 8, label: 'Городской поселок', short: 'гп.'},
  {value: 9, label: 'Поселок', short: 'п.'},
  {value: 10, label: 'Аал', short: 'аал'},
  {value: 11, label: 'Арбан', short: 'арбан'},
  {value: 12, label: 'Аул', short: 'аул'},
  {value: 13, label: 'Выселки', short: 'в-ки'},
  {value: 14, label: 'Городок', short: 'г-к'},
  {value: 15, label: 'Заимка', short: 'з-ка'},
  {value: 16, label: 'Починок', short: 'п-к'},
  {value: 17, label: 'Кишлак', short: 'киш.'},
  {value: 18, label: 'Поселок при станции', short: 'п. ст.'},
  {value: 19, label: 'Поселок при железнодорожной станции', short: 'п. ж/д ст.'},
  {value: 20, label: 'Железнодорожный блокпост', short: 'ж/д бл-ст'},
  {value: 21, label: 'Местечко', short: 'м-ко'},
  {value: 22, label: 'Деревня', short: 'д.'},
  {value: 23, label: 'Село', short: 'с.'},
  {value: 24, label: 'Слобода', short: 'сл.'},
  {value: 25, label: 'Станция', short: 'ст.'},
  {value: 26, label: 'Станица', short: 'ст-ца'},
  {value: 27, label: 'Улус', short: 'у.'},
  {value: 28, label: 'Хутор', short: 'х.'},
  {value: 29, label: 'Разъезд', short: 'рзд.'},
  {value: 30, label: 'Зимовье', short: 'зим.'},
  {value: 31, label: 'Район', short: 'р-н'},
];

export const ROAD_TYPES = [
  {value: 1, label: 'Улица', short: 'ул.'},
  {value: 2, label: 'Шоссе', short: 'ш.'},
  {value: 3, label: 'Аллея', short: 'ал.'},
  {value: 4, label: 'Бульвар', short: 'б-р'},
  {value: 5, label: 'Взвоз', short: 'взв.'},
  {value: 6, label: 'Въезд', short: 'взд.'},
  {value: 7, label: 'Дорога', short: 'дор.'},
  {value: 8, label: 'Заезд', short: 'ззд.'},
  {value: 9, label: 'Километр', short: 'км'},
  {value: 10, label: 'Кольцо', short: 'к-цо'},
  {value: 11, label: 'Коса', short: 'коса'},
  {value: 12, label: 'Линия', short: 'лн.'},
  {value: 13, label: 'Магистраль', short: 'мгстр.'},
  {value: 14, label: 'Набережная', short: 'наб.'},
  {value: 15, label: 'Переезд', short: 'пер-д'},
  {value: 16, label: 'Переулок', short: 'пер.'},
  {value: 17, label: 'Площадка', short: 'пл-ка'},
  {value: 18, label: 'Площадь', short: 'пл.'},
  {value: 19, label: 'Проезд', short: 'пр-д'},
  {value: 20, label: 'Просек', short: 'пр-к'},
  {value: 21, label: 'Просека', short: 'пр-ка'},
  {value: 22, label: 'Проселок', short: 'пр-лок'},
  {value: 23, label: 'Проспект', short: 'пр-кт'},
  {value: 24, label: 'Проулок', short: 'проул.'},
  {value: 25, label: 'Разъезд', short: 'рзд.'},
  {value: 26, label: 'Ряд', short: 'ряд'},
  {value: 27, label: 'Сквер', short: 'с-р'},
  {value: 28, label: 'Спуск', short: 'с-к'},
  {value: 29, label: 'Съезд', short: 'сзд.'},
  {value: 30, label: 'Тракт', short: 'тракт'},
  {value: 31, label: 'Тупик', short: 'туп.'},
];

export const BUILDING_TYPES = [
  {value: 1, label: 'Владение', short: 'влд.'},
  {value: 2, label: 'Дом', short: 'д.'},
  {value: 3, label: 'Домовладение', short: 'двлд.'},
  {value: 4, label: 'Здание', short: 'зд.'},
  {value: 5, label: 'Павильон', short: 'пав.'},
  {value: 6, label: 'Сооружение', short: 'соор.'},
  {value: 7, label: 'Строение', short: 'стр.'},
  {value: 8, label: 'Корпус', short: 'к.'},
];

export const APARTMENT_TYPES = [
  {value: 1, label: 'Квартира', short: 'кв.'},
  {value: 2, label: 'Комната', short: 'ком.'},
  {value: 3, label: 'Офис', short: 'офис'},
  {value: 4, label: 'Помещение', short: 'помещ.'},
];
