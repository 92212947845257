import { REVOKE_ISSUER_TYPE_OPTIONS } from "../filter.constants";
import { IFilterControls } from "../filter.interfaces";
import { ALL_FILTER_CONTROLS, REVOKE_STATUSES, REVOKE_APPLICANTS } from "./filter.defaults";

export const defaultRevokesFilterControls: IFilterControls = {
  applicant: {
    title: "Заявитель",
    name: "applicant",
    controls: REVOKE_APPLICANTS,
  },
  status: {
    title: "Статус",
    name: "status",
    controls: REVOKE_STATUSES
  },
  issuerType: {
    title: "Издатель",
    name: "issuerType",
    controls: {
      issuerType: {
        type: "select",
        name: "issuerType",
        value: -1,
        options: REVOKE_ISSUER_TYPE_OPTIONS,
        disabled: false,
        touched: true
      }
    }
  },
  dateCreate: {
    title: "Период создания",
    name: "dateCreate",
    controls: {
      dateCreateFrom: {...ALL_FILTER_CONTROLS.date},
      dateCreateTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  dateReady: {
    title: "Период подтверждения",
    name: "dateReady",
    controls: {
      dateReadyFrom: {...ALL_FILTER_CONTROLS.date},
      dateReadyTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
};

export default defaultRevokesFilterControls;