import {app} from "@/main";
import { fetch } from '@/services/api';

import { APPLICATION_URLS as URL_LIST, EXTERNAL_SERVICES_URLS } from '@/config/urls';
import { ICompanyGroup, IApplicationInfo, IDivisionInfo } from "./application.interfaces";

const getApplicationInfo = async (orderId: number) => {
  const response: {
    data?: IApplicationInfo;
    error?: string;
    status?: number;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetApplicationInfo}/${orderId}?withScans=true`,
    );
  
    if (status === 403) throw new Error('Нет доступа к заявке');
    if (status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.error) throw new Error(data.error);

    response.data = data;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
    response.status = error.response?.status;
  }
  
  return response;
};

const saveCompanyGroup = async ({
  orderId,
  companyGroupName,
  companyGroupInn
}: {
  orderId: number;
  companyGroupName: string;
  companyGroupInn: string;
}) => {
  const response: {error?: string} = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.SaveCompanyGroup}`,
      {
        initialApplicationId: +orderId,
        name: companyGroupName || null,
        inn: companyGroupInn || null,
      },
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка'
      );
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const companyGroupList = async () => {
  const response: {
    data?: {
      allowCompanyGroup: boolean;
      items: Array<ICompanyGroup>;
      success: boolean;
      error: string;
    };
    error?: string;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.CompanyGroupList}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.error) throw new Error(data.error);
    if (!data?.success) throw new Error('Ответ неуспешный');
    
    response.data = data;
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
  }
  
  return response;
};

const saleLicense = async (orderId: number | string) => {
  const response: {error?: string} = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.post(
      `${URL_LIST.SaleLicense}/${orderId}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getLicensePdf = async (id: number) => {
  const response: {string64?: string; error?: string;} = {};

  app.$modal.show("loader");

  try {
    const { status, data } = await fetch.get(
      `${URL_LIST.GetLicensePdf}/${id}`,
    );
  
    if (!status || status !== 200) throw new Error('Неожиданный статус ответа');
    if (data && data.error) throw new Error(data.error);

    response.string64 = data?.file;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
  } finally {
    app.$modal.hide("loader");
  }
  
  return response;
};

const getDivisionInfo = async (divisionCode: number) => {
  const response: {
    data?: IDivisionInfo;
    error?: string;
    status?: number;
  } = {};

  try {
    const { status, data } = await fetch.get(
      `${EXTERNAL_SERVICES_URLS.GetDivisionName}?query=${divisionCode}`,
    );
  
    if (status === 403) throw new Error('Нет доступа');
    if (status !== 200) throw new Error('Неожиданный статус ответа');
    if (data?.error) throw new Error(data.error);

    response.data = data;
    
  } catch(err) {
    const error = err as any;
    response.error = 
      JSON.stringify(
        error.response?.data
        || error.message
        || 'Неизвестная ошибка',
        null,
        2,
      );
    response.status = error.response?.status;
  }
  
  return response;
};

export default {
  saveCompanyGroup,
  companyGroupList,
  getApplicationInfo,
  saleLicense,
  getLicensePdf,
  getDivisionInfo,
};
