import { GetterTree } from 'vuex';

import { getFormattedDate } from '@/helpers';
import { RootState } from '@/store/root.interface';
import { IRevokeListState, IListItem } from './revoke-list.types';
import {
  STATUSES,
  STATUS_IDS,
  CERT_ISSUER_TYPES,
  CERT_ISSUER_TYPE_IDS,
  CERT_SUBJECT_TYPES,
  CERT_SUBJECT_TYPE_IDS,
} from '@/constants/revoke.constants';

export const getters: GetterTree<IRevokeListState, RootState> = {
  getApplicationList(state, getters, rootState, rootGetters) {
    return state.list.map((item: IListItem) => {
      const row = [];
      row.push({id: item.number ? `А${item.number}` : 'Не отпределён'});
      row.push(item.dateCreate ? getFormattedDate(item.dateCreate, 'L') : 'Не определена');
      row.push(STATUSES[item.status]?.name || 'Не определён');
      row.push(CERT_ISSUER_TYPES[item.certIssuerType] || 'Не определён');
      row.push(CERT_SUBJECT_TYPES[item.certSubjectType] || 'Не определён');
      row.push(item.applicantName || 'Не определён');
      row.push(item.dateReady ? getFormattedDate(item.dateReady, 'L') : '');
      row.push(item.managerFullName || 'Не определён')
      return row;
    });
  },

  getIsRevokesAllowed(state) {
    return state.isRevokesAllowed;
  },
};
