export interface IRulesValidationFile {
  type: string[];
  size: number;
}

export default (file: any, { rules, errorMessage }: any) => {
  if (!rules) {
    return [];
  }

  const error = [];

  if (rules.type && rules.type.indexOf(file.type) === -1) {
    error.push(errorMessage.type);
  }

  if (rules.size && !(rules.size > file.size)) {
    error.push(errorMessage.size);
  }

  return error;
};
