import { Module } from "vuex";

import { actions } from "./preapplication-list.actions";
import { mutations } from "./preapplication-list.mutations";
import { getters } from "./preapplication-list.getters";

import { IPreApplicationList } from "./preapplication-list.interfaces";
import { RootState } from "@/store/root.interface";

export const preapplicationList: Module<IPreApplicationList, RootState> = {
  namespaced: true,
  state: {
    preapplicationsList: [],
    totalPages: null,
    sizePage: 10,
    activePage: 1,
    isLoaded: false,
    newPreAppNum: 0,
  },
  mutations,
  actions,
  getters
};
