import { MutationTree } from "vuex";
import { IPreApplicationList } from "./preapplication-list.interfaces";

export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const SET_SIZE_PAGES = "SET_SIZE_PAGES";
export const SET_PREAPPLICATIONS_LIST = "SET_APPLICATIONS_LIST";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const CLEAR_PREAPPLICATION_LIST = "CLEAR_APPLICATION_LIST";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const SET_NEW_PREAPP_NUM = "SET_NEW_PREAPP_NUM";

export const mutations: MutationTree<IPreApplicationList> = {
  [SET_TOTAL_PAGES](state, num: number) {
    state.totalPages = num;
  },
  [SET_ACTIVE_PAGE](state, num: number) {
    state.activePage = num;
  },
  [SET_SIZE_PAGES](state, num: number) {
    state.sizePage = num;
  },
  [SET_PREAPPLICATIONS_LIST](state, preapplications: []) {
    state.preapplicationsList = [...preapplications];
  },
  [SET_IS_LOADED](state, val: boolean) {
    state.isLoaded = val;
  },
  [CLEAR_PREAPPLICATION_LIST](state) {
    state.sizePage = 10;
    state.activePage = 1;
    state.totalPages = null;
  },
  [SET_NEW_PREAPP_NUM](state,payload: number) {
    state.newPreAppNum = payload;
  }
};
